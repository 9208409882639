import operadora from '@static/menu/operadora';
import master from '@static/menu/master';
import comercializadora from '@static/menu/comercializadora';
import grupo from '@static/menu/grupo';
import administrador from '@static/menu/administrador';
import soporte from '@static/menu/soporte';
import operadora_loteria from '@static/menu/operadora-loteria';

export default {
    namespaced: true,
    state: () => ({
        items: {
            'operadora': operadora,
            'master': master,
            'comercializadora': comercializadora,
            'grupo': grupo,
            'administrador': administrador,
            'soporte': soporte,
            'operadora-loteria': operadora_loteria,
        },
    }),
    getters: {
        get: state => (domain) => state.items[domain] || [],
    },
}