<template>
    <v-form ref="form" class="d-flex align-center" style="width: 100%" v-model="valid">
        <div class="mb-3 mr-3">
            <div>
                <DateInput v-model="fields.fecha"/>
            </div>
        </div>
        <div class="mb-3 mr-3">
            <v-text-field
                v-model="fields.search"
                @change="changed('search', $event)"
                append-icon="mdi-magnify"
                filled
                label="Buscar"
                hide-details
                clearable
            ></v-text-field>
        </div>
        <div class="mb-3 mr-3">
            <v-select
                :items="activeList"
                filled
                hide-details
                label="Estado"
                v-model="fields.is_active"
                :disabled="loading"
                @change="changed('is_active', $event)"
                />
        </div>
        <div class="mb-3 mr-3">
            <v-select
                :items="masterList"
                :loading="isLoadingMasterList"
                :disabled="isLoadingMasterList"
                item-text="name"
                item-value="id"
                label="Master"
                @change="changed('master_id', $event)"
                v-model="fields.master_id"
                hide-details
                filled
                >
            </v-select>
        </div>
        <div class="mb-3 mr-3">
            <v-select
                :items="comercializadoraList"
                :loading="isLoadingComercializadoraList"
                :disabled="isLoadingComercializadoraList || !this.fields.master_id"
                label="Comercializadora"
                @change="changed('comercializadora_id', $event)"
                v-model="fields.comercializadora_id"
                hide-details
                filled
                >
            </v-select>
        </div>
        <div class="mb-3 mr-3">
            <v-select
                :items="grupoList"
                :loading="isLoadingGrupoList"
                :disabled="isLoadingGrupoList || !this.fields.comercializadora_id"
                label="Grupo"
                @change="changed('grupo_id', $event)"
                v-model="fields.grupo_id"
                hide-details
                filled
                >
            </v-select>
        </div>
        <!--div class="mb-3 mr-3">
            <v-select
                :items="ComerciosList"
                :loading="isLoadingComerciosList"
                :disabled="isLoadingComerciosList"
                item-text="name"
                item-value="id"
                label="Comercios"
                @change="changed('comercio_id', $event)"
                v-model="fields.comercio_id"
                hide-details
                filled
                />
        </div-->
        <div class="d-flex">
            <v-spacer/>
            <v-btn text class="mr-3" :disabled="loading" @click="reset()">Reiniciar Filtros</v-btn>
            <v-btn color="primary" :disabled="loading" :loading="loading" @click="search()"><v-icon>mdi-magnify</v-icon>Buscar</v-btn>
        </div>
    </v-form>
    
</template>

<script>
import Search from '@mixins/search';

import MasterService from '@services/comercio/MasterService';
import ComercializadoraService from '@services/comercio/ComercializadoraService';
import GrupoService from '@services/comercio/GrupoService';

export default {
    props: ['value', 'loading'],
    mixins: [Search],
    data: function ()  {
        return {
            activeList: [
                {
                    text: 'Todos',
                    value: null,
                },
                {
                    text: 'Activos',
                    value: true,
                },
                {
                    text: 'Inactivos',
                    value: false,
                }
            ],

            defaultFields: {
                search: null,
                is_active: null,
                comercio_id: null,
                master_id: null,
                comercializadora_id: null,
                grupo_id: null,
                fecha: null,
            },

            isLoadingComerciosList: false,
            ComerciosList: [],

            isLoadingMasterList: false,
            masterList: [],

            isLoadingComercializadoraList: false,
            comercializadoraList: [],

            isLoadingGrupoList: false,
            grupoList: [],
           
        }
    },
    computed: {
        
    },
    created: function () {
        this.getMasterList();
    },
    methods: {
        getMasterList: function () {
            this.isLoadingMasterList = true;

            this.masterList = [];

            MasterService
                .getList({ is_active: true })
                .then(({ masterList }) => {
                    this.masterList = [{id: null, name: 'Todos'},...masterList];
                })
                .finally(() => this.isLoadingMasterList = false);
        },
        getComercializadoraList: function (params = {}) {
            this.comercializadoraList = [];

            this.isLoadingComercializadoraList = true;

            ComercializadoraService
                .getList({
                    ...{ is_active: true },
                    ...(this.fields.master_id ? {master_id: this.fields.master_id} : {}),
                    ...params
                })
                .then(({ comercializadoraList }) => { this.comercializadoraList = comercializadoraList; },)
                .finally(() => this.isLoadingComercializadoraList = false);
        },
        getGrupoList: function (params = {}) {
            this.grupoList = [];

            this.isLoadingGrupoList = true;

            GrupoService
                .getList({
                    ...{ is_active: true },
                    ...(this.fields.comercializadora_id ? {comercializadora_id: this.fields.comercializadora_id} : {}),
                    ...params
                })
                .then(({ grupoList }) => { this.grupoList = grupoList; },)
                .finally(() => this.isLoadingGrupoList = false);
        },
        onChange: function (field) {
            switch(field) {
                case 'master_id':
                    this.fields.comercializadora_id = null;   
                break;
                case 'comercializadora_id':
                    this.fields.grupo_id = null;
                break;
            }
        }
    },
    watch: {
        'fields.master_id': function () {
            this.getComercializadoraList();
        },
        'fields.comercializadora_id': function () {
            this.getGrupoList();
        },
        /*'fields.comercio_id': function (comercio_id) {
            if (juego_id !== null) {
                this.getJuegoSorteoList();
                this.getTipoJuegoSelected();
                this.fields.resultado = null;
            } else {
                this.juegoSorteoList = [];
                this.fields.resultado = null;
                this.fields.juego_sorteo_id = null;
            }
        },*/
    }
}
</script>

<style>

</style>