<template>
    <v-form ref="form" style="width: 100%" v-model="valid">
        <v-container fluid>
            <v-row>
                <template v-if="context == 'sistema'">
                    <div class="mr-3 mb-3">
                        <SelectorSistema v-model="fields.sistema_id" :disabled="loading"/>
                    </div>
                </template>
                <template v-else>
                    <div class="mr-3 mb-3">
                        <v-select
                            :items="masterList"
                            :loading="isLoadingMasterList"
                            :disabled="isLoadingMasterList || !showMaster"
                            item-text="name"
                            item-value="id"
                            label="Master"
                            @change="changed('master_id', $event)"
                            v-model="fields.master_id"
                            hide-details
                            filled
                            >
                        </v-select>
                    </div>
                    <div class="mr-3 mb-3">
                        <v-select
                            :items="comercializadoraList"
                            :loading="isLoadingComercializadoraList"
                            :disabled="isLoadingComercializadoraList || !this.fields.master_id || !showComercializadora"
                            item-text="name"
                            item-value="id"
                            label="Comercializadora"
                            @change="changed('comercializadora_id', $event)"
                            v-model="fields.comercializadora_id"
                            hide-details
                            filled
                            />
                    </div>
                    <div class="mr-3 mb-3">
                        <v-select
                            :items="grupoList"
                            :loading="isLoadingGrupoList"
                            :disabled="isLoadingGrupoList || !this.fields.comercializadora_id || !showGrupo"
                            item-text="name"
                            item-value="id"
                            label="Grupo"
                            @change="changed('grupo_id', $event)"
                            v-model="fields.grupo_id"
                            hide-details
                            filled
                            />
                    </div>
                    <div class="mr-3 mb-3">
                        <v-select
                            :items="taquillaList"
                            :loading="isLoadingTaquillaList"
                            :disabled="isLoadingTaquillaList || !this.fields.grupo_id || !showTaquilla"
                            item-text="name"
                            item-value="id"
                            label="Taquilla"
                            @change="changed('taquilla_id', $event)"
                            v-model="fields.taquilla_id"
                            hide-details
                            filled
                            />
                    </div>
                </template>
                <div class="mr-3 mb-3">
                    <DateRangeInput v-model="fields.daterange" @change="changed('daterange', $event)" label="Rango de Fechas"/>
                </div>
            </v-row>
            <!--div class="mr-3 mb-3">
                <v-select
                    :items="divisaList"
                    :loading="isLoadingDivisaList"
                    :disabled="isLoadingDivisaList"
                    item-text="name"
                    item-value="id"
                    label="Divisa"
                    @change="changed('divisa_id', $event)"
                    v-model="fields.divisa_id"
                    hide-details
                    filled
                    >
                </v-select>
            </div-->
        <v-divider class="my-4" />
        <div class="d-flex">
            <v-spacer/>
            <v-btn text class="mr-3" :disabled="loading" @click="reset()">Reiniciar Filtros</v-btn>
            <v-btn color="primary" :disabled="loading" :loading="loading" @click="search()"><v-icon>mdi-magnify</v-icon>Buscar</v-btn>
        </div>
    </v-container>
    </v-form>
    
</template>

<script>
import Search from '@mixins/search';
//import DivisaService from '@services/operadora/general/DivisaService';
import MasterService from '@services/comercio/MasterService';
import ComercializadoraService from '@services/comercio/ComercializadoraService';
import GrupoService from '@services/comercio/GrupoService';
import TaquillaService from '@services/comercio/TaquillaService';

export default {
    components: {
        
    },
    props: ['context', 'value', 'loading'],
    mixins: [Search],
    data: function ()  {
        return {
            masterList: [],
            isLoadingMasterList: false,
            showMaster: true,

            comercializadoraList: [],
            isLoadingComercializadoraList: false,
            showComercializadora: true,

            grupoList: [],
            isLoadingGrupoList: false,
            showGrupo: true,

            taquillaList: [],
            isLoadingTaquillaList: false,
            showTaquilla: true,

            /*divisaList: [],
            isLoadingDivisaList: false,*/

            defaultFields: {
                daterange: [this.$luxon.local().toISODate(), this.$luxon.local().toISODate()],
                master_id: null,
                comercializadora_id: null,
                grupo_id: null,
                taquilla_id: null,
                sistema_id: null,
                //divisa_id: null,
            },
        }
    },
    computed: {
        
    },
    created: function () {
        //this.getDivisaList();
        this.getMasterList();
    },
    mounted: function () {
        //this.preloadLists();
    },
    methods: {
        /*getDivisaList: function () {
            this.divisaList = [];
            DivisaService.getList().then(({ divisaList }) => {
                this.divisaList = [{id: null, name: 'Todos'},...divisaList];
            });
        },*/
        getMasterList: function () {
            this.masterList = [];
            this.isLoadingMasterList = true;
            MasterService.getList()
                .then(({ masterList }) => {
                    if (masterList.length == 1) 
                    {
                        this.fields.master_id = masterList[0].id;
                        this.showMaster = false;
                    }
                    this.masterList = [{id: null, name: 'Todos'},...masterList];
                })
                .finally(() => this.isLoadingMasterList = false);
        },
        getComercializadoraList: function (parent_id = null) {
            const master_id = this.fields.master_id || parent_id;
            this.comercializadoraList = [];
            if (!master_id) return;
            this.isLoadingComercializadoraList = true;
            ComercializadoraService.getList({ master_id })
                .then(({ comercializadoraList }) => {
                    if (comercializadoraList.length == 1) 
                    {
                        this.fields.comercializadora_id = comercializadoraList[0].id;
                        this.showComercializadora = false;
                    }
                    this.comercializadoraList = [{id: null, name: 'Todos'},...comercializadoraList];
                })
                .finally(() => this.isLoadingComercializadoraList = false);
        },
        getGrupoList: function (parent_id = null) {
            const comercializadora_id = this.fields.comercializadora_id || parent_id
            this.grupoList = [];
            if (!comercializadora_id) return;
            this.isLoadingGrupoList = true;
            GrupoService.getList({ comercializadora_id })
                .then(({ grupoList }) => {
                    if (grupoList.length == 1) 
                    {
                        this.fields.grupo_id = grupoList[0].id;
                        this.showGrupo = false;
                    }
                    this.grupoList = [{id: null, name: 'Todos'},...grupoList];
                })
                .finally(() => this.isLoadingGrupoList = false);
        },
        getTaquillaList: function (parent_id = null) {
            const grupo_id = this.fields.grupo_id || parent_id
            this.taquillaList = [];
            if (!grupo_id) return;
            this.isLoadingTaquillaList = true;
            TaquillaService.getList({ grupo_id })
                .then(({ taquillaList }) => {
                    if (taquillaList.length == 1) 
                    {
                        this.fields.taquilla_id = taquillaList[0].id;
                        this.showTaquilla = false;
                    }
                    this.taquillaList = [{id: null, name: 'Todos'},...taquillaList];
                })
                .finally(() => this.isLoadingTaquillaList = false);
        },
        preloadLists: function () {
            /*const defaults = this.default;

            if (defaults.master_id) {
                this.getComercializadoraList();
            }
            if (defaults.comercializadora_id) {
                this.getGrupoList();
            }
            if (defaults.grupo_id) {
                this.getTaquillaList();
            }
            if (defaults.juego_id) {
                this.getJuegoSorteoList();
            }*/
        },
        onChange: function (field) {
            switch(field) {
                case 'master_id':
                    this.fields.comercializadora_id = null;   
                break;
                case 'comercializadora_id':
                    this.fields.grupo_id = null;   
                break;
                case 'grupo_id':
                    this.fields.taquilla_id = null;   
                break;
            }
        }
    },
    watch: {
        'fields.master_id': function (master_id) {
            if (!this.fields.master_id) this.getMasterList();
            if (master_id !== null) this.getComercializadoraList();
        },
        'fields.comercializadora_id': function (comercializadora_id) {
            if (comercializadora_id !== null) this.getGrupoList(); 
        },
        'fields.grupo_id': function (grupo_id) {
            if (grupo_id !== null) this.getTaquillaList();
        },
    }
}
</script>

<style>

</style>