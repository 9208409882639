var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mb-2"},[_c('v-card',[_c('v-card-title',[_vm._v(" Ventas Por Figura ")]),_c('v-card-text',{staticClass:"d-flex justify-space-between align-center"},[_c('div',[(_vm.params.juego_sorteo_id)?_c('div',{staticClass:"d-flex align-center mb-2"},[_c('span',{staticClass:"mr-4"},[_vm._v("SORTEO")]),_c('Totalizador',{attrs:{"loading":_vm.loadingSorteoSummary,"summary":_vm.sorteoSummary}})],1):_vm._e(),_c('div',{staticClass:"d-flex align-center mb-2"},[_c('span',{staticClass:"mr-4"},[_vm._v("DÍA")]),_c('Totalizador',{attrs:{"loading":_vm.loadingDaySummary,"summary":_vm.daySummary}})],1),(_vm.params.juego_sorteo_id && _vm.weekSummary)?_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"mr-4"},[_vm._v("SEMANA")]),_c('Totalizador',{attrs:{"loading":_vm.loadingWeekSummary,"summary":_vm.weekSummary}})],1):_vm._e()]),_c('div',[_c('h1',[_vm._v(_vm._s(_vm.totalTickets)+" Tickets")])])])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.listComputed,"items-per-page":-1,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.comercio",fn:function({ item }){return [(_vm.context == 'comercio')?_c('div',[_c('small',{staticClass:"d-flex"},[_vm._v(_vm._s(item.parentComercio))]),_c('v-btn',{attrs:{"text":"","small":""},on:{"click":function($event){return _vm.open({
                    'comercio': 'reporte.figura',
                    'sistema': 'operadora.sistema.reporte.figura',
                }[_vm.context], {
                    comercio: {
                        type: item.comercio_type,
                        id: item.comercio_id,
                    }
                })}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi mdi-eye-outline")]),_vm._v(" "+_vm._s(item.comercio)+" ")],1)],1):_vm._e(),(_vm.context == 'sistema')?_c('div',[_vm._v(" "+_vm._s(item.comercio)+" ")]):_vm._e()]}},{key:"item.tickets",fn:function({ item }){return [_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","text":""},on:{"click":function($event){return _vm.open({
                'comercio': 'tickets',
                'sistema': 'operadora.sistema.tickets',
            }[_vm.context], {
                numero: item.figura.value
            })}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi mdi-receipt-text")]),_vm._v(" "+_vm._s(item.ticket_count)+" ")],1)]}},{key:"item.venta",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("currency")(item.venta,item.divisa_code))+" ")]}},{key:"item.comision",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("currency")(item.comision,item.divisa_code))+" ")]}},{key:"item.projected",fn:function({ item }){return [_c('v-chip',{staticClass:"font-weight-bold mr-2",attrs:{"small":"","label":"","color":item.premioColor}},[_vm._v(" "+_vm._s(_vm._f("currency")(item.projected,item.divisa_code))+" "+_vm._s(' / ')+" "+_vm._s(_vm._f("percent")(item.premioPercent))+" ")])]}},{key:"item.total_projected",fn:function({ item }){return [_c('v-chip',{staticClass:"font-weight-bold mr-2",attrs:{"small":"","label":"","color":item.sorteoColor}},[_vm._v(" Sorteo: "+_vm._s(_vm._f("currency")(item.sorteoTotal,item.divisa_code))+" ")]),(_vm.weekSummary)?_c('v-chip',{staticClass:"font-weight-bold mr-2",attrs:{"small":"","label":"","color":item.dayColor}},[_vm._v(" Día "+_vm._s(_vm._f("currency")(item.dayTotal,item.divisa_code))+" ")]):_vm._e(),(_vm.weekSummary)?_c('v-chip',{staticClass:"font-weight-bold",attrs:{"small":"","label":"","color":item.weekColor}},[_vm._v(" Semana "+_vm._s(_vm._f("currency")(item.weekTotal,item.divisa_code))+" ")]):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }