<template>
    <v-form ref="form" style="width: 100%" v-model="valid">
        <div class="d-flex align-center mb-3">
            <div class="mr-3">
                <DateInput 
                    v-model="fields.date" 
                    @change="changed('date', $event)" 
                    label="Fechas"/>
            </div>
            <div class="mr-3">
                <SelectorJuego 
                    v-model="fields.juego_id" 
                    @change="changed('juego_id', $event)" 
                    :juego.sync="localJuego"
                    />
            </div>
            <div class="mr-3">
                <SelectorJuegoSorteo 
                    :params="{ juego_id: fields.juego_id }"
                    @change="changed('juego_sorteo_id', $event)"
                    v-model="fields.juego_sorteo_id"
                    />
            </div>
        </div>
        <v-divider class="my-4" />
        <div class="d-flex align-center">
            <div>
                <div class="mb-1">
                    <v-checkbox 
                        v-model="fields.consolidado" 
                        label="Consolidar Por Divisa"
                        hide-details
                        class="ma-0"
                        dense
                        ></v-checkbox>
                </div>
                <div v-if="fields.consolidado">
                    <SelectorDivisaChip v-model="fields.divisaList"/>
                </div>
            </div>
            <v-spacer/>
            <v-btn text class="mr-3" :disabled="loading" @click="reset()">Reiniciar Filtros</v-btn>
            <v-btn color="primary" :disabled="loading" :loading="loading" @click="search()"><v-icon>mdi-magnify</v-icon>Buscar</v-btn>
        </div>
    </v-form>
    
</template>

<script>
import Search from '@mixins/search';

export default {
    components: {
    },
    props: ['value', 'loading', 'juego'],
    mixins: [Search],
    data: function ()  {
        return {
            localJuego: null,

            defaultFields: {
                date: this.$luxon.local().toISODate(),

                juego_id: null,
                juego_sorteo_id: null,

                numero: null,

                consolidado: false,
                divisaList: [],
            },
        }
    },
    computed: {
        defaultJuegoSlug: function () {
            return this.$store.getters['persist/default/defaultJuegoSlug'];
        },
    },
    created: function () {
    },
    mounted: function () {
    },
    methods: {
        afterSearch: function () {
            this.$emit('update:juego', { ... this.localJuego })
        },
    },
    watch: {
        'fields.numero': function (numero, old) {
            if (numero === null) this.comercio_to_group = null;
            if (old === null && numero) this.comercio_to_group = null;
        }
    }
}
</script>

<style>

</style>