<template>
<v-form ref="form" v-model="valid">
    <v-row dense>
        <v-col cols="12" sm="8" md="8">
            Nombre
            <v-text-field
                v-model="fields.name"
                :rules="rules.name"
                :disabled="loading"
                label="Nombre"
                solo
                hide-details="auto"
                @change="changed('name', $event)"
                required
                />
        </v-col>
        <v-col cols="12" sm="4" md="4">
            Estado
            <v-switch
                v-model="fields.is_active"
                :rules="rules.is_active"
                :disabled="loading"
                :label="fields.is_active ? 'Activo' : 'Inactivo'"
                inset
                hide-details="auto"
                color="primary"
                @change="changed('is_active', $event)"
                required
                />
        </v-col>
        <v-col cols="12">
            Descripción
            <v-textarea
                v-model="fields.description"
                :rules="rules.description"
                :disabled="loading"
                label="Descripción"
                rows="5"
                clearable
                clear-icon="mdi-close-circle"
                solo
                hide-details="auto"
                auto-grow
                @change="changed('description', $event)"
                />
        </v-col>
    </v-row>
</v-form>
</template>
<script>
import Form from '@mixins/form';

export default {
    mixins: [Form],
    data: function () {
        return {
            rules: {
                name: [v => !!v || 'ERROR'],
                description: [],
                is_active: [],
            },

            defaultFields: {
                name: null,
                description: null,
                is_active: false,
            }
        };
    },
}
</script>