<template>
    <div>
        <h3>Sorteos</h3>
        <div>
            <v-scroll-x-transition>
                <div v-if="loading" style="width:100%">
                    <div class="d-flex align-center justify-content-center flex-column pa-3">
                        <p class="mb-1">Cargando Sorteos</p>
                        <v-progress-linear color="primary" indeterminate rounded height="6"/>
                    </div>
                </div>
                <div v-else style="width: 100%">
                    <div class="d-flex align-center flex-wrap">
                        <div v-for="(juegoSorteo, index) in juegoSorteoList" :key="`loteria-juego-sorteo-list-item-${index}-${juegoSorteo.id}`" class="mr-2 mb-2">
                            <v-menu
                                v-model="juegoSorteo.menu"
                                :close-on-content-click="false"
                                :nudge-width="150"
                                offset-y
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn class="mb-2" :class="{primary: juegoSorteo.is_active, secondary: !juegoSorteo.is_active}" v-bind="attrs" v-on="on" small>
                                        {{juegoSorteo.horario}}
                                    </v-btn>
                                </template>
                                <JuegoSorteoForm :juego="juego" :juegoSorteo="juegoSorteo" @saved="juegoSorteoSaved($event)" :juegoSorteoList="juegoSorteoList"/>
                            </v-menu>
                        </div>
                        <v-menu
                            v-model="menu"
                            :close-on-content-click="false"
                            :nudge-width="150"
                            offset-y
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" small class="mb-2">Añadir Sorteo</v-btn>
                            </template>
                            <JuegoSorteoForm :juego="juego" @saved="juegoSorteoSaved($event)" :juegoSorteoList="juegoSorteoList"/>
                        </v-menu>
                    </div>
                </div>
            </v-scroll-x-transition>
        </div>
    </div>
</template>
<script>
import JuegoSorteoService from '@services/operadora/loteria/JuegoSorteoService';

import JuegoSorteoForm from '@components/entities/loteria/juego-sorteo/form';

export default {
    components: {
        JuegoSorteoForm,
    },
    props: ["juego"],
    data: () => ({
        loading: false,
        dialog: false,
        menu: false,
        juegoSorteoList: [],
    }),
    mounted: function () {
        if (this.juego.id)
            this.getSorteoList();
    },
    methods: {
        getSorteoList: function () {
            this.juegoSorteoList = [];
            this.loading = true;
            JuegoSorteoService.getList({ juego_id: this.juego.id })
                .then(({ juegoSorteoList }) => {
                    this.juegoSorteoList = juegoSorteoList;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        addSorteo: function () {
            this.dialog = true;
        },
        juegoSorteoSaved: function () {
            this.menu = false,
            this.getSorteoList();
        }
    },
    watch: {
        juego: function (juego) {
            if (juego?.id) this.getSorteoList();
        },
    },
}
</script>
