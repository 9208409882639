import CrudService from "@utils/CrudService";

import axios from '@plugins/axios';

class JuegoSorteoService extends CrudService {
    getListForResultado(filters = {}, options = {}) {
        return axios({
            url: this.getRoute('for-resultado'),
            method: 'GET',
            params: this.getParamsProcessed(filters),
            ...options
        });
    }
}

export const BaseJuegoSorteoService = JuegoSorteoService

export default new JuegoSorteoService({
    domainName:'operadora/loteria',
    entityName:'juego-sorteo',
    fields: ['horario', 'juego_id','is_active'],
    query:['juego_id', 'is_active', 'fecha'],
});