<template>
    <v-dialog v-model="dialog" width="600">
        <v-card shaped>
            <v-card-title class="primary white--text">
                <h3>User</h3>
            </v-card-title>
            <v-card-text class="pa-3">
                <v-container fluid>
                    <div v-if="context == 'new'" class="mb-3">
                        Tipo de Usuario
                        <v-select
                            :items="domainList"
                            item-text="name"
                            item-value="id"
                            :loading="isLoadingDomainList"
                            solo
                            hide-details
                            label="Tipo de Usuario"
                            v-model="domain_id"
                            :disabled="loading || isLoadingDomainList"
                            />
                    </div>
                    <UserForm :loading="loading" v-model="userData"  :default="defaultUserData" :valid.sync="isUserDataValid" :context="context"/>
                </v-container>
            </v-card-text>
            <v-divider/>
            <v-card-actions>
                <v-spacer/>
                <v-btn color="primary" text :disabled="loading" @click="reset()">RESETEAR</v-btn>
                <v-btn color="primary" :disabled="!isValid || loading" :loading="loading" @click="submit()">
                    <v-icon>mdi-content-save</v-icon>
                    <span v-if="this.user">Editar user</span>
                    <span v-else>Añadir user</span>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import UserForm from '@components/entities/general/user/form';

import UserService from '@services/comercio/UserService';

export default {
    components: {
        UserForm,
    },
    props: ['comercioType', 'comercioId', 'user', 'open'],
    data: () => ({
        loading: false,
        dialog: false,

        context: 'new',

        userData: {},
        defaultUserData: null,
        isUserDataValid: false,

        isLoadingDomainList: false,
        domainList: [],

        domain_id: null,
    }),
    mounted: function () {
        this.reset();
    },
    created: function () {
        this.getUserDomainList();
    },
    computed: {
        isValid: function () {
            return this.isUserDataValid && (this.context != 'new' || this.domain_id);
        }
    },
    methods: {
        close: function () {
            this.$emit('update:open', false);
        },
        submit: function () {
            this.loading = true;
            let action = null;
            const data = { ...this.userData, domain_id: this.domain_id };

            if (this.user) action = UserService.update(this.comercioType, this.comercioId, this.user.id, data);
            else action = UserService.create(this.comercioType, this.comercioId, data);

            action.then(({ user }) => {
                this.$ui.notificate({message: 'User guardado'});
                this.emit(user);
                this.close();
            }).catch(() => {
                this.$ui.notificate({message: 'Error guardando el user', color: 'error'});
            }).finally(() => this.loading = false);
        },
        emit: function (data) {
            this.$emit('saved', data);
        },
        reset: function () {
            if (this.user) {
                this.context = 'edit';
                this.defaultUserData = { ... this.user};
            } else {
                this.context = 'new';
                this.defaultUserData = null;
            }
            this.userData = {};
        },
        getUserDomainList: function () {
            this.isLoadingDomainList = true;

            this.domainList = [];

            UserService
                .getDomainList()
                .then(({ domainList }) => { this.domainList = domainList; })
                .finally(() => this.isLoadingDomainList = false);
        },
    },
    watch: {
        open: function (open) {
            this.reset();
            this.dialog = open;
        },
        dialog: function (dialog) {
            if (!dialog) {
                this.$emit('update:open', false);
                this.reset();
            }
        },
    }
}
</script>
