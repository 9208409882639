<template>
    <v-form ref="form" class="d-flex align-center" style="width: 100%">
        <div class="mr-3">
            <v-select
                :items="tipoJuegoList"
                item-text="name"
                item-value="id"
                clearable
                label="Tipo Juego"
                v-model="fields.tipo_juego_id"
                hide-details
                filled
                :disabled="loading" :loading="loading"
                @change="changed('tipo_juego_id')"
                >
            </v-select>
        </div>
        <div class="mr-3">
            <v-select
                :items="loteriaList"
                item-text="name"
                item-value="id"
                clearable
                label="Loteria"
                v-model="fields.loteria_id"
                hide-details
                filled
                :disabled="loading" :loading="loading"
                @change="changed('loteria_id')"
                >
            </v-select>
        </div>
        <div class="mr-3">
            <v-select
                :items="isActiveList"
                filled
                hide-details
                label="Estado"
                clearable
                v-model="fields.is_active"
                :disabled="loading" :loading="loading"
                @change="changed('is_active')"
                >
            </v-select>
        </div>
        <div class="mr-3">
            <v-text-field
                v-model="fields.search"
                append-icon="mdi-magnify"
                filled
                label="Buscar Juego"
                hide-details
                clearable
                :disabled="loading" :loading="loading"
                @change="changed('search')"
                >
            </v-text-field>
        </div>
        <div class="d-flex">
            <v-btn text class="mr-3" :disabled="loading" @click="reset()">Reiniciar Filtros</v-btn>
            <v-btn color="primary" :disabled="loading" :loading="loading" @click="search()"><v-icon>mdi-magnify</v-icon>Buscar</v-btn>
        </div>
    </v-form>
</template>

<script>
import LoteriaService from '@services/loteria/LoteriaService';
import TipoJuegoService from '@services/loteria/TipoJuegoService';

const defaultValues = {
    search: null,
    is_active: null,
    tipo_juego_id: null,
    loteria_id: null,
};

export default {
    props: ['value', 'loading'],
    data: () => ({
        fields: { ...defaultValues },
        isActiveList: [
            {
                text: 'Todos',
                value: null,
            },
            {
                text: 'Activo',
                value: true,
            },
            {
                text: 'Inactivo',
                value: false,
            }
        ],
        
        tipoJuegoListLoading: false,
        tipoJuegoList: [],

        loteriaListLoading: false,
        loteriaList: [],
    }),
    created: function () {
        this.getTipoJuegoList();
        this.getLoteriaList();
    },
    methods: {
        getTipoJuegoList: function () {
            this.tipoJuegoListLoading = true;
            TipoJuegoService.getList({ is_active: true })
                .then(({ tipoJuegoList }) => {
                    this.tipoJuegoList = tipoJuegoList;
                }).finally(() => {
                    this.tipoJuegoListLoading = false;
                });
        },
        getLoteriaList: function () {
            this.loteriaListLoading = true;
            LoteriaService.getList({ is_active: true })
                .then(({ loteriaList }) => {
                    this.loteriaList = loteriaList;
                }).finally(() => {
                    this.loteriaListLoading = false;
                });
        },
        reset: function () {
            this.fields = { ... defaultValues };
        },
        emit: function () {
            const data = { ...this.value };
            for (const property in this.fields) {
                if (Object.hasOwnProperty.call(this.fields, property)) {
                    if (this.fields[property] !== null) data[property] = this.fields[property];
                }
            }
            this.$emit('input', data);
        },
        changed: function () {
            this.emit();
        },
        search: function () {
            this.$emit('search');
        }
    },
    watch: {
        value: function (value) {
            this.fields = value || { ...defaultValues };
            this.$refs['form'].resetValidation();
        }
    }
}
</script>

<style>

</style>