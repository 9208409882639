<template>
    <v-form ref="form" class="d-flex align-center" style="width: 100%" v-model="valid">
        <v-container fluid>
            <v-row >
                <div class="mr-3 mb-3">
                    <v-select
                        :items="isActiveList"
                        filled
                        hide-details
                        label="Estado"
                        v-model="fields.is_active"
                        :disabled="loading"
                        @change="changed('is_active', $event)"
                        />
                </div>
                <div class="mr-3 mb-3"> <!--v-show="showMaster"-->
                    <v-select
                        :items="masterList"
                        :loading="isLoadingMasterList"
                        :disabled="isLoadingMasterList || !showMaster"
                        item-text="name"
                        item-value="id"
                        label="Master"
                        @change="changed('master_id', $event)"
                        v-model="fields.master_id"
                        hide-details
                        filled
                        >
                    </v-select>
                </div>
                <div class="mr-3 mb-3"> <!--v-show="showComercializadora"-->
                    <v-select
                        :items="comercializadoraList"
                        :loading="isLoadingComercializadoraList"
                        :disabled="isLoadingComercializadoraList || !this.fields.master_id || !showComercializadora"
                        item-text="name"
                        item-value="id"
                        label="Comercializadora"
                        @change="changed('comercializadora_id', $event)"
                        v-model="fields.comercializadora_id"
                        hide-details
                        filled
                        >
                    </v-select>
                </div>
                <div class="mr-3 mb-3"> <!--v-show="showGrupo"-->
                    <v-select
                        :items="grupoList"
                        :loading="isLoadingGrupoList"
                        :disabled="isLoadingGrupoList || !this.fields.comercializadora_id || !showGrupo"
                        item-text="name"
                        item-value="id"
                        label="Grupo"
                        @change="changed('grupo_id', $event)"
                        v-model="fields.grupo_id"
                        hide-details
                        filled
                        >
                    </v-select>
                </div>
            </v-row>
            <v-row>
                <div class="mr-3">
                    <v-text-field
                        v-model="fields.search"
                        @change="changed('search', $event)"
                        append-icon="mdi-magnify"
                        filled
                        label="Buscar"
                        hide-details
                        clearable
                    ></v-text-field>
                </div>
                <div class="mr-3">
                    <v-select
                        :items="isBloqueadoList"
                        :disabled="loading"
                        label="Instalación"
                        @change="changed('is_bloqueado', $event)"
                        v-model="fields.is_bloqueado"
                        hide-details
                        filled
                        >
                    </v-select>
                </div>
            </v-row>
            <v-divider class="my-4" />
            <div class="d-flex">
                <v-spacer/>
                <v-btn text class="mr-3" :disabled="loading" @click="reset()">Reiniciar Filtros</v-btn>
                <v-btn color="primary" :disabled="loading" :loading="loading" @click="search()"><v-icon>mdi-magnify</v-icon>Buscar</v-btn>
            </div>
        </v-container>
    </v-form>
</template>

<script>
import Search from '@mixins/search';

import MasterService from '@services/comercio/MasterService';
import ComercializadoraService from '@services/comercio/ComercializadoraService';
import GrupoService from '@services/comercio/GrupoService';

export default {
    mixins: [Search],
    data: function ()  {
        return {
            isActiveList: [
                {
                    text: 'Todos',
                    value: null,
                },
                {
                    text: 'Activos',
                    value: true,
                },
                {
                    text: 'Inactivos',
                    value: false,
                }
            ],

            isBloqueadoList: [
                {
                    text: 'Todos',
                    value: null,
                },
                {
                    text: 'Bloqueada',
                    value: true,
                },
                {
                    text: 'Desbloqueada',
                    value: false,
                }
            ],

            defaultFields: {
                search: null,
                is_active: null,
                master_id: null,
                comercializadora_id: null,
                grupo_id: null,
            },

            isLoadingMasterList: false,
            showMaster: true,
            masterList: [],

            isLoadingComercializadoraList: false,
            showComercializadora: true,
            comercializadoraList: [],

            isLoadingGrupoList: false,
            showGrupo: true,
            grupoList: [],
        }
    },
    /*created: function () {
        this.getMasterList();
    },*/
    methods: {
        getMasterList: function () {
            this.isLoadingMasterList = true;

            this.masterList = [];

            MasterService
                .getList({ is_active: true })
                .then(({ masterList }) => {
                    if (masterList.length == 1) 
                    {
                        this.fields.master_id = masterList[0].id;
                        this.showMaster = false;
                    }
                    this.masterList = [{id: null, name: 'Todos'},...masterList];
                })
                .finally(() => this.isLoadingMasterList = false);
        },
        getComercializadoraList: function (params = {}) {
            this.comercializadoraList = [];

            this.isLoadingComercializadoraList = true;

            ComercializadoraService
                .getList({
                    ...{ is_active: true },
                    ...(this.fields.master_id ? {master_id: this.fields.master_id} : {}),
                    ...params
                })
                .then(({ comercializadoraList }) => { 
                    if (comercializadoraList.length == 1) 
                    {
                        this.fields.comercializadora_id = comercializadoraList[0].id;
                        this.showComercializadora = false;
                    }
                    this.comercializadoraList = comercializadoraList; 
                },)
                .finally(() => this.isLoadingComercializadoraList = false);
        },
        getGrupoList: function (params = {}) {
            this.grupoList = [];

            this.isLoadingGrupoList = true;

            GrupoService
                .getList({
                    ...{ is_active: true },
                    ...(this.fields.comercializadora_id ? {comercializadora_id: this.fields.comercializadora_id} : {}),
                    ...params
                })
                .then(({ grupoList }) => { 
                    if (grupoList.length == 1) 
                    {
                        this.fields.grupo_id = grupoList[0].id;
                        this.showGrupo = false;
                    }
                    this.grupoList = grupoList; 
                },)
                .finally(() => this.isLoadingGrupoList = false);
        },
        onChange: function (field) {
            switch(field) {
                case 'master_id':
                    this.fields.comercializadora_id = null; 
                    this.fields.grupo_id = null;  
                break;
                case 'comercializadora_id':
                    this.fields.grupo_id = null;
                break;
            }
        }
    },
    watch: {
        'fields.master_id': function () {
            this.showComercializadora = true;
            if (!this.fields.master_id) this.getMasterList();
            this.getComercializadoraList();
        },
        'fields.comercializadora_id': function () {
            this.showGrupo = true;
            if (!this.fields.comercializadora_id) this.getComercializadoraList();
            this.getGrupoList();
        },
    }
}
</script>

<style>

</style>