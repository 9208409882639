<template>
<v-container fluid>
    <v-row>
        <v-col>
            <v-card>
                <v-card-title>Filtros</v-card-title>
                <v-card-text><TicketSearch :loading="loading" :default="params" @search="params = $event"/></v-card-text>
            </v-card>
            <TicketList :params="params" :loading="loading"/>
        </v-col>
    </v-row>
</v-container>
</template>
    
<script>
import TicketSearch from '@components/entities/ticket/search';
import TicketList from '@components/entities/ticket';

export default {
    components: {
        TicketSearch,
        TicketList,
    },
    data: function () {
        const defaultParams = this.setQuery({
            date: this.$luxon.local().toISODate(),
        });
        return {
            options: {},
            loading: false,

            isModalOpen: false,
            item: null,

            default: defaultParams,
            params: {...defaultParams},
        };
    },
    created: function () {
        this.$store.dispatch('ui/layout/setTitle', 'Admin. de Tickets');
    },
    methods: {
        add: function () {
            this.item = null;
            this.isModalOpen = true;
        },
        edit: function (item) {
            this.item = item;
            this.isModalOpen = true;
        },
        setQuery: function (params = {}) {
            const query = {... this.$route.query};

            for (const key in query) {
                if (Object.hasOwnProperty.call(query, key)) {
                    const element = query[key];

                    if (key == 'numero') {
                        query[key] = element;
                        continue;
                    }

                    query[key] = /^-?[\d.]+(?:e-?\d+)?$/.test(element) ? Number.parseFloat(element): element;
                }
            }

            return { ... params, ... query };
        },
    },
}
</script>
<!-- 
data: function() {
    return {
        params: this.setQuery({
            date: this.$luxon.now().toISODate(),
        }),
        juego: null,
    }
},
created: function () {
    this.$store.dispatch('ui/layout/setTitle', '');
},
computed:{
    reporte: function() {
        if (!this.juego) return '';
        if (this.juego.tipo_juego.slug == 'tripleta') {
            return 'TripletaFigura';
        }

        if (this.params?.taquilla_id) return 'TicketList';
        if (this.params?.numero && this.params?.juego_sorteo_id) return 'Detallado';
        return 'Consolidado';    
    },
},
methods: {
    search: function (params) {
        this.params = params;
    },
    setQuery: function (defaultParams = {}) {
        const query = {... this.$route.query};

        for (const key in query) {
            if (Object.hasOwnProperty.call(query, key)) {
                const element = query[key];

                if (key == 'numero') {
                    query[key] = element;
                    continue;
                }

                if (key == 'consolidado') {
                    if (element == 'true') query[key] = true;
                    else query[key] = false;
                    continue;
                }

                if (key == 'divisaList') {
                    if (Array.isArray(element)) query[key] = element.map(value => parseInt(value));
                    else query[key] = [parseInt(element)];
                    continue;
                }

                query[key] = /^-?[\d.]+(?:e-?\d+)?$/.test(element) ? Number.parseFloat(element): element;
            }
        }

        return { ... defaultParams, ... query };
    }
},
watch: {
    '$route.query': {
        handler: function () {
            this.params = this.setQuery(this.params);
        },
        deep: true,
    }
} -->