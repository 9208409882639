<template>
    <v-dialog v-model="dialog" width="600">
        <v-card shaped>
            <v-card-title class="primary white--text">
                <h3>Taquilla</h3>
            </v-card-title>
            <v-card-text class="pa-3">
                <v-container fluid>
                    <TaquillaForm :loading="loading" v-model="taquillaData" :default="defaultTaquillaData" :valid.sync="isTaquillaDataValid"/>
                </v-container>
            </v-card-text>
            <v-divider/>
            <v-card-actions>
                <v-spacer/>
                <v-btn color="primary" text :disabled="loading" @click="reset()">RESETEAR</v-btn>
                <v-btn color="primary" :disabled="!isValid || loading" :loading="loading" @click="submit()">
                    <v-icon>mdi-content-save</v-icon>
                    <span v-if="this.taquilla">Editar taquilla</span>
                    <span v-else>Añadir taquilla</span>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import TaquillaForm from "./form"
//import UserForm from '@components/entities/general/user/form';

import TaquillaService from '@services/comercio/TaquillaService';

export default {
    components: {
        TaquillaForm,
        //UserForm
    },
    props: ['taquilla', 'open'],
    data: () => ({
        loading: false,
        dialog: false,

        taquillaData: {},
        defaultTaquillaData: null,
        isTaquillaDataValid: false,

    }),
    mounted: function () {
        this.reset();
    },
    computed: {
        isValid: function () {
            let isTaquillaDataValid = false;
            if (this.isTaquillaDataValid && Object.keys(this.taquillaData).length) isTaquillaDataValid = true;
            return isTaquillaDataValid;
        }
    },
    methods: {
        close: function () {
            this.$emit('update:open', false);
        },
        submit: function () {
            this.loading = true;
            let action = null;
            const data = {};

            if (this.isTaquillaDataValid && Object.keys(this.taquillaData).length) data['taquilla'] = this.taquillaData;

            if (this.taquilla) action = TaquillaService.update(this.taquilla.id, data);
            else action = TaquillaService.create(data);
            action
                .then(({ taquilla }) => {
                    this.$ui.notificate({message: 'Taquilla guardado'});
                    this.emit(taquilla);
                    this.close();
                })
                .catch(() => this.$ui.notificate({message: 'Error guardando taquilla', color: 'error'}))
                .finally(() => this.loading = false);
        },
        emit: function (data) {
            this.$emit('saved', data);
        },
        reset: function () {
            if (this.taquilla) {
                this.defaultTaquillaData = { ... this.taquilla};
            } else {
                this.defaultTaquillaData = null;
            }
            this.taquillaData = {};
        }
    },
    watch: {
        open: function (open) {
            this.reset();
            this.dialog = open;
        },
        dialog: function (dialog) {
            if (!dialog) {
                this.$emit('update:open', false);
                this.reset();
            }
        },
    }
}
</script>
