<template>
<div>
    <v-card flat>
        <v-card-text>
            <v-row class="pa-4">
                <v-col cols="12" md="4">
                    <p class="ma-0">{{ juegoCount }} juegos registrados</p>
                    <v-list v-if="!loading" three-line nav class="pa-0">
                        <v-list-item-group v-model="juegoSelected" color="primary">
                            <v-list-item v-for="(juego, index) in juegoList" :key="`juego-list-item-${index}`" :value="juego">
                                <v-list-item-avatar :color="(juego.is_active?'primary':'secondary')+' white--text'">{{juego.id}}</v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title v-html="juego.name"></v-list-item-title>
                                    <v-list-item-subtitle v-html="juego.description"></v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                    <v-skeleton-loader
                        v-if="loading"
                        class="mx-auto"
                        type="list-item-avatar-three-line@3"
                        ></v-skeleton-loader>
                    <v-divider class="my-2"/>
                    <v-list three-line nav class="pa-0">
                        <v-list-item-group v-model="juegoSelected" color="primary">
                            <v-list-item key="juego-list-item-new" value="new">
                                <v-list-item-avatar color="accent white--text">- -</v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>Nuevo Juego</v-list-item-title>
                                    <v-list-item-subtitle>Haz click para registrar un nuevo juego</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-col>
                <v-col cols="12" md="8" class="d-flex">
                    <v-card v-if="!juegoSelected" outlined class="flex-grow-1">
                        <v-card-title>
                            <div class="text-h6 text-primary font-weight-light ma-0 text-center">Selecciona un juego</div>
                        </v-card-title>
                    </v-card>
                    <JuegoDashboard v-if="juegoSelected" :juego_id="juegoSelected.id" :loteria="loteria">
                        <template v-slot:upper-actions="{ juego, isJuegoLoading }">
                            <div v-if="juego">
                                <v-btn icon primary @click="editJuego(juego)" :disabled="isJuegoLoading"><v-icon>mdi-pencil</v-icon></v-btn>
                            </div>
                        </template>
                    </JuegoDashboard>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
    <ModalJuego :loteria="loteria" :juego="juegoSelected" :open.sync="juegoModalOpen" @saved="juegoSaved($event)"/>
</div>
</template>
<script>
import JuegoService from '@services/operadora/loteria/JuegoService';

import ModalJuego from '@components/entities/loteria/juego/modal';
import JuegoDashboard from './dashboard';

export default {
    components: {
        ModalJuego,
        JuegoDashboard
    },
    props: ['loteria'],
    data: () => ({
        loading: false,
        juegoList: [],
        juegoCount: 0,
        juegoSelected: null,
        juegoSelectedId: null,
        juegoModalOpen: false,
    }),
    mounted: function () {
        this.init();
    },
    methods: {
        init: function () {
            this.loadJuegoList();
        },
        loadJuegoList: function (juegoSelected = null) {
            this.juegoList = [];
            if (!this.loteria) return;
            this.loading = true;
            JuegoService.getList({loteria_id: this.loteria.id}).then(({ juegoList = [], juegoCount = 0 }) => {
                this.juegoList = juegoList;
                this.juegoCount = juegoCount;
                this.$nextTick(() => {
                    if (juegoSelected) this.juegoSelected = juegoList.find(juego => juego.id == juegoSelected.id);
                    else this.juegoSelected = null;
                })
            }).finally(() => {
                this.loading = false;
            });
        },
        newJuego: function () {
            this.$nextTick(() => {
                this.juegoSelected = null;
                this.juegoModalOpen = true;
            });
        },
        editJuego: function (juego) {
            this.$nextTick(() => {
                this.juegoSelected = juego;
                this.juegoModalOpen = true;
            });
        },
        juegoSaved: function (juego) {
            this.loadJuegoList(juego);
        }
    },
    watch: {
        loteria: function (loteria) {
            if (loteria && loteria.id) this.loadJuegoList();
        },
        juegoSelected: function (juegoSelected) {
            if (juegoSelected == 'new') return this.newJuego();
        },
    }
}
</script>