<template>
    <div class="d-flex">
        <template v-if="loading">
            <v-skeleton-loader type="chip" class="mr-2"/>
            <v-skeleton-loader type="chip" class="mr-2"/>
            <v-skeleton-loader type="chip" class="mr-2"/>
            <v-skeleton-loader type="chip" class="mr-2"/>
        </template>
        <template v-else>
            <v-chip label class="mr-2">Ventas: {{ summary.venta | currency(summary.divisa_code) }}</v-chip>
            <v-chip label class="mr-2">Comisión: {{ summary.comision | currency(summary.divisa_code) }}</v-chip>
            <v-chip label class="mr-2">Premios: {{ summary.premio | currency(summary.divisa_code) }}</v-chip>
            <v-chip label :color="totalColor">Total: {{ summary.total | currency(summary.divisa_code) }}</v-chip>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        loading: {
            default: false,
        },
        summary: {
            defaults: function() {
                return {};
            },
        }
    },
    computed: {
        totalColor: function () {
            let color = 'secondary';
            if (!this.summary.total) return color;
            if (this.summary.total > 0) return 'primary';
            return 'error';
        }
    }
}
</script>
<style>

</style>