<template>
    <v-card flat>
        <v-card-text class="px-5" style="padding: .3rem 1rem;">
            {{ current | datetime }}
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    computed: {
        current: function () {
            return this.$store.getters['ui/currentDateTime'];
        },
    }
}
</script>