<template>
    <div>
        <div style="display: flex;">
            <div>
                <v-card-title>Tickets</v-card-title>
                <v-card-text>
                    <p class="text-h2">{{ count }}</p>
                </v-card-text>
            </div>
            <div v-if="params.numero">
                <v-card-title>Total apuestas página</v-card-title>
                <v-card-text>
                    <p class="text-h4 mt-4"> {{ this.apuestas | currency(divisa.divisa_code) }}</p>
                </v-card-text>
            </div>
            <div>
                <v-card-title>Total ventas página</v-card-title>
                <v-card-text>
                    <p class="text-h4 mt-4"> {{ this.ventas | currency(divisa.divisa_code) }}</p>
                </v-card-text>
            </div>
        </div>
        <v-data-table
            :headers="headers"
            :items="list"
            :options.sync="options"
            :server-items-length="count"
            :loading="loading"
            >
            <!-- eslint-disable-next-line -->
            <template v-slot:item.general="{ item }">
                <div>{{ item.control }}</div>
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.created_at="{ item }">
                {{ item.created_at | datetime }}
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.jugadas="{ item }">
                {{ item.jugada_list.length }}
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.total="{ item }">
                {{ item.total | currency }}
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.monto="{item}">
                <v-chip small color="primary" label>
                    {{ params.numero }} - {{ item.jugada_list.find(jugada => jugada.numero == params.numero)?.monto | currency }}
                </v-chip>
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.state="{ item }">
                <v-chip v-if="item.state == -1" color="error" small>Anulado</v-chip>
                <v-chip v-if="item.state == 0" color="primary" small>Vendido</v-chip>
                <v-chip v-if="item.state == 1" color="accent" small>Ganador</v-chip>
                <v-chip v-if="item.state == 2" color="success" small>Pagado</v-chip>
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.owner="{ item }">
                <template v-if="context == 'comercio'">
                    {{ item.master.name }} / {{ item.comercializadora.name }} / {{ item.grupo.name }} / {{  item.taquilla.name }}
                </template>
                <template v-if="context == 'sistema'">
                    {{ item.sistema.name }} 
                </template>
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.fecha="{ item }">
                {{ item.created_at | date }}
            </template>
             <!-- eslint-disable-next-line -->
             <template v-slot:item.actions="{ item }">
                <ActionAnular v-if="!item.is_anulado" :ticket="item" @anulado="anularTicket($event)"/>
                <v-btn color="primary" icon @click="openTicket(item)"><v-icon>mdi-eye</v-icon></v-btn>
            </template>
            
        </v-data-table>
    </div>
</template>
<script>
    import TicketService from '@services/ticket/TicketService';
    import ActionAnular from './components/actions/anular';
    import JuegoService from '@services/loteria/JuegoService';

    export default {
        components: {
            ActionAnular
        },
        props: {
            params: {
                defaults: function () {
                    return {

                    };
                }
            },
            context: {
                default: 'comercio',
            },
        },
        data: function(){
            return {
                options: {},
                list: [],
                count: 0,
                loading :false,
                ventas: 0,
                apuestas: 0,
                showApuesta: false,
            }
        },
        computed:{
            headers: function () {
                return [
                    ... this.params.state == null ? [{
                        text: 'Estado',
                        value: 'state',
                        align: 'center',
                        width: 100
                    }]: [],
                    {
                        text: 'Control',
                        value: 'general',
                        width: 100
                    },
                    ... (this.params.numero && this.showApuesta) ? [{
                        text: 'Apuesta',
                        value: 'monto',
                        width: 150,
                    }]: [],
                    {
                        text: 'Nº Jugadas',
                        width: 125,
                        value: 'jugadas',
                    },
                    {
                        text: 'Total',
                        value: 'total',
                        width: 150
                    },
                    {
                        text: 'Owner',
                        value: 'owner',
                    },
                    {
                        text: 'Fecha',
                        value: 'created_at',
                        width: 200
                    },
                    {
                        text: 'Acciones',
                        value: 'actions',
                        align: 'right',
                        width: 180
                    },
                ];
            },
            divisa: function () {
                return this.$store.getters['persist/divisa/selected'];
            },
        },
        methods:{
            getList: function (params = {}) {
                if (this.params.numero) this.checkJuego();
                this.loading = true;
                this.ventas = 0;
                this.apuestas = 0;
                this.list = [];
                if(!Object.hasOwn(params,'limit')) params = {...params, ...this.getOptions()};
                TicketService.getList(this.context, {...this.params, ...params, divisa_id: this.divisa.id})
                    .then(({ticketList, ticketCount}) => {
                        this.ventas = 0;
                        this.apuestas = 0;
                        this.list = ticketList;
                        this.count = ticketCount;
                        ticketList.forEach(venta => { 
                            this.ventas += venta.total; 
                            this.apuestas += venta.jugada_list.find(jugada => jugada.numero == this.params.numero)?.monto || 0;
                        });
                    }).finally(() => {
                        this.loading = false;
                        
                    });
            },
            checkJuego: function () {
                JuegoService.get(this.params?.juego_id).then(({ juego }) => {
                    if (juego?.slug === "tripleta-lotto-rey") this.showApuesta = false; else this.showApuesta = true;
                });
            },
            openTicket: function (ticket) {
                const route = this.$router.resolve({
                    name: 'tickets.view',
                    params: {id: ticket.id},
                    query: {'no-layout': 1}
                });
                window.open(new URL(route.href, window.location.origin), '_blank', 'titlebar=no, status=no, menubar=no, height=520 width=680');
            },
            ticketAnulado: function (ticket) {
                const index = this.list.findIndex(_ticket => _ticket.id == ticket.id);

                if (index == -1) return;

                this.list.splice(index, 1, ticket);
            },
            getOptions: function () {
                const params = {};
                let itemsPerPage = null;
                if (this.options.itemsPerPage !== -1) itemsPerPage = this.options.itemsPerPage
                else if (this.count) itemsPerPage = this.count
                params['limit'] = itemsPerPage;
                params['offset'] = (this.options.page * itemsPerPage) - itemsPerPage;
                return params;
            },
        },
        watch: { 
            'params': function() {
                this.getList();
            },
            divisa: function () {
                this.getList();
            },
        }
    }
</script>
<tyle>

</tyle>