export default {
    home: {
        title: "Source",
        access: "Acceder"  
    },
    login: {
        header: {
            title: 'Source',
        },
        form: {
            'reset-password': "Resetear contraseña",
            'login': "login"
        }
    },
    layout: {
        account: {
            subtitle: "admin",
            logout: "Logout"
        }
    }
}