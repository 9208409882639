<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <TaquillaCard :taquilla="taquilla" :loading="loading">
                    <template v-slot:actions>
                        <v-btn color="primary" @click="edit()">
                            <v-icon>mdi-pencil</v-icon> EDITAR
                        </v-btn>
                    </template>
                    <v-tabs v-model="tab" icons-and-text>
                        <v-tabs-slider></v-tabs-slider>
                        <v-tab href="#tab-cupos" v-if="taquilla && $user.isDomain(['operadora', 'master', 'comercializadora', 'grupo'])">Cupos <v-icon>mdi-account-box</v-icon></v-tab>
                    </v-tabs>
                </TaquillaCard>
            </v-col>
            <v-col cols="12">
                <v-card>
                    <v-tabs-items v-model="tab">
                        <v-tab-item value="tab-cupos" v-if="taquilla && $user.isDomain(['operadora', 'master', 'comercializadora', 'grupo'])">
                            <v-card flat>
                                <v-card-text>
                                    <TaquillaJuegoCrud :taquilla="taquilla" has-limit/>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                        
                    </v-tabs-items>
                </v-card>
            </v-col>
        </v-row>
        <TaquillaModal :open.sync="isModalOpen" @saved="getTaquilla()" :taquilla="taquilla"/>
    </v-container>
</template>
<script>
import TaquillaCard from '@components/entities/comercio/taquilla/card';
import TaquillaModal from '@components/entities/comercio/taquilla/modal';

import TaquillaService from '@services/comercio/TaquillaService';

import TaquillaJuegoCrud from '@components/entities/comercio/taquilla/juego/crud';

export default {
    components: {
        TaquillaCard,
        TaquillaModal,
        TaquillaJuegoCrud,
    },
    data: () => ({
        tab: null,
        taquilla: null,
        loading: false,

        isModalOpen: false,
    }),
    created: function () {
        this.getTaquilla();
    },
    methods: {
        getTaquilla: function () {
            this.loading = true;
            TaquillaService
                .get(this.$route.params.id)
                .then(({taquilla}) => {
                    this.taquilla = taquilla;
                    this.$store.dispatch('ui/layout/setTitle', `Taquilla ${this.taquilla?.name}`);
                })
                .finally(() => this.loading = false);
        },
        edit: function () {
            this.isModalOpen = true;
        },
    },
    watch: {
        'comercializadora': function (comercializadora) {
            if (comercializadora) this.$store.dispatch('ui/layout/setTitle', `Comercializadora ${this.comercializadora?.name}`);
        },
    }
}
</script>