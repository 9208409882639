<template>
    <v-btn color="primary" small :loading="loading" :disabled="loading" @click="anular()">
        <v-icon>mdi-text-box-remove</v-icon> ANULAR
    </v-btn>
</template>
<script>
import JuegoResultadoService from '@services/operadora/loteria/JuegoResultadoService';

export default {
    components: {
    },
    props: ['juegoResultado'],
    data: function () {
        return {
            loading: false,
        }
    },
    methods: {
        anular: function () {
            this.$ui.confirm({
                message: `¿estás seguro que deseas anular el resultado del juego ${this.juegoResultado.juego.name} a las ${this.juegoResultado.juego_sorteo.horario}?`,
                textConfirm: 'Anular resultado',
                textCancel: 'Cancelar',
                confirm: () => {
                    this.anularJuegoResultado(this.juegoResultado);
                },
            });
        },
        anularJuegoResultado: function (juegoResultado) {
            this.loading = true;
            JuegoResultadoService.anular(juegoResultado.id)
                .then(({juegoResultado}) => {
                    this.$emit('anulado', juegoResultado);
                    this.$ui.notificate({message: 'Resultado anulado'});
                }).catch(() => {
                    this.$ui.notificate({message: 'Error anulando', color: 'error'});
                }).finally(() => {
                    this.loading = false;
                });
        },
    },
}
</script>