import CrudService from "@utils/CrudService";
import axios from '@plugins/axios';

class JuegoService extends CrudService 
{
    activate(id, options = {}) {
        return axios({
            url: this.getRoute(`${id}/activate`),
            method: 'POST',
            ...options
        });
    }
    deactivate(id, options = {}) {
        return axios({
            url: this.getRoute(`${id}/deactivate`),
            method: 'POST',
            ...options
        });
    }
    reorder(ids, options = {}) {
        return axios({
            url: this.getRoute(`reorder`),
            data: {juegoIds: ids},
            method: 'POST',
            ...options
        });
    }
    getListForResultado(params = {}, options = {}) {
        return axios({
            url: 'operadora/loteria/juego/get-list-for-resultado',
            method: 'GET',
            params,
            ...options,
        });
    }
    getJuegoListForCupoSistema(sistema_id, params = {}, options = {}) {
        return axios({
            url: `operadora/loteria/juego/get-list-for-cupo-sistema/${sistema_id}`,
            method: 'GET',
            params,
            ...options,
        });
    }
}

export const BaseJuegoService = JuegoService

export default new JuegoService({
    domainName:'operadora/loteria',
    entityName:'juego',
    fields: ['image', 'name', 'description', 'loteria_id', 'tipo_juego_id', 'max_tiempo_jugada'],
    query:['search', 'loteria_id', 'tipo_juego_id', 'is_active', 'offset', 'limit', 'owner_id'],
});