import axios from '@plugins/axios';

class CupoService {
    get(context, id, options = {}) {
        return axios({
            url: `comercio/cupo/${context}/${id}`,
            method: 'GET',
            ...options
        });
    }
    getBy(context, filters = {}, options = {}) {
        return axios({
            url: `comercio/cupo/${context}/by`,
            method: 'GET',
            params: filters,
            ...options
        });
    }
    // DEPRECADO; USAR GET_LIMITS; ALERTA: NO ESTA TERMINADO.
    getParentBy(context, filters = {}, options = {}) {
        return axios({
            url: `comercio/cupo/${context}/parent/by`,
            method: 'GET',
            params: filters,
            ...options
        });
    }
    getList(context, filters = {}, options = {}) {
        return axios({
            url: `comercio/cupo/${context}`,
            method: 'GET',
            params: filters,
            ...options
        });
    }
    count(context, filters = {}, options = {}) {
        return axios({
            url: `comercio/cupo/${context}`,
            method: 'GET',
            params: filters,
            ...options
        });
    }
    create(context, data, options = {}) {
        return axios({
            url: `comercio/cupo/${context}`,
            data: data,
            method: 'POST',
            ...options
        });
    }
    update(context, id, data, options = {}) {
        return axios({
            url: `comercio/cupo/${context}/${id}`,
            data: data,
            method: 'PUT',
            ...options
        });
    }
    activate(context, id, options = {}) {
        return axios({
            url: `/comercio/cupo/${context}/${id}/activate`,
            method: 'POST',
            ...options
        });
    }
    deactivate(context, id, options = {}) {
        return axios({
            url: `/comercio/cupo/${context}/${id}/deactivate`,
            method: 'POST',
            ...options
        });
    }
    // WIP. REVISAR SI EL CONTEXTO ESTA FUNCIONANDO ANTES DE USARLO, DE NO ESTARLO, HACERLO FUNCIONAR.
    // LA IDEA ES QUE EL BACKEND TE TRAIGA LOS cupo_jugada, comision, banqueo MAXIMOS DEPENDIENDO DEL CONTEXTO, este mismo debe de revisar los niveles anteriores para poder entregarte el maximo valor que puedes registrar
    // EJEMPLO; cupo_jugada en LOTERIA_CUPO_JUEGO esta en 100, pero en CUPO_MASTER esta en 0, asi que si el contexto es `taquilla` EL LIMITE DEBE ENTREGAR 100.
    getLimits(context, params = {}, options = {}) {
        return axios({
            url:`/comercio/cupo/${context}/limits`,
            method: 'GET',
            params,
            ... options,
        });
    }
}

export const BaseCupoService = CupoService

export default new CupoService();