import Generales from '@pages/reporte/general';
import Figuras from '@pages/reporte/figura';
import ConsolidadoJuegoSorteo from '@pages/reporte/juego-divisa';
import ConsolidadoComercio from '@pages/reporte/comercio';

export default [
    {
        path: '/reporte/general',
        name: 'reporte.general',
        component: Generales,
    },
    {
        path: '/reporte/figura',
        name: 'reporte.figura',
        component: Figuras,
    },
    {
        path: '/reporte/comercio',
        name: 'reporte.consolidado.comercio',
        component: ConsolidadoComercio,
    },
    {
        path: '/reporte/divisa',
        name: 'reporte.consolidado.divisa',
        component: ConsolidadoJuegoSorteo,
    },
];
