import CrudService from "@utils/CrudService";

class TipoJuegoService extends CrudService {}

export const BaseTipoJuegoService = TipoJuegoService;

export default new TipoJuegoService({
    domainName:'operadora/loteria',
    entityName:'tipo-juego',
    fields: ['name', 'digito_figura', 'is_ruleta', 'is_active'],
    query:['search', 'name', 'is_active', 'is_ruleta'],
});