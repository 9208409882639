<template>
    <div>
        <div class="mb-2">
            <v-card>
                <v-card-title>
                    Ventas Por Figura
                </v-card-title>
                <v-card-text>
                    <div class="d-flex align-center mb-2" v-if="params.juego_sorteo_id">
                        <span class="mr-4">SORTEO</span>
                        <Totalizador :loading="loading" :summary="summarySorteo"/>
                    </div>
                    <div class="d-flex align-center mb-2" v-if="weekSummary">
                        <span class="mr-4">DÍA</span>
                        <Totalizador :loading="loadingDaySummary" :summary="daySummary"/>
                    </div>
                    <div class="d-flex align-center" v-if="params.juego_sorteo_id && weekSummary">
                        <span class="mr-4">SEMANA</span>
                        <Totalizador :loading="loadingWeekSummary" :summary="weekSummary"/>
                    </div>
                </v-card-text>
            </v-card>
        </div>
        <v-data-table
            :headers="headers"
            :items="listComputed"
            :items-per-page="-1"
            hide-default-footer
            disable-pagination
            disable-sort
            disable-filtering
            :loading="loading"
        >
            <!-- eslint-disable-next-line -->
            <template v-slot:item.figura="{ item }">
                <v-chip small text class="pl-0">
                    <v-avatar size="25" class="mr-2">
                        <img
                            :src="item.figura.image"
                            alt="John"
                            />
                    </v-avatar>
                    {{ item.figura.value }} - {{ item.figura.label }} {{ (item.ultimo_resultado && `(${item.ultimo_resultado})`) || '' }}
                </v-chip>
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.tickets="{ item }">
                <v-chip small class="mr-2" text>
                    <v-icon class="mr-2" small>mdi mdi-receipt-text</v-icon>
                    {{ item.ticket_count }}
                </v-chip>
            </template>

            <!-- eslint-disable-next-line -->
            <template v-slot:item.venta="{ item }">
                {{ item.venta | currency(item.divisa_code) }}
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.comision="{ item }">
                {{ item.comision | currency(item.divisa_code) }}
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.projected="{ item }">
                <v-chip small label :color="item.premioColor" class="font-weight-bold mr-2">
                    {{ item.projected | currency(item.divisa_code) }} {{' / '}} {{ item.premioPercent | percent }}
                </v-chip>
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.total_projected="{ item }">
                <v-chip small label :color="item.sorteoColor" class="font-weight-bold mr-2">
                    Sorteo: {{ item.sorteoTotal | currency(item.divisa_code) }}
                </v-chip>
                <v-chip v-if="daySummary" small label :color="item.dayColor" class="font-weight-bold mr-2">
                    Día {{ item.dayTotal | currency(item.divisa_code) }}
                </v-chip>
                <v-chip v-if="weekSummary" small label :color="item.weekColor" class="font-weight-bold">
                    Semana {{ item.weekTotal | currency(item.divisa_code) }}
                </v-chip>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import VentaService from '@services/reporte/VentaService';

import Totalizador from '@components/entities/reporte/venta/figura/totalizador';

export default {
    components: {
        Totalizador
    },
    props: {
        params: {
            defaults: function() {
                return {

                };
            },
        }
    },
    data: function() { 
        return {
            list: [],
            count: 0,
            loading: false,  
            loadingDaySummary: true,
            daySummary: null,
            loadingWeekSummary: true,
            weekSummary: null,
            ventas: 0,
            comision: 0,
            subtotal: 0,
            premio: 0,
            total: 0,
        }
    },
    computed: {
        divisa: function () {
            return this.$store.getters['persist/divisa/selected'];
        },
        listComputed: function () {
            return this.list.map(report => {
                let color = 'secondary';
                if (report.percent_projected > 0.1) color = 'primary';
                if (report.percent_projected < 0.1 && report.percent_projected > -0.1) color = 'secondary';
                if (report.percent_projected < -0.1) color = 'error';

                const sorteoTotal = this.subtotal - (report.projected);
                const sorteoPercent = sorteoTotal / this.subtotal;

                let sorteoColor = 'secondary';
                /* if (sorteoPercent > 0.1) sorteoColor = 'primary';
                if (sorteoPercent < 0.1 && sorteoPercent > -0.1) sorteoColor = 'secondary';
                if (sorteoPercent < -0.1) sorteoColor = 'error'; */

                if (sorteoTotal > 0) sorteoColor = 'primary';
                if (sorteoTotal == 0) sorteoColor = 'secondary';
                if (sorteoTotal < 0) sorteoColor = 'error';

                let premioColor = 'secondary';
                let premioPercent = 0;
                if (report.projected) {
                    premioPercent = report.projected / this.ventas;
                    if (premioPercent <= 1) premioColor = 'primary';
                    else premioColor = 'error';
                }

                let dayColor = 'secondary';
                let dayTotal = 0;
                let dayPercent = 0;

                if (this.daySummary) {
                    dayTotal = this.daySummary.total - report.projected;
                    dayPercent = this.daySummary.total / dayTotal;

                    /* if (dayPercent > 0.1) dayColor = 'primary';
                    if (dayPercent < 0.1 && dayPercent > -0.1) dayColor = 'secondary';
                    if (dayPercent < -0.1) dayColor = 'error'; */

                    if (dayTotal > 0) dayColor = 'primary';
                    if (dayTotal  == 0) dayColor = 'secondary';
                    if (dayTotal < 0) dayColor = 'error';
                }

                let weekColor = 'secondary';
                let weekTotal = 0;
                let weekPercent = 0;

                if (this.weekSummary) {
                    weekTotal = this.weekSummary.total - report.projected;
                    weekPercent = this.weekSummary.total / weekTotal;

                    /* if (weekPercent > 0.1) weekColor = 'primary';
                    if (weekPercent < 0.1 && weekPercent > -0.1) weekColor = 'secondary';
                    if (weekPercent < -0.1) weekColor = 'error'; */

                    if (weekTotal > 0) weekColor = 'primary';
                    if (weekTotal == 0) weekColor = 'secondary';
                    if (weekTotal < 0) weekColor = 'error';
                }

                return {
                    ...report,
                    color,
                    sorteoColor,
                    sorteoTotal,
                    sorteoPercent,
                    premioColor,
                    premioPercent,
                    dayColor,
                    dayTotal,
                    dayPercent,
                    weekColor,
                    weekTotal,
                    weekPercent,
                }
            })
        },
        headers: function () {
            return [
                { text: 'Figura',align: 'start', value: 'figura', width: 90 },
                { text: 'Tickets',align: 'start', value: 'tickets' },
                { text: 'Ventas',align: 'center', value: 'venta' },
                { text: 'Comisión',align: 'center', value: 'comision' },
                { text: 'Premio',align: 'center', value: 'projected' },
                { text: 'Total',align: 'start', value: 'total_projected' },
            ];
        },
        summarySorteo: function () {
            return {
                venta: this.ventas,
                comision: this.comision,
                premio: this.premio,
                total: this.total,
            }
        }
    },
    methods: {
        getLegibleDistanciaFecha: function (date) {
            if (!date) return 0;

            const start = this.$luxon.fromSQL(date);
            const end = this.$luxon.now();

            const days = Math.floor(end.diff(start, 'days').toObject().days);

            return days;
        },
        getList: function (params = {}) {
            this.list = [];
            this.loading = true;
            this.ventas = 0;
            this.comision = 0;
            this.premio = 0;
            this.total = 0;
            this.subtotal = 0;

            const parameters = {...this.params, ...params, divisa_id: this.divisa.id};

            VentaService.reportePorFiguraConsolidado(parameters)
                .then(({reportList}) => {
                    reportList.forEach(reporte => {
                        this.ventas += reporte.venta;
                        this.comision += reporte.comision;
                        this.premio += reporte.premio;
                        this.total += reporte.total;
                    });
                    this.subtotal = this.ventas - this.comision;
                    this.list = reportList;
                }).finally(() => {
                    this.loading = false;
                });
        },
        getDaySummary: function (params = {}) {
            this.daySummary = null;
            this.loadingDaySummary = true;

            let parameters = {...this.params, ...params, divisa_id: this.divisa.id};

            delete parameters.juego_sorteo_id;

            VentaService.reporteConsolidado(parameters)
                .then(({reportList}) => {
                    this.daySummary = reportList[0];
                }).finally(() => {
                    this.loadingDaySummary = false;
                });
        },
        getWeekSummary: function (params = {}) {
            this.weekSummary = null;
            this.loadingWeekSummary = true;

            let parameters = {...this.params, ...params, divisa_id: this.divisa.id};
            
            const dateParam = parameters.date;
            delete parameters.date;
            delete parameters.juego_sorteo_id;

            const DateTime = this.$luxon;
            // Obtén la fecha y hora actual
            let ahora = DateTime.fromFormat(dateParam, 'yyyy-LL-dd').setZone('America/Caracas');
            // Encuentra el lunes de la semana actual
            let lunes = ahora.startOf('week');
            // Asegúrate de que la hora esté seteada en 00:00:00
            lunes = lunes.set({ hour: 0, minute: 0, second: 0 });

            parameters.daterange = [lunes.toISODate(), dateParam];

            VentaService.reporteConsolidado(parameters)
                .then(({reportList}) => {
                    this.weekSummary = reportList[0];
                }).finally(() => {
                    this.loadingWeekSummary = false;
                });
        }
    },
    watch: { 
        'params': function () {
            this.getList();
            this.getDaySummary();
            this.getWeekSummary();
        },
        'divisa': function () {
            this.getList();
            this.getDaySummary();
            this.getWeekSummary();
        }
    },
    mounted: function () {
        this.getList();
        this.getDaySummary();
        this.getWeekSummary();
    }
}
</script>
<style>

</style>