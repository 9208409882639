<template>
  <div class="d-flex align-center" style="
      min-height: 100vh;
      background-image: url(/img/background.jpg);
      background-size: cover;
      background-position: center;
    ">
    <v-overlay
      absolute
      >
      <v-card width="400" shaped>
        <v-card-title class="d-flex align-center flex-column">
            <div class="text-h4">
                <img src="/img/source/source-monedas-color-128.png"/>
            </div>
            <h3 class="text-h3">{{$t('general.home.title')}}</h3>
        </v-card-title>
        <v-card-text class="text-center">
          <v-btn color="primary" dark large href="/login">{{$t('general.home.access')}}</v-btn>
        </v-card-text>
      </v-card>
    </v-overlay>
  </div>
</template>

<script>

  export default {
    data: () => ({
    }),
  }
</script>
