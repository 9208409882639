export default {
    client: {
        title: "Administrador de Usuários",
        'client-card': {
            title: "{client-count} clientes",
            subtitle: "Nostrud ut ullamco sunt",
            action: "adicionar cliente",
        },
        'client-list': {
            title: "Lista de clientes",
            headers: {
                user: {
                    email: "Email"
                },
                fullname: "Nome Completo",
                registration_number: "CPF / CNPJ",
                phone: "Telefone",
                actions: "Ações"
            },
            items: {
                actions: {
                    see: "Ver",
                    edit: "Editar",
                    delete: "Remover",
                }
            }
        }
    },
    'client-form': {
        title: "Formulário do cliente",
        forms: {
            user: {
                title: "Conta de usuário",
            },
            client: {
                title: "Dados do cliente",
            },
            reset: "REDEFINIR",
            save: "GUARDAR DADOS",
        }
    },
    'client-view': {
        title: "Painel do cliente",
    },
    'debit': {
        title: '2ª VIA DO BOLETO',
        excerpt: 'Para o download da 2ª via do boleto, atente-se a número do documento',
        search: 'Procurar',
        list: {
            header: 'BOLETOS EM ABERTO',
            headers: {
                ndoc: 'Numero do documento',
                amount: 'Valor',
                expire_at: 'Data de vencimento', 
                pdf: 'PDF',
                conditionType: 'Tipo de condição da Parcela',
                dueDate: 'Data de vencimento',
                actions: 'Ações',
            }
        }
    }
}