<template>
    <v-form ref="form" style="width: 100%" v-model="valid">
        <div class="d-flex align-center mb-3">
            <div class="mr-3 mb-3">
                <DateRangeInput v-model="fields.daterange" @change="changed('daterange', $event)" label="Rango de Fechas"/>
            </div>
            <div class="mr-3 mb-3">
                <SelectorJuego v-model="fields.juego_id" @change="changed('juego_id', $event)" filled/>
            </div>
            <div class="mr-3 mb-3">
                <v-select
                    :items="divisaList"
                    :loading="isLoadingDivisaList"
                    :disabled="isLoadingDivisaList"
                    item-text="name"
                    item-value="id"
                    label="Divisa"
                    @change="changed('divisa_id', $event)"
                    v-model="fields.divisa_id"
                    hide-details
                    filled
                    >
                </v-select>
            </div>
        </div>
        <v-divider class="my-4" />
        <div class="d-flex">
            <v-spacer/>
            <v-btn text class="mr-3" :disabled="loading" @click="reset()">Reiniciar Filtros</v-btn>
            <v-btn color="primary" :disabled="loading" :loading="loading" @click="search()"><v-icon>mdi-magnify</v-icon>Buscar</v-btn>
        </div>
    </v-form>
    
</template>

<script>
import Search from '@mixins/search';
import DivisaService from '@services/operadora/general/DivisaService';

export default {
    components: {
        
    },
    props: ['value', 'loading'],
    mixins: [Search],
    data: function ()  {
        return {
            divisaList: [],
            isLoadingDivisaList: false,

            defaultFields: {
                daterange: [this.$luxon.local().toISODate(), this.$luxon.local().toISODate()],
                juego_id: null,
                divisa_id: null,
            },
        }
    },
    computed: {
        
    },
    created: function () {
        this.getDivisaList();
    },
    mounted: function () {
    },
    methods: {
        getDivisaList: function () {
            this.divisaList = [];
            DivisaService.getList().then(({ divisaList }) => {
                this.divisaList = [{id: null, name: 'Todos'},...divisaList];
            });
        },
        
    },
}
</script>

<style>

</style>