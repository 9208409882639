<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12"  md="3">
                <v-card elevation="2" :loading="loading">
                    <v-card-title>{{ juegoCount }} Juegos</v-card-title>
                    <v-card-actions>
                        <!--v-btn color="primary" :to="{name: 'operadora.juego.nuevo'}"><v-icon>mdi-account-box</v-icon> AÑADIR JUEGO</v-btn-->
                        <v-btn color="secondary" @click="openResultadoModal()"><v-icon>mdi-account-box</v-icon> AÑADIR RESULTADO</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card elevation="2">
                    <v-card-title>
                        <JuegoSearch v-model="params" @search="getJuegoList()"/>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table
                            :headers="headers"
                            :items="juegoList"
                            :options.sync="options"
                            :server-items-length="juegoCount"
                            :loading="loading"
                            >
                            <!-- eslint-disable-next-line -->
                            <template v-slot:item.is_active="{ item }">
                                <div>
                                    <v-chip v-if="item.is_active" color="success" small>Activo</v-chip>
                                    <v-chip v-else color="error" small>Inactivo</v-chip>
                                </div>
                            </template>
                            <!-- eslint-disable-next-line -->
                            <template v-slot:item.name="{ item }">
                                <div>
                                    <div>{{ `${item.name}` }}</div>
                                    <small>{{ `${item.slug}` }}</small>
                                </div>
                            </template>
                            <!-- eslint-disable-next-line -->
                            <template v-slot:item.actions="{ item }">
                                <div>
                                    <v-btn color="accent" icon @click="openResultadoModal(item)">
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-btn>
                                    <!--v-btn color="secondary" icon :to="{name: 'operadora.loteria.juego.editar', params: {id: item.id}}">
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-btn>
                                    <v-btn color="primary" icon :to="{name: 'operadora.loteria.juego.view', params: {id: item.id}}">
                                        <v-icon>mdi-eye</v-icon>
                                    </v-btn-->
                                </div>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card> 
            </v-col>
        </v-row>
        <JuegoResultadoModal :juego="juego" :open.sync="isModalResultadoOpen"/>
    </v-container>
</template>

<script>
import JuegoService from '@services/operadora/loteria/JuegoService';

import JuegoResultadoModal from'@components/entities/loteria/juego-resultado/modal-form';
import JuegoSearch from '@components/entities/loteria/juego/search';

export default {
    components: {
        JuegoResultadoModal,
        JuegoSearch
    },
    data: () => ({

        options: {},
        loading: false,
        juegoList: [],
        juegoCount: 0,

        isModalResultadoOpen: false,
        juego: null,

        params: {}
    }),
    computed: {
        headers: function() {
            return [
                {
                    text: 'Imagen',
                    value: 'image',
                    align: 'start',
                    width: 100,
                    sortable: false
                },
                {
                    text: 'Nombre',
                    value: 'name',
                    align: 'start',
                    sortable: false
                },
                {
                    text: 'Loteria',
                    value: 'loteria.name',
                    align: 'start',
                    sortable: false
                },
                {
                    text: 'Tipo de Juego',
                    value: 'tipo_juego.name',
                    align: 'start',
                    sortable: false
                },
                {
                    text: 'Estado',
                    value: 'is_active',
                    align: 'center',
                    width: 150,
                    sortable: false
                },
                {
                    text: 'Acciones',
                    value: 'actions',
                    align: 'right',
                    width: 200,
                    sortable: false
                },
            ];
        }
    },
    created: function () {
        this.$store.dispatch('ui/layout/setTitle', 'Juego');
    },
    methods: {
        getJuegoList: function (params = {}) {
            this.loading = true;
            JuegoService.getList({...this.params, ...params})
                .then(({juegoList, juegoCount}) => {
                    this.juegoList = juegoList;
                    this.juegoCount = juegoCount;
                }).finally(() => {
                    this.loading = false;
                });
        },
        openResultadoModal: function (juego = null) {
            this.juego = juego;
            this.isModalResultadoOpen = true;
        }
    },
    watch: {
      options: {
        handler: function (options) {
            const itemsPerPage = options.itemsPerPage !== -1 ? options.itemsPerPage : null;
            const offset =  itemsPerPage !== null ? (options.page * itemsPerPage) - itemsPerPage : null;

            const params = {};
            if (itemsPerPage !== null) params['limit'] = itemsPerPage;
            if (offset !== null) params['offset'] = offset;
            
            this.getJuegoList(params);
        },
        deep: true,
      },
    },
}
</script>

<style>

</style>