<template>
    <div>
        <p>{{ count }} Usuarios</p>
        <v-data-table
            :headers="headers"
            :items="list"
            :options.sync="options"
            :server-items-length="count"
            :loading="loading"
            >
            <!--v-btn color="primary" icon :to="{name: 'comercio.comercializadora.view', params: {id: item.id}}">
                <v-icon>mdi-eye</v-icon>
            </v-btn-->
            <!-- eslint-disable-next-line -->
            <template v-slot:item.username="{ item }">
                <v-chip 
                    :color="item.is_active ? 'primary' : 'secondary'" 
                    small 
                    label
                >
                    <v-icon left>mdi-account-circle-outline</v-icon>
                    {{ item.username }}
                </v-chip>
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.comercio="{ item }">
                <v-chip 
                    :color="item.is_active ? 'primary' : 'secondary'" 
                    small 
                    label
                    :to="{name: 'comercio.'+item.comercio_type+'.view', params: {id: item.comercio_id}}"
                >
                    {{ item.comercio_type }} - {{ item.username }}
                </v-chip>
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.user_created="{ item }">
                <div> -- </div>
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.actions="{ item }">
                <v-btn color="primary" small icon><v-icon>mdi-pencil</v-icon></v-btn>
                <v-btn color="primary" small icon><v-icon>mdi-magnify</v-icon></v-btn>
                <v-btn color="primary" small icon><v-icon>mdi-close-circle</v-icon></v-btn>
            </template>
        </v-data-table>
    </div>
</template>
<script>
    import UsuarioService from '@services/usuario/UsuarioService';

    export default {
        props: {
            params: {
                
            }
        },
        data: function(){
            return {
                options: {},
                list: [],
                count: 0,
                loading :false,
            }
        },
        computed:{
            headers: function () {
                return [
                    {
                        text: 'Usuario',
                        value: 'username',
                        sortable: false
                    },
                    {
                        text: 'Comercio',
                        value: 'comercio',
                        sortable: false
                    },
                    {
                        text: 'Ult. Ingreso',
                        value: 'user_created',
                        align: 'center',
                        sortable: false
                    },
                    {
                        text: 'Acciones',
                        value: 'actions',
                        align: 'right',
                        sortable: false
                    },
                ];
            },
        },
        created: function () {
            this.getList();
        },
        methods:{
            getList: function (params = {}) {
                this.loading = true;
                this.list = [];
                UsuarioService.getList({...this.params, ...params})
                    .then(({userList}) => {
                        this.list = userList;
                        this.count = userList.length;
                    }).finally(() => {
                        this.loading = false;
                    });
            },
        },
        watch: { 
            'params': function() {
                this.getList();
            },
            options: {
                handler: function (options) {
                    const itemsPerPage = options.itemsPerPage !== -1 ? options.itemsPerPage : null;
                    const offset =  itemsPerPage !== null ? (options.page * itemsPerPage) - itemsPerPage : null;

                    const params = {};
                    if (itemsPerPage !== null) params['limit'] = itemsPerPage;
                    if (offset !== null) params['offset'] = offset;
                    
                    this.getList(params);
                },
                deep: true,
            },
        }
    }
</script>
<tyle>

</tyle>