<template>
    <div class="d-inline">
        <v-btn v-if="user" color="white" text small @click="dialog = !dialog"><v-icon small>mdi-pencil</v-icon> EDITAR</v-btn>
        <v-btn v-else color="white" text small @click="dialog = !dialog">CREAR</v-btn>
        <v-dialog v-model="dialog" width="600">
            <v-card shaped>
                <v-card-title class="primary white--text">
                    <h3>Actualizar Datos</h3>
                </v-card-title>
                <v-card-text class="pa-3">
                    <v-container fluid>
                        <UserForm :loading="loading" v-model="userData" :default="defaultUserData" :valid.sync="isUserDataValid" :context="context"/>
                    </v-container>
                </v-card-text>
                <v-divider/>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn color="primary" text :disabled="loading" @click="reset()">RESETEAR</v-btn>
                    <v-btn color="primary" :disabled="!isValid || loading" :loading="loading" @click="submit()">
                        <v-icon>mdi-content-save</v-icon>
                        <span v-if="this.user">Editar</span>
                        <span v-else>Crear</span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import UserForm from "./form-limited"

export default {
    components: {
        UserForm
    },
    props: ['user', 'open'],
    data: () => ({
        loading: false,
        dialog: false,

        context: 'new',

        userData: {},
        defaultUserData: null,
        isUserDataValid: false,

        hasUser: false,
    }),
    mounted: function () {
        this.reset();
    },
    computed: {
        isValid: function () {
            return Object.keys(this.userData).length && this.isUserDataValid;
        }
    },
    methods: {
        close: function () {
            this.dialog = false;
        },
        submit: function () {
            this.loading = true;
            this.$store.dispatch('persist/auth/update', {...this.userData})
                .then(this.userData).then(({ user }) => {
                    this.$ui.notificate({message: 'Datos guardados exitosamente'});
                    this.emit(user);
                    this.close();
                }).catch(() => {
                    this.$ui.notificate({message: 'Error guardando los datos, por favor contactar con administración', color: 'error'});
                }).finally(() => this.loading = false);
        },
        emit: function (data) {
            this.$emit('saved', data);
        },
        reset: function () {
            this.context = 'edit';
            this.defaultUserData = {... this.user};
            this.userData = {
                username: this.user.username,
                password: "",
            };
        }
    },
    watch: {
        dialog: function () {
            this.reset();
        },
    }
}
</script>
