<template>
<v-container fluid>
    <v-row>
        <v-col>
            <v-card class="mb-3">
                <v-card-title>Filtros</v-card-title>
                <v-card-text>
                    <LoteriaSearch v-model="params" :loading="loading" @search="getList()"/>
                </v-card-text>
            </v-card>
            <v-card>
                <v-card-title>
                    <div class="d-flex justify-space-between" style="width: 100%">
                        <div class="flex-1">{{count}} Loterias</div>
                        <LoteriaModal @saved="getList()"/>
                    </div>
                </v-card-title>
                <v-card-text>
                    <v-data-table
                        :headers="headers"
                        :items="list"
                        :items-per-page="-1"
                        hide-default-footer
                        disable-pagination
                        disable-sort
                        disable-filtering
                        :loading="loading"
                        >
                        <!-- eslint-disable-next-line -->
                        <template v-slot:item.name="{ item }">
                            <v-chip :color="item.is_active ? 'primary': 'error'" label small>
                                {{ item.name }}
                            </v-chip>
                        </template>
                        <!-- eslint-disable-next-line -->
                        <template v-slot:item.actions="{ item }">
                            <div>
                                <LoteriaModal class="mr-2" @saved="getList()" :loteria="item"/>
                                <v-btn small text :to="{name: 'operadora.loteria.loteria.view', params: {id: item.id}}">
                                    <v-icon small>mdi-eye</v-icon>
                                    VER
                                </v-btn>
                            </div>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
import LoteriaService from '@services/operadora/loteria/LoteriaService';
import LoteriaSearch from '@components/entities/loteria/loteria/search';
import LoteriaModal from '@components/entities/loteria/loteria/modal';

export default {
    components: {
        LoteriaSearch,
        LoteriaModal
    },
    data: () => ({
        search: '',
        count: 0,
        list: [],
        loading: false,

        item: null,

        params: {},
    }),
    computed: {
        headers: function() {
            return [
                {
                    text: 'Nombre',
                    value: 'name',
                    align: 'start',
                    sortable: false
                },
                {
                    text: 'Acciones',
                    value: 'actions',
                    align: 'right',
                    sortable: false,
                },
                {
                    text: 'Nombre',
                    value: 'shortname',
                    align: ' d-none',
                },
            ];
        }
    },
    created: function () {
        this.$store.dispatch('ui/layout/setTitle', '');
        this.getList();
    },
    methods: {
        add: function () {
            this.item = null;
            this.isModalOpen = true;
        },
        edit: function (item) {
            this.item = item;
            this.isModalOpen = true;
        },
        getList: function () {
            this.loading = true;
            LoteriaService.getList(this.params)
                .then(({loteriaList, loteriaCount}) => {
                    this.list = loteriaList;
                    this.count = loteriaCount;
                }).finally(() => {
                    this.loading = false;
                });
        },
    }
}
</script>

<style>

</style>