import axios from '@plugins/axios';

class SistemaService {
    get(id, options = {}) {
        return axios({
            url: `operadora/comercio/sistema/${id}`,
            method: 'GET',
            ...options,
        });
    }
    getBy(params = {}, options = {}) {
        return axios({
            url: `operadora/comercio/sistema/by`,
            method: 'GET',
            params,
            ...options,
        });
    }
    getList(params = {}, options = {}) {
        return axios({
            url: `operadora/comercio/sistema`,
            method: 'GET',
            params,
            ...options
        });
    }
    create(data = {}, options = {}) {
        return axios({
            url: `operadora/comercio/sistema`,
            method: 'POST',
            data,
            ... options,
        });
    }
    update(id, data, options = {}) {
        return axios({
            url: `operadora/comercio/sistema/${id}`,
            method: 'PUT',
            data,
            ...options,
        });
    }
    delete(id, options = {}) {
        return axios({
            url: `operadora/comercio/sistema/${id}`,
            method: 'DELETE',
            ...options,
        })
    }
    renewToken(id, options = {}) {
        return axios({
            url: `operadora/comercio/sistema/${id}/renew-token`,
            method: 'POST',
            ...options,
        });
    }
}

export const BaseSistemaService = SistemaService;

export default new SistemaService();