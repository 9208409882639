<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <ComercializadoraCard :comercializadora="comercializadora" :loading="loading">
                    <template v-slot:actions>
                        <v-btn color="primary" @click="edit()">
                            <v-icon>mdi-pencil</v-icon> EDITAR
                        </v-btn>
                    </template>
                    <v-tabs v-model="tab" icons-and-text>
                        <v-tabs-slider></v-tabs-slider>
                        <v-tab href="#tab-cupos" v-if="comercializadora && $user.isDomain(['operadora', 'master'])">Cupos <v-icon>mdi-account-box</v-icon></v-tab>
                        <v-tab href="#tab-usuarios" v-if="comercializadora && $user.isDomain(['operadora', 'master', 'comercializadora'])">Usuarios <v-icon>mdi-account-box</v-icon></v-tab>
                    </v-tabs>
                </ComercializadoraCard>
            </v-col>
            <v-col cols="12">
                <v-card elevation="5">
                    <v-tabs-items v-model="tab">
                        <v-tab-item value="tab-cupos" v-if="comercializadora && $user.isDomain(['operadora', 'master'])">
                            <v-card flat>
                                <v-card-text>
                                    <ComercializzadoraJuegoCrud :comercializadora="comercializadora"/>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item value="tab-usuarios" v-if="comercializadora && $user.isDomain(['operadora', 'master', 'comercializadora'])">
                            <v-card flat>
                                <v-card-title>
                                    Usuarios
                                </v-card-title>
                                <v-card-text>
                                    <UserCrud comercioType="comercializadora" :comercioId="comercializadora.id"/>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card>
            </v-col>
        </v-row>
        <ComercializadoraModal :open.sync="isModalOpen" @saved="getComercializadora()" :comercializadora="comercializadora"/>
    </v-container>
</template>
<script>
import ComercializadoraCard from '@components/entities/comercio/comercializadora/card';
import ComercializadoraModal from '@components/entities/comercio/comercializadora/modal';

import ComercializadoraService from '@services/comercio/ComercializadoraService';

import ComercializzadoraJuegoCrud from '@components/entities/comercio/comercializadora/juego/crud';
import UserCrud from '@components/entities/comercio/user/crud';

export default {
    components: {
        ComercializadoraCard,
        ComercializadoraModal,
        ComercializzadoraJuegoCrud,
        UserCrud
    },
    data: () => ({
        tab: null,
        comercializadora: null,
        loading: false,

        isModalOpen: false,
    }),
    created: function () {
        this.getComercializadora();
    },
    methods: {
        getComercializadora: function () {
            this.loading = true;
            ComercializadoraService
                .get(this.$route.params.id)
                .then(({comercializadora}) => {
                    this.comercializadora = comercializadora;
                    this.$store.dispatch('ui/layout/setTitle', `Comercializadora ${this.comercializadora?.name}`);
                })
                .finally(() => this.loading = false);
        },
        edit: function () {
            this.isModalOpen = true;
        },
    },
    watch: {
        'comercializadora': function (comercializadora) {
            if (comercializadora) this.$store.dispatch('ui/layout/setTitle', `Comercializadora ${this.comercializadora?.name}`);
        },
    }
}
</script>