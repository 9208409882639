import axios from '@plugins/axios';

class JuegoService {
    getList(params = {}, options = {}) {
        return axios({
            url: `ventana/juego`,
            method: 'GET',
            params,
            ...options
        });
    }
}

export const BaseJuegoService = JuegoService;

export default new JuegoService();