<template>
    <v-form ref="form" v-model="valid" :disabled="loading">
        <v-row dense>
            <v-col cols="12">
                <v-card :loading="isLoadingLimits" outlined>                
                    <v-card-text>
                        <v-card v-if="isLoadingLimits" elevation="1">
                            <v-card-text>
                                Cargando...
                            </v-card-text>
                        </v-card>
                        <v-card v-else elevation="1">
                            <ul v-if="limits">
                                <li>Cupo Maximo: {{limits.cupo_jugada }}</li>
                                <li>Máxima Comisión: {{limits.comision | percent}}</li>
                                <li>Banqueo Máximo: {{limits.banqueo | percent}}</li>
                            </ul>
                        </v-card>
                        <v-container fluid>
                            <v-row>
                                <v-col cols="12" md="4">
                                    <v-text-field
                                        label="Cupo Jugada"
                                        v-model="fields.cupo_jugada"
                                        :rules="rules.cupo_jugada"
                                        :hint="hints.cupo_jugada"
                                        :disabled="disabled"
                                        hide-details="auto"
                                        persistent-hint
                                        filled
                                        min="0"
                                        type="number"
                                        @change="changed('cupo_jugada')"
                                        />
                                </v-col>
                                <v-col cols="12" md="4">
                                    <PercentInput 
                                        label="Comisión"
                                        v-model="fields.comision"
                                        :rules="rules.comision"
                                        :hint="hints.comision"
                                        :disabled="disabled"
                                        @change="changed('comision')"
                                        />
                                </v-col>
                                <v-col cols="12" md="4">
                                    <PercentInput 
                                        label="Banqueo"
                                        v-model="fields.banqueo"
                                        :rules="rules.banqueo"
                                        :hint="hints.banqueo"
                                        :disabled="disabled"
                                        @change="changed('banqueo')"
                                        />
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        label="Mínimo Apuesta"
                                        v-model="fields.min_apuesta"
                                        :rules="rules.min_apuesta"
                                        :hint="hints.min_apuesta"
                                        :disabled="disabled"
                                        hide-details="auto"
                                        persistent-hint
                                        filled
                                        min="0"
                                        type="number"
                                        @change="changed('min_apuesta')"
                                        />
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        label="Máximo Apuesta"
                                        v-model="fields.max_apuesta"
                                        :rules="rules.max_apuesta"
                                        :hint="hints.max_apuesta"
                                        :disabled="disabled"
                                        hide-details="auto"
                                        persistent-hint
                                        filled
                                        :min="fields.min_apuesta"
                                        type="number"
                                        @change="changed('max_apuesta')"
                                        />
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-form>
</template>
<script>
import Form from '@/mixins/form';

import CupoService from '@/services/comercio/CupoService';

export default {
    mixins: [Form],
    props: {
        juego_id: {
            required: true,
        },
        divisa_id: {
            required: true,
        }
    },
    data: function () {
        return {
            limits: null,
            isLoadingLimits: false,
        };
    },
    mounted: function () {
        this.getLimits();
    },
    computed: {
        defaultFields: function () {
            return {
                cupo_jugada: 0,
                min_apuesta: 0,
                max_apuesta: 0,
                comision: 0,
                banqueo: 0,
            }
        },
        rules: function () {
            return {
                cupo_jugada: [
                    v => (v >= 0) || "El cupo de jugada no debe ser menor a Cero(0)",
                    ...(
                        this.limits && this.limits.cupo_jugada ?
                            [v => (v <= this.limits.cupo_jugada) || `Solicite a la administracion para aumentar el limite de juego`]:
                            []
                    ),
                ],
                comision: [
                    v => (v >= 0) || "El % de comisión debe ser mayor a Cero(0)",
                    v => (v <= 100) || "El % de comisión no debe ser mayor a Cien(100)",
                    ...(
                        this.limits ?
                            [v => !(v > this.limits.comision * 100) || `El % de comisión no debe ser mayor a ${this.limits.comision*100}`]:
                            []
                    ),
                ],
                banqueo: [
                    v => (v >= 0) || "El % de banqueo no debe ser menor a Cero(0)",
                    v => (v <= 100) || "El % de banqueo no debe ser mayor a Cien(100)",
                    ...(this.limits ? [
                            v => !(v > 0 && this.limits.banqueo == 0) || "Contacte a su administrador para asignar % de banqueo",
                            v => !(v > this.limits.banqueo * 100) || `El % de banqueo no debe ser mayor a ${this.limits.banqueo*100}`,
                        ]: []),
                ],
                min_apuesta: [
                    v => (v >= 0) || "El mínimo de apuesta no debe ser menor a Cero(0)",
                    v => (v <= parseFloat(this.fields.max_apuesta)) || `El mínimo de apuesta no debe ser superior al máximo de apuesta`,
                ],
                max_apuesta: [
                    v => !!v || 'Debes indicar el máximo de apuesta',
                    v => (v > 0) || "El máximo de apuesta debe ser mayor a Cero(0)",
                    v => (v >= parseFloat(this.fields.min_apuesta)) || `El máximo de apuesta no debe ser inferior al mínimo de apuesta`,
                ],
            };
        },
        user: function () {
            return this.$store.getters['persist/auth/user'];
        },
        hints: function () {
            return {
                cupo_jugada: !parseFloat(this.fields.cupo_jugada) ? 'Sin cupo de jugada' : '',
                min_apuesta: null,
                max_apuesta: null,
                comision: !parseFloat(this.fields.comision) ? 'Sin comisión' : '',
                banqueo: !parseFloat(this.fields.banqueo) ? 'Sin banqueo' : '',
            }
        },  
        disabled: function () {
            if (!this.limits) return true;
            // OTROS CASOS
            return false;
        },
    },
    methods: {
        getLimits: function () {
            this.isLoadingLimits = true;
            CupoService
                .getLimits('sistema', {divisa_id: this.divisa_id, juego_id: this.juego_id})
                .then(({ limits }) => {
                    this.limits = limits;
                    this.$nextTick(() => {
                        this.$refs.form.resetValidation();
                    });
                })
                .finally(() => {
                    console.log('test');
                    this.isLoadingLimits = false;
                    this.$refs.form.validate(); 
                })
        }
    },
    watch: {
        'divisa_id': function () {
            this.getLimits();
        },
        'juego_id': function () {
            this.getLimits();
        },
    }
}
</script>