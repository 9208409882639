<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <v-card class="mb-3">
                    <v-card-title>Filtros</v-card-title>
                    <v-card-text>
                        <FiguraSearch 
                            context="sistema"
                            @search="search($event)" 
                            :default="params" 
                            :juego.sync="juego"
                            />
                    </v-card-text>
                </v-card>
                <v-card>
                    <v-card-text>
                        <component :is="reporte" context="sistema" :params="params"/>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
    </template>
    
    <script>
    import FiguraSearch from '@components/entities/reporte/venta/figura/search';
    
    import Detallado from '@components/entities/reporte/venta/figura/detallado';
    import Consolidado from '@components/entities/reporte/venta/figura/consolidado';
    import TicketList from '@components/entities/ticket';
    
    import TripletaFigura from '@components/entities/reporte/venta/game/tripleta/figura';
    
    export default {
        components: {
                Detallado,
                Consolidado,
                TicketList,
                TripletaFigura,
                FiguraSearch,
            },
        props: {
            
        },
        data: function() {
            return {
                params: this.setQuery({
                    date: this.$luxon.now().toISODate(),
                }),
                juego: null,
            }
        },
        created: function () {
            this.$store.dispatch('ui/layout/setTitle', '');
        },
        computed:{
            reporte: function() {
                if (!this.juego) return '';
                if (this.juego.tipo_juego.slug == 'tripleta') {
                    return 'TripletaFigura';
                }
    
                if (this.params?.taquilla_id) return 'TicketList';
                if (this.params?.numero && this.params?.juego_sorteo_id) return 'Detallado';
                return 'Consolidado';    
            },
        },
        methods: {
            search: function (params) {
                this.params = params;
            },
            setQuery: function (defaultParams = {}) {
                const query = {... this.$route.query};
    
                for (const key in query) {
                    if (Object.hasOwnProperty.call(query, key)) {
                        const element = query[key];
    
                        if (key == 'numero') {
                            query[key] = element;
                            continue;
                        }
    
                        if (key == 'consolidado') {
                            if (element == 'true') query[key] = true;
                            else query[key] = false;
                            continue;
                        }
    
                        if (key == 'divisaList') {
                            if (Array.isArray(element)) query[key] = element.map(value => parseInt(value));
                            else query[key] = [parseInt(element)];
                            continue;
                        }
    
                        query[key] = /^-?[\d.]+(?:e-?\d+)?$/.test(element) ? Number.parseFloat(element): element;
                    }
                }
    
                return { ... defaultParams, ... query };
            }
        },
        watch: {
            '$route.query': {
                handler: function () {
                    this.params = this.setQuery(this.params);
                },
                deep: true,
            }
        }
    }
    </script>
    <style>
    
    </style>