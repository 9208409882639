<template>
    <div>
        <div class="d-flex">
            <ConsolidarGeneral :params="params" :summary="sumary" :loading="loading"/>
            <div class="ml-auto"><v-btn @click="exportToExcel">Exportar a Excel</v-btn></div>
        </div>
        <br/>
        <v-data-table
            :headers="headers"
            :items="list"
            :items-per-page="-1"
            :loading="loading"
            hide-default-footer
            disable-pagination
            disable-filtering
            show-expand
            single-expand
            item-key="juego"
            >
            <!-- eslint-disable-next-line -->
            <template v-slot:item.horario="{ item }">
                {{ item.juego }}
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.venta="{ item }">
                {{ item.venta | currency }}
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.comision="{ item }">
                {{ item.comision | currency }}
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.premio="{ item }">
                {{ item.premio | currency }}
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.total="{ item }">
                <v-chip small label :color="item.percent > 0.80 ? 'error': 'primary'" class="font-weight-bold mr-2">
                    {{ item.total | currency(item.divisa_code) }} {{' / '}} {{ item.percent | percent }}
                </v-chip>
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.banqueo="{ item }">
                {{ item.banqueo[0] | currency }} / {{ item.banqueo[1] | currency }}
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="pa-2">
                    <v-card outlined color="gray darken-3">
                        <v-card-text class="pa-0">
                            <v-data-table
                                :headers="headers"
                                :items="item.sorteos"
                                :items-per-page="-1"
                                hide-default-footer
                                disable-pagination
                            >
                                <!-- eslint-disable-next-line -->
                                <template v-slot:item.horario="{ item }">
                                    <v-btn class="mr-2" text small @click="open(item, 'window')">
                                        <v-icon class="mr-2" small>mdi mdi-eye-outline</v-icon>
                                        {{ item.juego }} - {{ item.horario }} 
                                    </v-btn>
                                </template>
                                <!-- eslint-disable-next-line -->
                                <template v-slot:item.venta="{ item }">
                                    {{ item.venta | currency }}
                                </template>
                                <!-- eslint-disable-next-line -->
                                <template v-slot:item.comision="{ item }">
                                    {{ item.comision | currency }}
                                </template>
                                <!-- eslint-disable-next-line -->
                                <template v-slot:item.premio="{ item }">
                                    {{ item.premio | currency }}
                                </template>
                                <!-- eslint-disable-next-line -->
                                <template v-slot:item.total="{ item }">
                                    <v-chip small label :color="item.percent > 0.80 ? 'error': 'primary'" class="font-weight-bold mr-2">
                                        {{ item.total | currency(item.divisa_code) }} {{' / '}} {{ item.percent | percent }}
                                    </v-chip>
                                </template>
                                <!-- eslint-disable-next-line -->
                                <template v-slot:item.banqueo="{ item }">
                                    {{ item.banqueo[0] | currency }} / {{ item.banqueo[1] | currency }}
                                </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </td>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import * as excelJS from 'exceljs';
import { saveAs } from "file-saver";
import VentaService from "@services/reporte/VentaService"
import ConsolidarGeneral from '@components/entities/reporte/venta/general/consolidado/box';
export default {
    components: {
        ConsolidarGeneral,
    },
    props: {
        params: {
            defaults: function () {
                return {};
            }
        },
        context: {
            default: 'comercio',
        },
    },
    data: function() { 
        return {
            headers: [
                { text: 'Juego', align: 'start', value: 'horario' },
                { text: 'Ventas', value: 'venta' },
                { text: 'Comisión', value: 'comision' },
                { text: 'Premios', value: 'premio' },
                { text: 'Total', value: 'total'},
                { text: 'Banqueo',align: 'center', value: 'banqueo' },
            ],
            list: [],
            sumary: [],
            loading :false,
            count: 0,
        }
    },
    computed: {
        divisa: function () {
            return this.$store.getters['persist/divisa/selected'];
        },
        user: function () {
            return this.$store.getters['persist/auth/user'];
        },
    },
    mounted: function () {
        this.getList();
    },
    methods: {
        getList: function () {
            this.loading = true;
            this.list = [];
            const comercio = [];
            if (this.user.comercio_type !== 'operadora') comercio[this.getComType(this.user.comercio_type)] = this.user.comercio_id;

            const params = { ... this.params, divisa_id: this.divisa.id, ...comercio };

            if (this.context == 'sistema') {
                params['group_by'] = 'sistema';
                params['comercio_type'] = 'sistema';
            }

            VentaService.reportePorSorteoConsolidado(params)
                .then(({reportList}) => {
                    this.list = reportList.reduce((consolidadoList, reporte) => {
                        const reporteConsolidado = consolidadoList.find((reporteConsolidado) => reporteConsolidado.juego_id == reporte.juego_id);
                        if (!reporteConsolidado) {
                            consolidadoList.push({
                                juego_id: reporte.juego_id,
                                juego: reporte.juego,
                                venta: reporte.venta,
                                comision: reporte.comision,
                                premio: reporte.premio,
                                total: reporte.total,
                                banqueo: [reporte.banqueo[0], reporte.banqueo[1]],
                                sorteos: [reporte],
                                percent: reporte.percent,
                            });
                            return consolidadoList;
                        }
                        reporteConsolidado.venta += reporte.venta;
                        reporteConsolidado.comision += reporte.comision;
                        reporteConsolidado.premio += reporte.premio;
                        reporteConsolidado.total += reporte.total;
                        reporteConsolidado.banqueo[0] += reporte.banqueo[0];
                        reporteConsolidado.banqueo[1] += reporte.banqueo[1];
                        reporteConsolidado.percent = reporteConsolidado.premio / (reporteConsolidado.venta ? reporteConsolidado.venta : 1);
                        reporteConsolidado.sorteos.push(reporte);

                        return consolidadoList;
                    }, []);
                    this.sumary = reportList.reduce((sumary, item) => {
                        sumary.venta += item.venta; 
                        sumary.comision += item.comision; 
                        sumary.premio += item.premio; 
                        sumary.total += item.total; 
                        return sumary;
                    }, {venta: 0, comision: 0, premio: 0, total: 0});
                }).catch(() => {

                }).finally(() => {
                    this.loading = false;
                })
        },
        open: function (reporte, context = 'tab') {
            const comercio = [];
            if (this.user.comercio_type !== 'operadora') comercio[this.getComType(reporte.comercio_type)] = reporte.comercio_id;
            const route = this.$router.resolve({
                name: ({
                    'comercio': 'reporte.general',
                    'sistema': 'operadora.sistema.reporte.general',
                })[this.context],
                query: {
                    ... this.params,
                    juego_id: reporte.juego_id,
                    juego_sorteo_id: reporte.juego_sorteo_id,
                    ... context == 'window' ? { 'minimal': 1, 'no-layout': 1 }: {},
                }
            });
            if (context == 'tab') return window.open(new URL(route.href, window.location.origin), '_blank').focus();
            if (context == 'window') return window.open(new URL(route.href, window.location.origin), '_blank', 'titlebar=no, status=no, menubar=no, height=600 width=1000');
        },
        getComType: function (comercioType) {
            return {'master': 'master_id', 'comercializadora': 'comercializadora_id', 'grupo': 'grupo_id', 'taquilla': 'taquilla_id'}[comercioType];
        },
        exportToExcel () {
            if (this.list.length == 0) return;
            const workbook = new excelJS.Workbook();
            workbook.creator = 'Source 2';
            workbook.lastModifiedBy = 'System';
            workbook.created = new Date();
            let sheet = workbook.addWorksheet("Reporte");

            sheet.getRow(1).values = [
                'Juego', 
                'Ventas '+ this.divisa.code, 
                'Comision '+ this.divisa.code, 
                'Premios '+ this.divisa.code, 
                'Total '+ this.divisa.code, 
                'BanqueoA',
                'BanqueoB',
            ];
            
            const results = this.list.reduce((sumary, item) => { 
                sumary.ventas += parseFloat(item.venta.toFixed(2)); 
                sumary.comisiones += parseFloat(item.comision.toFixed(2)); 
                sumary.premios += parseFloat(item.premio.toFixed(2)); 
                sumary.totales += parseFloat(item.total.toFixed(2)); 
                sumary.banqueoa += parseFloat(item.banqueo[0].toFixed(2)); 
                sumary.banqueob += parseFloat(item.banqueo[1].toFixed(2)); 
                return sumary;
            }, {ventas: 0, comisiones: 0, premios: 0, totales: 0, banqueoa: 0, banqueob: 0});

            let records = [];
            this.list.forEach(reporte => {
                records.push([
                    reporte.juego,
                    parseFloat(reporte.venta.toFixed(2)),
                    parseFloat(reporte.comision.toFixed(2)),
                    parseFloat(reporte.premio.toFixed(2)), 
                    parseFloat(reporte.total.toFixed(2)), 
                    parseFloat(reporte.banqueo[0].toFixed(2)),
                    parseFloat(reporte.banqueo[1].toFixed(2))  
                ]);
            });
            sheet.addRows(records);
            const rmax = this.list.length + 2;
            sheet.getCell('B' + rmax).value = { formula: `SUM(B3:B${rmax})`, result: results.ventas };
            sheet.getCell('C' + rmax).value = { formula: `SUM(C3:C${rmax})`, result: results.comisiones };
            sheet.getCell('D' + rmax).value = { formula: `SUM(D3:D${rmax})`, result: results.premios };
            sheet.getCell('E' + rmax).value = { formula: `SUM(E3:E${rmax})`, result: results.totales };
            sheet.getCell('F' + rmax).value = { formula: `SUM(F3:F${rmax})`, result: results.banqueoa };
            sheet.getCell('G' + rmax).value = { formula: `SUM(G3:G${rmax})`, result: results.banqueob };
            
            sheet.getColumn(1).alignment = {
                vertical: "middle",
                horizontal: "center",
            };
            sheet.getRow(1).font  = {
                bold: true
            };

            sheet.columns.forEach(column => {
                const lengths = column.values.map(v => v.toString().length);
                const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
                column.width = maxLength + 2;
            });

            const tittle = 'Source 2 Reporte General de Ventas desde ' 
                + this.params.daterange[0].split("-").reverse().join("-")
                + ' hasta '
                + this.params.daterange[1].split("-").reverse().join("-");

            sheet.insertRow(1, [tittle]);
            sheet.mergeCells("A1:G1");

            sheet.getCell('A1').fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb:'c9daf8'}
            };

            sheet.addConditionalFormatting({
                ref: 'A2:G' + rmax,
                rules: [
                    {
                    type: 'expression',
                    formulae: ['MOD(ROW(),2)=0'],
                    style: {fill: {type: 'pattern', pattern: 'solid', bgColor: {argb: 'cccccc'}}},
                    }
                ]
            });

            const filename = 'Reporte General ' + this.$luxon.now().toFormat('MM-dd HHmmss') + '.xlsx';
            workbook.xlsx.writeBuffer().then(function(buffer) {
                const blob = new Blob([buffer], { type: "applicationi/xlsx" });
                saveAs(blob, filename);
            });
        }
    },
    watch: { 
        'params': function () {
            this.getList();
        },
        'divisa': function () {
            //this.getList();
        }
    }
}
</script>
<style>

</style>