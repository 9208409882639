<template>
    <v-card elevation="2">
        <v-card-title>
            {{ juego.name }}
        </v-card-title>
        <v-card-text>
            <div class="mb-2">
                <v-chip 
                    v-for="(juegoSorteo, index) of juego.juego_sorteo_list"
                    :key="`juego-${juego.id}-sorteo-${index}-${juegoSorteo.id}-chip`"
                    :color="juegoSorteo.is_active ? 'primary' : 'secondary'"
                    label
                    small
                    class="mr-1 mb-1"
                    >
                    {{ juegoSorteo.horario }}
                </v-chip>
            </div>
            <v-card>
                <v-card
                    v-for="(cupoJuego, index) of cupoJuegoList"
                    :key="`juego-${juego.id}-cupo-juego-${index}-${cupoJuego.id}-card`"
                    elevation="0"
                    :color="cupoJuego.is_active ? 'grey darken-4': ''"
                    tile
                    class="mb-1"
                    >
                    <v-card-text>
                        <div class="d-flex">
                            {{ cupoJuego.divisa.name }}
                            <v-spacer/>
                            {{ cupoJuego.is_active ? 'ACTIVO' : 'INACTIVO' }}
                        </div>
                        <v-container fluid class="subtitle-2 pa-0">
                            <v-row>
                                <v-col sm="2">
                                    Cupo · {{ cupoJuego.cupo_jugada | currency(cupoJuego.divisa.code) }}
                                </v-col>
                                <v-col sm="3">
                                    Min. Apuesta · {{ cupoJuego.min_apuesta | currency(cupoJuego.divisa.code) }}
                                </v-col>
                                <v-col sm="3">
                                    Max. Apuesta · {{ cupoJuego.max_apuesta | currency(cupoJuego.divisa.code) }}
                                </v-col>
                                <v-col sm="2" class="text-right">
                                    Comision · {{ cupoJuego.comision | percent }}
                                </v-col>
                                <v-col sm="2" class="text-right">
                                    Banqueo · {{ cupoJuego.banqueo | percent }}
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-card>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    props: {
        juego: {},
    },
    computed: {
        cupoJuegoList: function () {
            return this.juego.cupo_juego_list.filter(cupoJuego => cupoJuego.is_active);
        }
    }
}
</script>