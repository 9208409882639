import store from '@stores';

import config from './config';

const axios = require('axios');
axios.defaults.baseURL = config.get('API_URL');
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

axios.interceptors.request.use(function (config) {
  if (store.getters['persist/auth/token']) config.headers['Authorization'] = `Bearer ${store.getters['persist/auth/token']}`;
  return config;
}, function (error) {
  return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
  //console.log(response.data)
  if(response.config.raw) return response;
  if(response.config.full) return response.data;
  return response.data.data;
}, function (error) {
  if (error?.response?.data) {
    const response = error.response.data;
    switch (response.code) {
      case "UNAUTHENTICATED": store.dispatch('persist/auth/logout'); break;
    }
  }
  return Promise.reject(error);
});

export default axios;