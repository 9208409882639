<template>
<v-card :loading="loading" elevation="5">
    <v-fade-transition mode="out-in">
        <div v-if="loading" key="loading">
            <v-card-text style="min-height: 100px">
            <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
                />
            </v-card-text>
        </div>
        <div v-else key="loaded">
            <div>
                <v-card-title>
                    <div class="d-flex justify-space-between align-center flex-grow-1">
                        <div>
                            <h3 class="mr-2">{{ master.name }}
                                <v-chip :color="{'true': 'success', 'false': 'error'}[master.is_active]">
                                    {{ master.is_active ? 'Activo' : 'Inactivo' }}
                                </v-chip>
                            </h3>
                            
                            <div class="d-flex align-center">
                            <p class="text-caption ma-0 mr-2">{{ master.slug }}</p>
                            </div>
                        </div>
                        <div>
                            <slot name="actions"></slot>
                        </div>
                    </div>
                </v-card-title>
                <v-card-text v-if="master.description">
                    <p v-if="master.description" class="ma-0">{{ master.description }}</p>
                </v-card-text>
            </div>
            <v-card-actions class="pa-0"><slot/></v-card-actions>
        </div>
    </v-fade-transition>
    <!-- <div class="primary">
        <v-card-title v-if="!loading && master" class="white--text">
            <div class="text-h5">
                <div>{{ master.name }}</div>
                <small>{{ master.slug }}</small>
            </div>
            <slot name="title"></slot>
        </v-card-title>
        <v-card-title v-if="loading" class="white--text">
            <div class="text-h4">Loading...</div>
        </v-card-title>
    </div>
    <v-card-text class="pa-0">
        <v-list two-line v-if="!loading && master">
            <v-list-item v-if="master && master.description">
                <v-list-item-icon>
                    <v-icon color="primary">mdi-email</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Descripción</v-list-item-title>
                    <v-list-item-subtitle>{{ master.description }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-divider inset v-if="master && master.email && master.email.length"></v-divider>
            <v-list-item v-for="(email, index) in master.email" :key="`master-card-item-email-${index}`">
                <v-list-item-icon>
                    <v-icon color="primary">mdi-email</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{email}}</v-list-item-title>
                    <v-list-item-subtitle>Correo Electrónico {{index + 1}}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
    
            <v-divider inset v-if="master && master.phone && master.phone.length"></v-divider>
            <v-list-item v-for="(phone, index) of master.phone" :key="`master-card-item-phone-${index}`">
                <v-list-item-icon>
                    <v-icon color="primary">mdi-phone</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{phone}}</v-list-item-title>
                    <v-list-item-subtitle>Telefono {{index + 1}}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-divider inset v-if="master && master.user"></v-divider>
            <v-list-item v-if="master && master.user">
                <v-list-item-icon>
                    <v-icon :color="master.user.is_active ? 'primary' : 'secondary'">mdi-account-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{master.user.username}}</v-list-item-title>
                    <v-list-item-subtitle>
                        {{master.user.is_active ? 'Usuario Activo' : 'Usuario Inactivo'}}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <slot name="content"></slot>
    </v-card-text>
    <v-card-actions class="primary" v-if="master">
        <slot name="actions"></slot>
    </v-card-actions> -->
</v-card>
</template>
  
  <script>
  export default {
    props: ['master', 'loading'],
  }
  </script>
  
  <style>
  
  </style>