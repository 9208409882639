import CrudService from "@utils/CrudService";

import axios from "@/plugins/axios";

class DivisaService extends CrudService {
    getLastRate(params = {}, options = {}) {
        return axios({
            url: 'operadora/general/divisa/get-last-rate',
            params,
            ...options,
        });
    }
    setRate(data = {}, options = {}) {
        return axios({
            url: 'operadora/general/divisa/set-rate',
            data,
            method: 'POST',
            ...options,
        });
    }
    getListForCupoSistema(sistema_id, params = {}, options = {}) {
        return axios({
            url: `operadora/general/divisa/get-list-for-cupo-sistema/${sistema_id}`,
            method: 'GET',
            params,
            ... options,
        })
    } 
}

export const BaseDivisaService = DivisaService

export default new DivisaService({
    domainName:'operadora/general',
    entityName:'divisa',
    fields: ['name', 'shortname', 'symbol', 'local_rate', 'is_default', 'flag_icon', 'is_active'],
    query:['search'],
});