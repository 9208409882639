import auth from './auth';
import divisa from './divisa';
import operadora from './comercio/operadora';
import defaultStore from './default';

export default {
    namespaced: true,
    modules: {
        auth,
        divisa,
        operadora,
        default: defaultStore,
    },
    actions: {
        clear: function () {
            window.localStorage.clear();
        }
    }
};