<template>
    <v-dialog v-model="open" width="500" persistent>
        <v-card>
            <v-card-title>{{message}}</v-card-title>
            <v-card-actions>
                <v-spacer/>
                <v-btn text @click="cancel()">Cancelar</v-btn>
                <v-btn color="primary" @click="confirm()">Aceptar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    props: {
        message: {
            type: String,
            default: "Confirma"
        },
        textConfirm: {
            type: String,
            default: "Aceptar"
        },
        textCancel: {
            text: String,
            default: "Cancelar"
        }
    },
    data: function () {
        return {
            open: true,
        };
    },
    methods: {
        cancel: function () {
            this.$emit('cancel');
            this.open = false;
        },
        confirm: function () {
            this.$emit('confirm');
            this.open = false;
        },
    }
}
</script>