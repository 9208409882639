<template>
<div>
    <template v-if="divisa">
        <v-menu
            v-model="menu"
            :close-on-content-click="true"
            :nudge-width="75"
            >
            <template v-slot:activator="{ on, attrs }">
                <v-card flat v-bind="attrs" v-on="on">
                    <v-card-text class="px-5 py-0">
                        <small class="overline">Divisa</small> ・ <small class="caption font-weight-bold">{{ divisa.shortname }} ({{ divisa.symbol }})</small>
                    </v-card-text>
                </v-card>
            </template>
            <v-card :loading="isDivisaListLoading">
                <v-card-title class="text-h6 py-2 pl-4">Divisas</v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pa-2">
                    <v-list three-line v-if="!isDivisaListLoading">
                        <v-list-item-group v-model="selected">
                            <v-list-item v-for="(divisaItem) in divisaList" :key="`${divisaItem.slug}-selector-item`" style="min-height: 72px;" :value="divisaItem.id">
                                <v-list-item-avatar>{{ divisaItem.symbol }}</v-list-item-avatar>
                                <v-list-item-content class="align-start">
                                    <v-list-item-title>{{ divisaItem.shortname }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ divisaItem.name }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                    <v-skeleton-loader v-if="isDivisaListLoading" type="list-item-avatar-three-line@2"/>
                </v-card-text>
            </v-card>
        </v-menu>
    </template>
    <v-card flat v-if="isDivisaLoading" loading>
        <v-card-text class="px-5 py-0">
            <small class="overline">Cargando Divisa</small>
        </v-card-text>
    </v-card>
</div>
</template>
<style>
.v-list-item--link:before { border-radius: 5px; }
</style>
<script>
import DivisaService from '@/services/general/DivisaService';

export default {
    data: function () {
        return {
            selected: null,
            menu: false,
            divisaList: [],
            isDivisaListLoading: true,
        };
    },
    created: function () {
        this.getDivisaList();
    },
    computed: {
        divisa: function () {
            return this.$store.getters['persist/divisa/selected'];
        },
        isDivisaLoading: function () {
            return this.$store.getters['persist/divisa/isLoading'];
        },
    },
    methods: {
        getDivisaList: function () {
            this.isDivisaListLoading = true;
            DivisaService
                .getList()
                .then(({ divisaList }) => {
                    this.divisaList = divisaList;
                })
                .finally(() => this.isDivisaListLoading = false);
        }
    },
    watch: {
        divisa: function (divisa) {
            this.selected = divisa.id;
        },
        selected: function (selected) {
            if (selected && this.divisa.id == selected) return;
            this.$store.dispatch('persist/divisa/setSelected', this.divisaList.find(divisa => divisa.id == selected));
        }
    },
}
</script>