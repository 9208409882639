<template>
    <div class="d-flex">
        Seleccione un juego
    </div>
</template>

<script>
export default {
    
}
</script>
<style>

</style>