<template>
    <v-form ref="form" v-model="valid" :disabled="loading">
        <v-row dense>
            <v-col cols="12">
                <v-card class="mb-2" outlined>
                    <v-card-title>{{ divisa.name }}</v-card-title>
                </v-card>
            </v-col>
            <v-col cols="12">
                <v-card :loading="isLoadingCupoParent" outlined>                
                    <v-card-title>Cupo {{ownerContext}}</v-card-title>
                    <v-card-text>
                        <ul v-if="cupoParent">
                            <li>Máxima Comisión: {{cupoParent.comision | percent}}</li>
                            <li>Banqueo Máximo: {{cupoParent.banqueo | percent}}</li>
                        </ul>
                        <v-container fluid>
                            <v-row>
                                <v-col cols="12" md="4">
                                    <v-text-field
                                        label="Cupo Jugada"
                                        v-model="fields.cupo_jugada"
                                        :rules="rules.cupo_jugada"
                                        :hint="hints.cupo_jugada"
                                        :disabled="disabled"
                                        hide-details="auto"
                                        persistent-hint
                                        filled
                                        min="0"
                                        type="number"
                                        @change="changed('cupo_jugada')"
                                        />
                                </v-col>
                                <v-col cols="12" md="4">
                                    <PercentInput 
                                        label="Comisión"
                                        v-model="fields.comision"
                                        :rules="rules.comision"
                                        :hint="hints.comision"
                                        :disabled="disabled"
                                        @change="changed('comision')"
                                        />
                                </v-col>
                                <v-col cols="12" md="4">
                                    <PercentInput 
                                        label="Banqueo"
                                        v-model="fields.banqueo"
                                        :rules="rules.banqueo"
                                        :hint="hints.banqueo"
                                        :disabled="disabled"
                                        @change="changed('banqueo')"
                                        />
                                </v-col>
                                <template v-if="hasLimit">
                                    <v-col cols="12" md="6">
                                        <v-text-field
                                            label="Mínimo Apuesta"
                                            v-model="fields.min_apuesta"
                                            :rules="rules.min_apuesta"
                                            :hint="hints.min_apuesta"
                                            :disabled="disabled"
                                            hide-details="auto"
                                            persistent-hint
                                            filled
                                            min="0"
                                            type="number"
                                            @change="changed('min_apuesta')"
                                            />
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field
                                            label="Máximo Apuesta"
                                            v-model="fields.max_apuesta"
                                            :rules="rules.max_apuesta"
                                            :hint="hints.max_apuesta"
                                            :disabled="disabled"
                                            hide-details="auto"
                                            persistent-hint
                                            filled
                                            min="0"
                                            type="number"
                                            @change="changed('max_apuesta')"
                                            />
                                    </v-col>
                                </template>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-form>
</template>
<script>
import Form from '@/mixins/form';

import CupoService from '@/services/comercio/CupoService';

export default {
    mixins: [Form],
    data: function () {
        return {
            cupoParent: null,
            baseLimit: null,
            isLoadingCupoParent: false,
            isLoadingDivisa: false,
        };
    },
    props: {
        ownerContext: {
            type: String,
            validator: function (value) {
                return ['juego', 'master', 'comercializadora', 'grupo', 'taquilla'].includes(value);
            },
            required: true,
        },
        owner: {
            type: Object,
            required: true,
        },
        hasLimit: {
            type: Boolean,
            default: false,
        },
        divisa: {
            type: Object,
        }
    },
    mounted: function () {
        this.getCupoParent();
    },
    computed: {
        defaultFields: function () {
            return {
                cupo_jugada: 0,
                min_apuesta:
                this.ownerContext == 'juego' ? this.divisa.min_apuesta_default : 0,
                max_apuesta: this.ownerContext == 'juego' ? this.divisa.max_apuesta_default : 0,
                comision: 0,
                banqueo: 0,
            }
        },
        rules: function () {
            return {
                cupo_jugada: [
                    v => !(v < 0) || "El cupo de jugada no debe ser menor a Cero(0)",
                    v => !(v < 1 && this.ownerContext == 'juego') || "El cupo de jugada debe ser mayor a Cero(0)",
                    ...(
                        this.cupoParent && this.cupoParent.cupo_jugada ?
                            [v => !(v > this.cupoParent.cupo_jugada) || `Solicite a su COMERCIO_PADRE para aumentar el limite de juego`]:
                            []
                    ),
                ],
                comision: [
                    v => !(v < 0) || "El % de comisión no debe ser menor a Cero(0)",
                    v => !(v < 1 && this.ownerContext == 'juego') || "El % de comisión debe ser mayor a Cero(0)",
                    v => !(v > 100) || "El % de comisión no debe ser mayor a Cien(100)",
                    ...(
                        this.cupoParent ?
                            [v => !(v > this.cupoParent.comision * 100) || `El % de comisión no debe ser mayor a ${this.cupoParent.comision*100}`]:
                            []
                    ),
                ],
                banqueo: [
                    v => !(v < 0) || "El % de banqueo no debe ser menor a Cero(0)",
                    v => !(v > 100) || "El % de banqueo no debe ser mayor a Cien(100)",
                    ...(this.cupoParent ? [
                            v => !(v > 0 && this.cupoParent.banqueo == 0) || "Contacte a su administrador para asignar % de banqueo",
                            v => !(v > this.cupoParent.banqueo * 100) || `El % de banqueo no debe ser mayor a ${this.cupoParent.banqueo*100}`,
                        ]: []),
                ],
                ... (this.hasLimit ? {
                    min_apuesta: [
                        v => !!v || 'Debes indicar el mínimo de apuesta',
                        v => (v > 0) || "El mínimo de apuesta no debe ser menor a Cero(0)",
                        v => (v <= parseFloat(this.fields.max_apuesta)) || `El mínimo de apuesta no debe ser superior al máximo ${this.fields.max_apuesta}`,
                        ...(
                            this.baseLimit?.min_apuesta ?
                                [
                                    v => (v >= this.baseLimit.min_apuesta) || `El mínimo de apuesta no debe ser menor a ${this.baseLimit.min_apuesta}`,
                                ]:
                                []
                        )
                    ],
                    max_apuesta: [
                        v => !!v || 'Debes indicar el máximo de apuesta',
                        v => (v > 0) || "El máximo de apuesta no debe ser menor a Cero(0)",
                        v => (v >= parseFloat(this.fields.min_apuesta)) || `El máximo de apuesta no debe ser inferior al mínimo de apuesta`,
                        ...(
                            this.baseLimit?.max_apuesta ?
                                [
                                    v => (v <= this.baseLimit.max_apuesta) || `El máximo de apuesta no debe ser mayor a ${this.baseLimit.max_apuesta}`,
                                    v => (v >= this.baseLimit.min_apuesta) || `El máximo de apuesta no debe ser inferior a ${this.baseLimit.min_apuesta}`,
                                ]:
                                []
                        )
                    ],
                }: {}),
            };
        },
        user: function () {
            return this.$store.getters['persist/auth/user'];
        },
        hints: function () {
            return {
                cupo_jugada: !parseFloat(this.fields.cupo_jugada) ? 'Sin cupo de jugada' : '',
                min_apuesta: null,
                max_apuesta: null,
                comision: !parseFloat(this.fields.comision) ? 'Sin comisión' : '',
                banqueo: !parseFloat(this.fields.banqueo) ? 'Sin banqueo' : '',
            }
        },  
        disabled: function () {
            if (!this.cupoParent && this.ownerContext != 'juego') return true;
            return false;
        },
    },
    methods: {
        getCupoParent: function () {
            this.isLoadingCupoParent = true;
            CupoService
                .getParentBy(this.ownerContext, {... this.owner, divisa_id: this.divisa.id })
                .then(({ cupo, limit }) => {
                        this.cupoParent = cupo;
                        this.baseLimit = limit;
                        if (this.context == 'new') {
                            this.fields.min_apuesta = limit.min_apuesta;
                            this.fields.max_apuesta = limit.max_apuesta;
                        }
                        this.$nextTick(() => {
                            this.$refs['form'].resetValidation();
                        });
                    })
                    .finally(() => {
                        this.$refs.form.validate(); 
                        this.fields.divisa_id = null;
                        this.isLoadingCupoParent = false;
                    })
        }
    },
    watch: {
        'divisa': function (divisa) {
            if (this.divisa) this.getCupoParent();
            if (!this.hasLimit) return;
            if (this.fields.min_apuesta === null) this.fields.min_apuesta = divisa.min_apuesta_default;
            if (this.fields.max_apuesta === null) this.fields.max_apuesta = divisa.max_apuesta_default;
        },
    }
}
</script>