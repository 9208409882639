<template>
    <div class="d-flex">
        <v-card elevation="2" class="mr-2" :loading="loading">
            <v-card-title>Ventas</v-card-title>
            <v-card-text :loading="loading">{{ sumary.venta ? sumary.venta : 0 | currency }}</v-card-text>
        </v-card>
        <v-card elevation="2" class="mr-2" :loading="loading">
            <v-card-title>Comisión</v-card-title>
            <v-card-text :loading="loading">{{ sumary.comision ? sumary.comision : 0 | currency }}</v-card-text>
        </v-card>
        <v-card elevation="2" class="mr-2" :loading="loading">
            <v-card-title>
                Premios
                <v-chip small label :color="porcentaje > 0.80 ? 'error': 'primary'" class="font-weight-bold ml-1">
                    {{ porcentaje ? porcentaje : 0 | percent }}
                </v-chip>
            </v-card-title>
            <v-card-text :loading="loading">{{ sumary.premio ? sumary.premio : 0 | currency }}</v-card-text>
        </v-card>
        <v-card elevation="2" class="mr-2" :loading="loading">
            <v-card-title>Total</v-card-title>
            <v-card-text :loading="loading">{{ sumary.total ? sumary.total : 0 | currency }}</v-card-text>
        </v-card>
    </div>
</template>

<script>

export default {
    components: {

    },
    props: {
        params: {
            defaults: function() {
                return {};
            },
        },
        summary: {
            required: true,
            default: () => {},
        },
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data: function() { 
        return {
            list: [],
        }
    },
    computed: {
        sumary: function (){
            return this.summary;
        },
        porcentaje: function (){
            if (this.sumary.premio === 0) return 0;
            return this.sumary.premio / this.sumary.venta;
        },
    },
    mounted: function () {

    },
    methods: {

    },
    watch: { 
        
    },
}
</script>
<style>

</style>