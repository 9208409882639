<template>
    <v-snackbar v-model="snackbar" shaped :color="color" right dark timeout="5000">
        {{ message }}
        <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="close()">Cerrar</v-btn>
        </template>
    </v-snackbar>
</template>
<script>
export default {
    props: {
        message: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            default: "primary"
        },
    },
    data: function () {
        return {
            snackbar: true,
        };
    },
    methods: {
        close: function () {
            this.snackbar = false;
        },
    },
    watch: {
        snackbar: function (open) {
            if (!open) this.$emit('closed'); 
        }
    }
}
</script>