<template>
<div>
<v-card 
    v-for="(juego, index) in list" 
    :key="`juego-${juego.id}-${index}`"
    >
    <v-card-title>
        {{ juego.name }}
    </v-card-title>
    <!--v-card-text>
        asd
    </v-card-text-->
    <v-card-actions>
    <v-expansion-panels flat>
        <v-expansion-panel>
            <v-expansion-panel-header>Divisas</v-expansion-panel-header>
            <v-expansion-panel-content>
                <CupoCrud context="taquilla" :divisaList="juego.availableDivisaList" :owner="{
                    taquilla_id: taquilla.id,
                    juego_id: juego.id,
                }" has-limit/>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
    </v-card-actions>
</v-card>
</div>
</template>
<script>
import JuegoService from '@services/loteria/JuegoService';

import CupoCrud from '@components/entities/comercio/cupo/crud.vue';

export default {
    components: {
        CupoCrud,
    },
    props: ['taquilla'],
    data: function () {
        return {
            options: {},
            loading: false,
            list: [],
            count: 0,

            isModalOpen: false,
            item: null,

            params: {
                'limit': 100,
                'offset': 0,
            }
        };
    },
    mounted: function () {
        this.getList();
    },
    methods: {
        getList: function (params = {}) {
            this.loading = true;
            JuegoService
                .getForCupoTaquilla(this.taquilla.id, { ...this.params, ...params})
                .then(({juegoList, juegoCount}) => {
                    this.list = juegoList;
                    this.count = juegoCount;
                }).finally(() => {
                    this.loading = false;
                });
        },
        add: function () {
            this.item = null;
            this.isModalOpen = true;
        },
        edit: function (item) {
            this.item = item;
            this.isModalOpen = true;
        }
    },
    watch: {
    },
}
</script>