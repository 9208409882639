<template>
    <v-form ref="form" class="d-flex align-center" style="width: 100%" v-model="valid">
        <div class="mr-3">
            <v-text-field
                v-model="fields.search"
                @change="changed('search')"
                append-icon="mdi-magnify"
                filled
                label="Buscar"
                hide-details
                clearable
            ></v-text-field>
        </div>
        <div class="mr-3">
            <v-select
                :items="domainList"
                item-text="name"
                item-value="id"
                filled
                hide-details
                label="Tipo de Usuario"
                v-model="fields.domain_id"
                :loading="isLoadingDomainList"
                :disabled="loading || isLoadingDomainList"
                @change="changed('domain_id')"
                />
        </div>
        <div class="mr-3">
            <v-select
                :items="isActiveList"
                filled
                hide-details
                label="Estado"
                v-model="fields.is_active"
                :disabled="loading"
                @change="changed('is_active')"
                />
        </div>
        <div class="d-flex">
            <v-btn text class="mr-3" :disabled="loading" @click="reset()">Reiniciar Filtros</v-btn>
            <v-btn color="primary" :disabled="loading" :loading="loading" @click="search()"><v-icon>mdi-magnify</v-icon>Buscar</v-btn>
        </div>
    </v-form>
</template>

<script>
import Search from '@mixins/search';

import UserService from '@services/comercio/UserService';

export default {
    mixins: [Search],
    data: function ()  {
        return {
            isActiveList: [
                {
                    text: 'Todos',
                    value: null,
                },
                {
                    text: 'Activos',
                    value: true,
                },
                {
                    text: 'Inactivos',
                    value: false,
                }
            ],

            defaultValues: {
                search: null,
                is_active: null,
            },

            domainList: [],
            isLoadingDomainList: false,
        }
    },
    created: function () {
        this.getUserDomainList();
    },
    methods: {
        getUserDomainList: function () {
            this.isLoadingDomainList = true;

            this.domainList = [];

            UserService
                .getDomainList()
                .then(({ domainList }) => { this.domainList = domainList; })
                .finally(() => this.isLoadingDomainList = false);
        },
    }
}
</script>