<template>
    <v-card>
        <v-card-title>Juego: {{ reporte.juego}}  -  {{ reporte.horario}}</v-card-title>
        <v-card-text>
            <v-chip label class="mr-2">Ventas: {{ reporte.venta | currency }}</v-chip>
            <v-chip label class="mr-2">Comisión: {{ reporte.comision | currency}}</v-chip>
            <v-chip label class="mr-2">Premios: {{ reporte.premio | currency }}</v-chip>
            <v-chip label :color="reporte.percent > 100 ? 'error': 'primary'">
                {{ reporte.total | currency(reporte.divisa_code) }} {{' / '}} {{ reporte.percent | percent }}
            </v-chip>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    props: ['reporte'],
}
</script>