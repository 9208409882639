import BaseService from "@utils/BaseService";
import axios from '@plugins/axios';

class BloqueoFiguraService extends BaseService {
    getList(filters = {}, options = {}) {
        return axios({
            url: this.getRoute(),
            method: 'GET',
            params: filters,
            ...options
        });
    }
    create(data, options = {}) {
        return axios({
            url: this.getRoute(),
            data: this.getDataEncoded(data),
            method: 'POST',
            ...options
        });
    }
    update(id, data, options = {}) {
        return axios({
            url: `${this.getRoute()}${id}`,
            params: data,
            method: 'PUT',
            ...options
        });
    }
}

export const BaseBloqueoFiguraService = BloqueoFiguraService

export default new BloqueoFiguraService({
    domainName: 'operadora/loteria',
    entityName: 'bloqueo-figura',
    fields: ['juego_id', 'juego_sorteo_id', 'numero', 'divisa_id', 'is_active', 'fecha'],
});