<template>
    <v-dialog v-model="dialog" width="600">
        <v-card shaped>
            <v-card-title class="primary white--text">
                <h3>Grupo</h3>
            </v-card-title>
            <v-card-text class="pa-3">
                <v-container fluid>
                    <GrupoForm :loading="loading" v-model="grupoData" :default="defaultGrupoData" :valid.sync="isGrupoDataValid"/>
                    <v-checkbox v-model="hasUser" label="¿posee usuario?" :disabled="!!(grupo && grupo.user)"></v-checkbox>
                    <v-card outlined tile v-if="hasUser">
                        <v-card-title>Usuario</v-card-title>
                        <v-card-text>
                            <UserForm :loading="loading" v-model="userData"  :default="defaultUserData" :valid.sync="isUserDataValid"/>
                        </v-card-text>
                    </v-card>
                </v-container>
            </v-card-text>
            <v-divider/>
            <v-card-actions>
                <v-spacer/>
                <v-btn color="primary" text :disabled="loading" @click="reset()">RESETEAR</v-btn>
                <v-btn color="primary" :disabled="!isValid || loading" :loading="loading" @click="submit()">
                    <v-icon>mdi-content-save</v-icon>
                    <span v-if="this.grupo">Editar grupo</span>
                    <span v-else>Añadir grupo</span>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import GrupoForm from "./form"
import UserForm from '@components/entities/general/user/form';

import GrupoService from '@services/comercio/GrupoService';

export default {
    components: {
        GrupoForm,
        UserForm
    },
    props: ['grupo', 'open'],
    data: () => ({
        loading: false,
        dialog: false,

        grupoData: {},
        defaultGrupoData: null,
        isGrupoDataValid: false,

        userData: {},
        defaultUserData: null,
        isUserDataValid: false,

        hasUser: false,
    }),
    mounted: function () {
        this.reset();
    },
    computed: {
        isValid: function () {
            let isGrupoDataValid = false;
            let isUserDataValid = false;
            if (this.isGrupoDataValid && Object.keys(this.grupoData).length) isGrupoDataValid = true;
            if (this.hasUser) {
                if (this.isUserDataValid && Object.keys(this.userData).length) isUserDataValid = true;
            } else {
                isUserDataValid = isGrupoDataValid;
            }
            return this.grupo ? isGrupoDataValid || isUserDataValid : isGrupoDataValid && isUserDataValid;
        }
    },
    methods: {
        close: function () {
            this.$emit('update:open', false);
        },
        submit: function () {
            this.loading = true;
            let action = null;
            const data = {};

            if (this.isGrupoDataValid && Object.keys(this.grupoData).length) data['grupo'] = this.grupoData;
            if (this.hasUser && this.isUserDataValid && Object.keys(this.userData).length) data['user'] = this.userData;

            if (this.grupo) action = GrupoService.update(this.grupo.id, data);
            else action = GrupoService.create(data);
            action
                .then(({ grupo }) => {
                    this.$ui.notificate({message: 'Grupo guardado'});
                    this.emit(grupo);
                    this.close();
                })
                .catch(() => this.$ui.notificate({message: 'Error guardando grupo', color: 'error'}))
                .finally(() => this.loading = false);
        },
        emit: function (data) {
            this.$emit('saved', data);
        },
        reset: function () {
            if (this.grupo) {
                this.defaultGrupoData = { ... this.grupo};
                if (this.grupo.user) {
                    this.hasUser = true;
                    this.defaultUserData = { ... this.grupo.user };
                } else {
                    this.hasUser = false;
                    this.defaultUserData = null;
                }
            } else {
                this.defaultGrupoData = null;
                this.defaultUserData = null;
            }
            this.grupoData = {};
            this.userData = {};
        }
    },
    watch: {
        open: function (open) {
            this.reset();
            this.dialog = open;
        },
        dialog: function (dialog) {
            if (!dialog) {
                this.$emit('update:open', false);
                this.reset();
            }
        },
    }
}
</script>
