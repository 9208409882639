<template>
<v-container fluid>
    <v-row>
        <v-col>
            <v-card class="mb-3">
                <v-card-title>Filtros</v-card-title>
                <v-card-text><ConsolidadoComercioSearch :default="params" @search="search($event)"></ConsolidadoComercioSearch></v-card-text>
            </v-card>
            <v-card>
                <v-card-text>
                    <!--ConsolidarComercio :params="params"/>
                    <br/-->
                    <ConsolidadoComercio :params="params"></ConsolidadoComercio>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</v-container>
</template>
<script>
    //import ConsolidarComercio from '@components/entities/reporte/venta/comercio/box';
    import ConsolidadoComercio from '@components/entities/reporte/venta/comercio';
    import ConsolidadoComercioSearch from '@components/entities/reporte/venta/comercio/search';

    export default {
        components: {
            //ConsolidarComercio,
            ConsolidadoComercio,
            ConsolidadoComercioSearch
        },
        props: {
        
        },
        data: function() {
            const query = {... this.$route.query};

            for (const key in query) {
                if (Object.hasOwnProperty.call(query, key)) {
                    const element = query[key];
                    console.log(key, query[key], this.$lodash.isNumber(element));
                    query[key] = /^-?[\d.]+(?:e-?\d+)?$/.test(element) ? Number.parseFloat(element): element;
                }
            }

            return {
                params: {
                    daterange: [this.$luxon.now().toISODate(),this.$luxon.now().toISODate()],
                    ... query,
                }
            }
        },
        created: function () {
            this.$store.dispatch('ui/layout/setTitle', 'Consolidada por Comercio');
        },
        computed:{
            
        },
        methods:{
            search: function (params) {
                this.params = params;
            }
        },
    }
</script>
<tyle>

</tyle>