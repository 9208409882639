<template>
    <v-app-bar app flat>
        <v-app-bar-title class="text-no-wrap"><span class="text-no-wrap">{{title}}</span></v-app-bar-title>
        <v-spacer/>
        <DateTime class="mr-2"/>
        <Divisa/>
    </v-app-bar>
</template>
<script>
import Divisa from '@components/layout/components/divisa';
import DateTime from '@components/layout/components/datetime';

export default {
    components: {
        Divisa,
        DateTime
    },
    computed: {
        title: function () {
            return this.$store.getters['ui/layout/getTitle'];
        },
    },
}
</script>