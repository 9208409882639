<template>
<div>
    <div v-for="(element, elementIndex) in items" :key="`${owner}-item-${elementIndex}`">
        <div v-if="!!element.items">
            <v-list-group v-model="element.active" :prepend-icon="element.icon" no-action :group="element.domain">
                <template v-slot:activator>
                    <v-list-item-content>
                        <v-list-item-title>{{element.title}}</v-list-item-title>
                    </v-list-item-content>
                </template>
                <v-list-item v-for="(child, childIndex) in element.items" :key="`${owner}-item-${elementIndex}-child-${childIndex}`" :to="child.to" exact>
                    <v-list-item-content>
                        <v-list-item-title v-text="child.title"></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-group>
        </div>
        <div v-else>
            <v-list-item-group active-class="primary white--text">
                <v-list-item :to="element.to" exact>
                    <v-list-item-icon>
                        <v-icon>{{element.icon}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{element.title}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-item-group>
        </div>
    </div>
</div>

</template>
<script>
export default {
    props: ['items', 'owner'],
    data: function () {
        return {
            list: [],
        };
    },
    methods: {
        setList: function () {
        }
    },
    computed: {
    },
    watch: {

    }
}
</script>