import CrudService from "@utils/CrudService";

class MasterService extends CrudService {}

export const BaseMasterService = MasterService

export default new MasterService({
    domainName:'comercio',
    entityName:'master',
    fields: {
        'master': ['name', 'description', 'phone', 'email', 'is_active'],
        'user': ['username', 'password', 'is_active']
    },
    query:['search', 'slug', 'is_active', 'offset', 'limit'],
});