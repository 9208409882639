import CrudService from "@utils/CrudService";
import axios from '@plugins/axios';

class TaquillaService extends CrudService {
    liberar(id, options = {}) {
        return axios({
            url: this.getRoute(`${id}/liberar`),
            method: 'POST',
            ...options
        });
    }
}

export const BaseTaquillaService = TaquillaService

export default new TaquillaService({
    domainName:'comercio',
    entityName:'taquilla',
    fields: {
        'taquilla': ['name', 'description', 'phone', 'email', 'grupo_id', 'is_active', 'username', 'password'],
    },
    query:['search', 'slug', 'is_active', 'offset', 'limit', 'grupo_id', 'master_id', 'comercializadora_id', 'grupo_id'],
});