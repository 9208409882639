import Profile from '@pages/profile';

export default [
    {
        path: '/profile',
        name: 'profile',
        component: Profile,
        meta: {
            requiresAuth: true
        }
    }
];
