<template>
<v-form ref="form" v-model="valid">
<v-row dense>
    <v-col cols="12">
        <v-select
            :items="juegoList"
            :loading="juegoListLoading"
            :disabled="juegoListLoading"
            item-text="name"
            item-value="id"
            label="Juego"
            @change="changed('juego_id', $event)"
            v-model="fields.juego_id"
            :rules="rules.juego_id"
            hide-details
            filled
            />
    </v-col>
    <v-col cols="12">
        <v-select
            :items="divisaList"
            :loading="divisaListLoading"
            :disabled="divisaListLoading || !fields.juego_id"
            item-text="name"
            item-value="id"
            label="Divisa"
            @change="changed('divisa_id', $event)"
            v-model="fields.divisa_id"
            :rules="rules.divisa_id"
            hide-details
            filled
            />
    </v-col>
</v-row>
</v-form>
</template>
<script>
import Form from '@mixins/form'; 

import JuegoService from '@/services/operadora/loteria/JuegoService';
import DivisaService from '@/services/operadora/general/DivisaService';

export default {
    mixins: [Form],
    props: {
        sistema: {
            required: true,
        },
    },
    data: function () {
        return {
            rules: {
                juego_id: [v => !!v || 'Selecciona un juego'],
                divisa_id: [v => !!v || 'Selecciona una divisa'],
            },
            defaultFields: {
                juego_id: null,
                divisa_id: null,
            },
            juegoList: [],
            juegoListLoading: false,
            divisaList: [],
            divisaListLoading: false,
        };
    },
    computed: {

    },
    methods: {
        getJuegoList: function () {
            this.juegoListLoading = true;
            JuegoService
                .getJuegoListForCupoSistema(this.sistema.id)
                .then(({ juegoList }) => {
                    this.juegoList = juegoList;
                })
                .finally(() => {
                    this.juegoListLoading = false;
                });
        },
        getDivisaList: function () {
            this.divisaListLoading = true;
            DivisaService
                .getListForCupoSistema(this.sistema.id, {
                    juego_id: this.fields.juego_id,
                })
                .then(({ divisaList }) => {
                    this.divisaList = divisaList;
                })
                .finally(() => {
                    this.divisaListLoading = false;
                });
        }
    },
    watch: {
        'fields.juego_id': function () {
            this.getDivisaList();
        },
    },
    mounted: function () {
        this.getJuegoList();
    }
}
</script>