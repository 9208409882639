export default {
    namespaced: true,
    state: () => ({
        confirm: null,
    }),
    getters: {
        get: state => state.confirm,
    },
    mutations: {
        set: (state, confirm) => {
            state.confirm = confirm;
        },
        remove: (state) => {
            state.confirm = null;
        }
    },
    actions: {
        set: ({ commit }, confirm) => {
            commit('set', {
                message: "Confirmar acción",
                textConfirm: "Confirmo",
                textCancel: "Cancelar",
                confirm: () => null,
                cancel: () => null,
                ...confirm
            });
        },
        remove: ({ commit }) => {
            commit('remove');
        },
    }
}