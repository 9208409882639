<template>
    <div>
        <v-text-field v-if="items.length==0"
            v-model="input"
            @change="changed($event)" 
            filled
            hide-details
            label="Número"
        />
        <v-autocomplete
            v-else
            label="Número/Figura"
            :items="items"
            :item-text="itemText"
            :loading=loading          
            :disabled=loading
            :auto-select-first=true
            clearable
            filled 
            @change="changed($event)"
            >
            <template v-slot:selection="data">
                {{ data.item.value }} {{ data.item.label }}
            </template>
            <template v-slot:item="data">
                {{ data.item.value }} {{ data.item.label }}
            </template>
        </v-autocomplete>
    </div>
</template>
<script>
export default {
    props: {
        'value': {
            default: null,
        }, 
        'loading': {
            type: Boolean,
            default: false,
        }, 
        'disabled': {
            type: Boolean,
            default: false,
        }, 
        'items': {
            type: Array,
            default: function () {
                return [];
            },
        }, 
        'label': { type: String, default: 'Ingrese Numero' },
    },
    data: function () {
        return {
            input: null,
        }
    },
    computed: {
        isNumero: function () {
            return !this.items.length;
        }
    },
    mounted: function () {
        this.input = this.value;
    },
    methods: {
        changed: function (value) {
            this.$emit('input', value);
            this.$emit('change', value);
        },
        itemText(item) {
            return `${item.value} ${item.label}`;
        }
    },
    watch: {
        value: function (value) {
            this.input = value;
        }
    }
}
</script>