import AuthService from '@services/AuthService'

import router from '../../router';

export default {
    namespaced: true,

    state: () => ({
        token: null,
        user: null,
        comercio: null,
    }),

    getters: {
        token: state => state.token,
        comercio: state => state.comercio,
        user: state => state.user,
        domain: state => state.user.domain.slug,
        intervalId: state => state.intervalId,
        isLogged: state => !!state.token,
    },

    mutations: {
        setUser: (state, { user }) => {
            state.user = user;
        },
        setComercio: (state, { comercio }) => {
            state.comercio = comercio;
        },
        setInterval: (state, intervalId) => {
            state.intervalId = intervalId;
        },
        login: (state, { token, user }) => {
            state.token = token;
            state.user = user;
        },
        logout: (state) => {
            state.token = null;
            state.user = null;
        }
    },

    actions: {
        user: ({ commit, getters, dispatch }) => {
            const token = getters['token'];
            return new Promise((resolve, reject) => {
                AuthService
                    .get(token)
                    .then(({user, comercio = null}) => {
                        commit('setUser', { user });
                        if (comercio) commit('setComercio', { comercio });
                        resolve(user);
                    })
                    .catch(error => {
                        if (error?.response?.data) {
                            const response = error.response.data;
                            switch (response.code) {
                                case "UNAUTHENTICATED": dispatch('logout', null); break;
                            }
                        }
                        return reject(error)
                    });
            });
        },
        login: ({ dispatch, commit }, credentials) => {
            return new Promise((resolve, reject) => {
                AuthService
                    .login(credentials)
                    .then(({token, user, comercio = null}) => {
                        commit('login', {
                            token,
                            user
                        });
                        if (comercio) commit('setComercio', { comercio });
                        dispatch('persist/divisa/setSelected', null, { root: true });
                        dispatch('persist/operadora/set', null, { root: true });
                        resolve(user);
                    })
                    .catch(error => {
                        if (error?.response?.data) {
                            const { code } = error.response.data;
                            switch (code) {
                                case 'USER_NOT_FOUND': return reject('USER_NOT_FOUND');
                            }
                        }
                        return reject('UNKNOWN');
                    });
            });
        },
        logout: ({ commit, dispatch }) => {
            return new Promise((resolve) => {
                AuthService
                    .logout()
                    .finally(() => {
                        commit('logout');
                        commit('setComercio', { comercio: null });
                        dispatch('persist/clear', null, { root: true });
                        router.replace({name: 'login'});
                        resolve();
                    });

            });
        },
        update: ({ state, commit }, data) => {
            return new Promise((resolve) => {
                AuthService
                    .update(data)
                    .then(({ user }) => {
                        commit('login', {
                            token: state.token,
                            user: user,
                        });
                        resolve(user);
                    });
            });
        }
    }
}