<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-title>
                        SISTEMAS
                        <v-spacer/>
                        <SistemaModal @saved="params = {}"/>
                    </v-card-title>
                    <v-card-text>
                        <SistemaList :params="params"/>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import SistemaList from '@components/entities/comercio/sistema/list';

import SistemaModal from "@components/entities/comercio/sistema/modal";

export default {
    components: {
        SistemaList,
        SistemaModal
    },
    data: function () {
        return {
            params: {},
        };
    }
}
</script>