<template>
    <v-form ref="form" style="width: 100%" v-model="valid">
        <div class="d-flex align-center flex-wrap">
            <div class="mr-3">
                <v-text-field
                    v-model="fields.search"
                    @change="changed('search')"
                    append-icon="mdi-magnify"
                    filled
                    label="Buscar"
                    hide-details
                    clearable
                ></v-text-field>
            </div>
            <div class="mr-3">
                <v-select
                    :items="isActiveList"
                    filled
                    hide-details
                    label="Estado"
                    v-model="fields.is_active"
                    :disabled="loading"
                    @change="changed('is_active')"
                    />
            </div>
        </div>
        <v-divider class="my-4" />
        <div class="d-flex">
            <v-spacer/>
            <v-btn text class="mr-3" :disabled="loading" @click="reset()">Reiniciar Filtros</v-btn>
            <v-btn color="primary" :disabled="loading" :loading="loading" @click="search()"><v-icon>mdi-magnify</v-icon>Buscar</v-btn>
        </div>
    </v-form>
</template>

<script>
import Search from '@mixins/search';

export default {
    mixins: [Search],
    data: function ()  {
        return {
            isActiveList: [
                {
                    text: 'Todos',
                    value: null,
                },
                {
                    text: 'Activos',
                    value: true,
                },
                {
                    text: 'Inactivos',
                    value: false,
                }
            ],
            defaultValues: {
                search: null,
                is_active: null,
            }
        }
    },
}
</script>