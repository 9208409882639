<template>
<v-card :loading="loading" elevation="5">
    <v-fade-transition mode="out-in">
        <div v-if="loading" key="loading">
            <v-card-text style="min-height: 100px">
            <v-progress-circular
                :size="50"
                color="secondary"
                indeterminate
                />
            </v-card-text>
        </div>
        <div v-else key="loaded">
            <div>
                <v-card-title>
                    <div class="d-flex justify-space-between align-center flex-grow-1">
                        <div>
                            <h3 class="mr-2">{{ grupo.name }}
                                <v-chip color="secondary">{{ grupo.id }}</v-chip>
                                <v-chip :color="{'true': 'success', 'false': 'error'}[grupo.is_active]">
                                    {{ grupo.is_active ? 'Activo' : 'Inactivo' }}
                                </v-chip>
                            </h3>
                            
                            <div class="d-flex align-center">
                            <p class="text-caption ma-0 mr-2">{{ grupo.slug }}</p>
                            </div>
                        </div>
                        <div>
                            <slot name="actions"></slot>
                        </div>
                    </div>
                </v-card-title>
                <v-card-text v-if="grupo.description">
                    <p v-if="grupo.description" class="ma-0">{{ grupo.description }}</p>
                </v-card-text>
            </div>
            <v-card-actions class="pa-0"><slot/></v-card-actions>
        </div>
    </v-fade-transition>
    <!--div class="primary">
        <v-card-title v-if="!loading && grupo" class="white--text">
            <div class="text-h5">
                <div>
                    {{ grupo.name }}
                    <v-chip :color="{'true': 'success', 'false': 'error'}[grupo.is_active]">
                        {{ grupo.is_active ? 'Activo' : 'Inactivo' }}
                    </v-chip>
                </div>
                <small>{{ grupo.slug }}</small>
            </div>
        </v-card-title>
        <v-card-title v-if="loading" class="white--text">
            <div class="text-h4">Loading...</div>
        </v-card-title>
    </div>

    <v-list two-line v-if="!loading && grupo">
    <v-divider inset v-if="grupo && grupo.description"></v-divider>
    <v-list-item>
        <v-list-item-icon>
            <v-icon color="primary">mdi-email</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
            <v-list-item-title>Descripción</v-list-item-title>
            <v-list-item-subtitle>{{ grupo.description }}</v-list-item-subtitle>
        </v-list-item-content>
    </v-list-item>
    <v-divider inset v-if="grupo && grupo.email && grupo.email.length"></v-divider>
    <v-list-item v-for="(email, index) in grupo.email" :key="`grupo-card-item-email-${index}`">
        <v-list-item-icon>
            <v-icon color="primary">mdi-email</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
            <v-list-item-title>{{email}}</v-list-item-title>
            <v-list-item-subtitle>Correo Electrónico {{index + 1}}</v-list-item-subtitle>
        </v-list-item-content>
    </v-list-item>

    <v-divider inset v-if="grupo && grupo.phone && grupo.phone.length"></v-divider>
    <v-list-item v-for="(phone, index) of grupo.phone" :key="`grupo-card-item-phone-${index}`">
        <v-list-item-icon>
            <v-icon color="primary">mdi-phone</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
            <v-list-item-title>{{phone}}</v-list-item-title>
            <v-list-item-subtitle>Telefono {{index + 1}}</v-list-item-subtitle>
        </v-list-item-content>
    </v-list-item>
    </v-list>
        <v-card-actions class="primary" v-if="grupo">
            <v-spacer/>
            
        </v-card-actions-->
</v-card>
</template>
  
  <script>
  export default {
    props: ['grupo', 'loading'],
  }
  </script>
  
  <style>
  
  </style>
