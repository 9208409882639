<template>
    <v-card>
        <v-card-text class="d-flex justify-space-between pb-0">
            <div class="mr-3">
                Hora
                <TimeInput v-model="fields.horario" :disabled="loading" label="Hora"/>
            </div>
            <div>
                Estado
                <v-switch v-model="fields.is_active" :disabled="loading" :label="`${fields.is_active?'Activo' : 'Inactivo'}`"/>
            </div>
        </v-card-text>
        <v-card-actions>
            <v-spacer/>
            <v-btn 
                small
                text
                :loading="loading"
                :disabled="loading"
                @click="reset()"
                >
                RESETEAR
            </v-btn>
            <v-btn 
                color="primary"
                :loading="loading"
                :disabled="loading"
                small
                text
                @click="saveSorteo()"
                >
                <v-icon>mdi-content-save</v-icon>
                <div v-if="this.juegoSorteo">
                    GUARDAR
                </div>
                <div v-else>
                    CREAR
                </div>
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
import JuegoSorteoService from '@services/operadora/loteria/JuegoSorteoService';
import TimeInput from '@components/generic/time-input';

export default {
    components: {
        TimeInput
    },
    props: ['juego', 'juegoSorteo', 'juegoSorteoList'],
    data: function () {
        return {
            loading: false,
            fields: {
                horario: null,
                is_active: null,
            },
        }
    },
    mounted: function () {
        this.reset(this.juegoSorteo);  
    },
    computed: {
        lastJuegoSorteo: function () {
            return this.juegoSorteoList.length ? this.juegoSorteoList[this.juegoSorteoList.length - 1] : null;
        }
    },
    methods: {
        saveSorteo: function () {
            this.loading = true;
            const data = {
                horario: this.fields.horario,
                is_active: this.fields.is_active,
                juego_id: this.juego.id
            };
            let action = null;
            if (this.juegoSorteo) {
                action = JuegoSorteoService.update(this.juegoSorteo.id, data);
            }
            else {
                action = JuegoSorteoService.create(data);
            }
            action.then(({juegoSorteo}) => {
                this.reset(juegoSorteo);
                this.$emit('saved', juegoSorteo);
            })
            .finally(() => {
                this.loading = false;
            });
        },
        reset: function (juegoSorteo = null) {
            if (juegoSorteo) {
                this.fields.horario = juegoSorteo.horario;
            } else {
                if (this.lastJuegoSorteo) {
                    this.fields.horario = this.$luxon.fromFormat(this.lastJuegoSorteo.horario, 'HH:mm:ss').plus({hours: 1}).toFormat('HH:mm:ss');
                } else {
                    this.fields.horario = '08:00:00';
                }
            }
            this.fields.is_active = juegoSorteo?.is_active;
        },
    },
    watch: {
        juegoSorteo: function (juegoSorteo) {
            this.reset(juegoSorteo);
        }
    }
}
</script>