<template>
<Layout>
  <v-scroll-x-transition mode="out-in">
    <router-view/>
  </v-scroll-x-transition>
  <ConfirmResolver/>
  <NotificationResolver/>
</Layout>
</template>

<script>
import Layout from '@components/layout';

import ConfirmResolver from '@components/global/confirm/resolver';
import NotificationResolver from '@components/global/notification/resolver';

export default {
  components: {
    Layout,
    ConfirmResolver,
    NotificationResolver,
  },
};
</script>
