import Tickets from '@pages/ticket';
import TicketsView from '@pages/ticket/view';

export default [
    {
        path: '/tickets',
        name: 'tickets',
        component: Tickets,
    },
    {
        path: '/tickets/:id',
        name: 'tickets.view',
        component: TicketsView,
    },
];
