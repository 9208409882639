<template>
    <v-dialog v-model="dialog" width="600">
        <v-card shaped>
            <v-card-title class="primary white--text">
                <h3>Bloqueo</h3>
            </v-card-title>
            <v-card-text class="pa-3">
                <v-container fluid>
                    <v-card outlined tile>
                        <v-card-text>
                            <BloqueoForm :loading="loading" v-model="bloqueoData" :valid.sync="isDataValid"/>
                        </v-card-text>
                    </v-card>
                </v-container>
            </v-card-text>
            <v-divider/>
            <v-card-actions>
                <v-spacer/>
                <v-btn 
                    color="primary" 
                    text 
                    :disabled="loading"
                    @click="reset()"
                    >
                    RESETEAR
                </v-btn>
                <v-btn 
                    color="primary" 
                    :disabled="!isDataValid || loading"
                    :loading="loading"
                    @click="submit()"
                    >
                    <v-icon>mdi-content-save</v-icon>
                    AÑADIR BLOQUEO
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import BloqueoForm from '@components/entities/loteria/bloqueo-figura/form';
import BloqueoFiguraService from '@services/loteria/BloqueoFiguraService';

export default {
    components: {
        BloqueoForm
    },
    props: ['open'],
    data: () => ({
        loading: false,
        dialog: false,
        bloqueoData: {},
        isDataValid: false,
    }),
    computed: {
        divisa: function () {
            return this.$store.getters['persist/divisa/selected'];
        },
    },
    methods: {
        close: function () {
            this.$emit('update:open', false);
        },
        submit: function () {
            this.loading = true;
            BloqueoFiguraService.create({... this.bloqueoData}).then(() => {
                this.$ui.notificate({message: 'Resultado Registrado', color: 'success'});
                this.dialog = false;
                this.$emit('update:saved', true);
            }).finally(() => this.loading = false);
        },
        reset: function () {
            this.bloqueoData = {};
        }
    },
    watch: {
        open: function (open) {
            if (open) {
                this.reset();
            }
            this.dialog = open;
        },
        dialog: function (dialog) {
            if (!dialog) {
                this.$emit('update:open', false);
                this.$emit('update:saved', false);
                this.reset();
            }
        },
    }
}
</script>
