<template>
    <v-card class="d-flex flex-column flex-grow-1" elevation="1">
        <v-card-title class="d-flex justify-space-between">
            <div>
                <h4>{{divisa.symbol}} - {{divisa.name}}</h4>
            </div>
            <div v-if="cupo">
                <slot name="actions"/>
            </div>
            <div v-else>
                <v-btn color="primary" @click="setCupo()" v-if="canRegister">HABILITAR {{ divisa.name }}</v-btn>
            </div>
        </v-card-title>
        <v-card-text class="d-flex" v-if="cupo">
            <div v-for="(info, index) in infoList" :key="`info-${cupo.id}-${index}`" class="mr-5">
                {{info.text}} · {{info.value}}
            </div>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    props: {
        divisa: {
            type: Object,
            required: true,
        },
        cupo: {
            type: Object,
            required: false,
        },
        conLimiteApuesta: {
            type: Boolean,
            default: false,
        },
        canRegister: {
            type: Boolean,
            default: true,
        }
    },
    computed: {
        infoList: function () {
            if (!this.cupo) return [];

            const currencyFormatter = this.$store.getters['ui/helpers/currencyFormatter'];
            const percentFormatter = this.$store.getters['ui/helpers/percentFormatter'];

            return [
                {
                    value: currencyFormatter(this.cupo.cupo_jugada, this.cupo.divisa.code),
                    text: 'Cupo Jugada',
                },
                {
                    value: percentFormatter(this.cupo.comision),
                    text: 'Comision',
                },
                {
                    value: percentFormatter(this.cupo.banqueo),
                    text: 'Banqueo',
                },
                ... this.conLimiteApuesta ? [
                    {
                        value: currencyFormatter(this.cupo.min_apuesta, this.cupo.divisa.code),
                        text: 'Min Apuesta',
                    },
                    {
                        value: currencyFormatter(this.cupo.max_apuesta, this.cupo.divisa.code),
                        text: 'Max Apuesta',
                    },
                ] : [],
            ];
        },
    },
    methods: {
        setCupo: function () {
            this.$emit('set', this.divisa);
        }
    }
}
</script>