<template>
    <v-dialog v-model="dialog" width="600">
        <v-card shaped>
            <v-card-title class="primary white--text">
                <h3>Juego</h3>
            </v-card-title>
            <v-card-text class="pa-3">
                <v-container fluid>  
                    <JuegoForm :loading="loading" v-model="juegoData" :default="defaultJuegoData" :valid.sync="isJuegoDataValid" :context="context" :newG="newG"/>
                </v-container>
            </v-card-text>
            <v-divider/>
            <v-card-actions>
                <v-spacer/>
                <v-btn color="primary" text :disabled="loading" @click="reset()">RESETEAR</v-btn>
                <v-btn color="primary" :disabled="!isValid || loading" :loading="loading" @click="submit()">
                    <v-icon>mdi-content-save</v-icon>
                    <span v-if="this.juego">Editar juego</span>
                    <span v-else>Añadir juego</span>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import JuegoForm from "./form"

import JuegoService from '@services/operadora/loteria/JuegoService';

export default {
    components: {
        JuegoForm,
    },
    props: ['loteria', 'juego', 'open', 'newG'],
    data: () => ({
        loading: false,
        dialog: false,

        context: 'new',

        juegoData: {},
        defaultJuegoData: null,
        isJuegoDataValid: false,
    }),
    mounted: function () {
        this.reset();
    },
    computed: {
        isValid: function () {
            return this.isJuegoDataValid;
        }
    },
    methods: {
        close: function () {
            this.$emit('update:open', false);
        },
        submit: function () {
            this.loading = true;
            let action = null;
            let data = {};

            if (this.isJuegoDataValid && Object.keys(this.juegoData).length) data = this.juegoData;

            if (this.loteria) data['loteria_id'] = this.loteria.id;
            if (data['max_tiempo_jugada']) data['max_tiempo_jugada'] = this.$luxon.fromFormat(`0:${data['max_tiempo_jugada']}:0`, 'H:m:s').toFormat('HH:mm:ss');

            if (this.juego) action = JuegoService.update(this.juego.id, data);
            else action = JuegoService.create(data);

            action.then(({ juego }) => {
                this.$ui.notificate({message: 'Juego guardado'});
                this.emit(juego);
                this.close();
            }).catch(() => {
                this.$ui.notificate({message: 'Error guardando el juego', color: 'error'});
            }).finally(() => this.loading = false);
        },
        emit: function (data) {
            this.$emit('saved', data);
        },
        reset: function () {
            if (this.juego) {
                this.context = 'edit';
                this.defaultJuegoData = { ... this.juego};
            } else {
                this.context = 'new';
                this.defaultJuegoData = null;
            }
            this.juegoData = {};
        }
    },
    watch: {
        open: function (open) {
            if (open) {
                this.reset();
            }
            this.dialog = open;
        },
        dialog: function (dialog) {
            if (!dialog) {
                this.$emit('update:open', false);
                this.reset();
            }
        },
    }
}
</script>
