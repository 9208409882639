import DivisaService from '@services/general/DivisaService';

import config from '@plugins/config';

const defaultCountryCode = config.get('COUNTRY_CODE');
const defaultCode = config.get('CURRENCY_CODE');

const currencyFormatters = {};
const getCurrencyFormatter = (code) => {
  return Object.prototype.hasOwnProperty.call(currencyFormatters, code) 
            ? 
            currencyFormatters[code] 
            : 
            currencyFormatters[code] = new Intl.NumberFormat(
                defaultCountryCode, 
                { 
                    style: 'currency', 
                    currency: code,
                    currencydisplay: 'symbol',
                }
            );
}

export default {
    namespaced: true,

    state: () => ({
        selected: null,
        default: null,
        isLoading: false,
    }),

    getters: {
        selected: state => state.selected,
        default: state => state.default,
        isLoading: state => state.isLoading,
        format: state => (value, code = null) => {
            if (code) return getCurrencyFormatter(code).format(value);
            return getCurrencyFormatter(state.selected?.code || defaultCode).format(value);
        },
        formatDefault: state => (value) => {
            return getCurrencyFormatter(state.default?.code || defaultCode).format(value);
        }
    },

    mutations: {
        setSelected: (state, divisa) => {
            state.selected = divisa;
        },
        setDefault: (state, divisa) => {
            state.default = divisa;
        },
        setIsLoading: (state, isLoading) => {
            state.isLoading = isLoading;
        }
    },

    actions: {
        setSelected: ({ commit }, divisa = null) => {
            return new Promise((resolve, reject) => {
                if (divisa) {
                    commit('setSelected', divisa);
                    resolve(divisa);
                } else {
                    commit('setIsLoading', true);
                    DivisaService
                        .getDefault()
                        .then(({ divisa }) => {
                            commit('setSelected', divisa);
                            resolve(divisa);
                        })
                        .catch(error => reject(error))
                        .finally(() => {
                            commit('setIsLoading', false);
                        });
                }
            });
        },
        setDefault: ({ commit, state }, divisa = null) => {
            if (state.default) return;
            return new Promise((resolve, reject) => {
                if (divisa) {
                    commit('setDefault', divisa);
                    resolve(divisa);
                } else {
                    DivisaService
                        .getDefault()
                        .then(({ divisa }) => {
                            commit('setDefault', divisa);
                            resolve(divisa);
                        })
                        .catch(error => reject(error))
                }
            });
        },
    }
}