import BaseService from "@utils/BaseService";
import axios from '@plugins/axios';

class LoteriaService extends BaseService {
    get(id, options = {}) {
        return axios({
            url: this.getRoute(id),
            method: 'GET',
            ...options
        });
    }
    getList(filters = {}, options = {}) {
        return axios({
            url: this.getRoute(),
            method: 'GET',
            params: this.getParamsProcessed({is_active: true, ...filters}),
            ...options
        });
    }
    count(filters = {}, options = {}) {
        return axios({
            url: this.getRoute(),
            method: 'GET',
            params: this.getParamsProcessed({is_active: true, ...filters}),
            ...options
        });
    }
}

export const BaseLoteriaService = LoteriaService;

export default new LoteriaService({
    domainName:'loteria',
    entityName:'loteria',
    query:['search', 'is_active'],
});