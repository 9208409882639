<template>
    <div>
        <ComercioSelector/>
    </div>
</template>
<script>
import ComercioSelector from '@components/entities/comercio/selector';

export default {
    components: {
        ComercioSelector
    }
}
</script>