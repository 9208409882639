import store from '@stores';

export class BaseUser {
    constructor($store, $router) {
        this.$router = $router;
        this.$store = $store;
        this.init();
    }
    get user() {
        return this.$store.getters['persist/auth/user'];
    }
    init() {
    }
    isDomain(domainSlug) {
        if (!this.user) return false;
        if (this.user.domain.slug === 'operadora') return true;
        if (Array.isArray(domainSlug)) return !!domainSlug.find(domain => domain == this.user.domain.slug); 
        else this.user.domain.slug == domainSlug;
    }
}

export default {
    install: function (Vue) {
        Vue.prototype['$user'] = new BaseUser(store);
    }
}