import layout from './layout';
import confirm from './confirm';
import notification from './notification';
import menu from './menu';
import helpers from './helpers';

export default {
    namespaced: true,
    modules: {
        layout,
        confirm,
        notification,
        menu,
        helpers
    },

    state: {
        currentDateTime: null,
        interval: null,
    },

    getters: {
        currentDateTime: state => state.currentDateTime,
    },

    mutations: {
        setCurrentDateTime: (state, dateTime) => {
            state.currentDateTime = dateTime;
        },
        setInterval: (state, interval) => {
            state.interval = interval;
        },
    },

    actions: {
        initTimer: function ({ commit }) {
            const interval = setInterval(() => {
                commit('setCurrentDateTime', this._vm.$luxon.now());
            }, 1000);

            commit('setInterval', interval);
        },
        stopTimer: ({ state, commit }) => {
            clearInterval(state.interval);
            commit('setInterval', null);
        }
    }
};