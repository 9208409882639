<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12"  md="3">
                <v-card elevation="2" :loading="loading">
                    <v-card-title>{{ count }} Taquillas</v-card-title>
                    <v-card-actions>
                        <v-btn color="primary" @click="add()"><v-icon>mdi-account-box</v-icon> Añadir taquilla</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card elevation="2">
                    <v-card-title>
                        <TaquillaSearch v-model="params" @search="getList()"/>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table
                            :headers="headersMod"
                            :items="list"
                            :options.sync="options"
                            :server-items-length="count"
                            :loading="loading"
                            >
                            <!-- eslint-disable-next-line -->
                            <template v-slot:item.name="{ item }">
                                <div>
                                    <div>{{ `${item.name}` }}</div>
                                    <small>{{ `${item.slug}` }}</small>
                                </div>
                            </template>
                            <!-- eslint-disable-next-line -->
                            <template v-slot:item.username="{ item }">
                                <div v-if="item">
                                    <v-chip :color="item.is_active ? 'primary' : 'secondary'" label>
                                        <v-icon left>mdi-account-circle-outline</v-icon>
                                        {{ item.username }}
                                    </v-chip>
                                </div>
                            </template>
                            <!-- eslint-disable-next-line -->
                            <template v-slot:item.grupo.name="{ item }">
                                <div>
                                    {{ item.grupo.name }} <v-chip color="secondary">{{ item.grupo.id }}</v-chip>
                                </div>
                            </template>
                            <!-- eslint-disable-next-line -->
                            <template v-slot:item.is_active="{ item }">
                                <div>
                                    <v-chip v-if="item.is_active" color="success" small>Activo</v-chip>
                                    <v-chip v-else color="error" small>Inactivo</v-chip>
                                </div>
                            </template>
                            <!-- eslint-disable-next-line -->
                            <template v-slot:item.actions="{ item }">
                                <div>
                                    <v-btn :color="item.is_bloqueado ? 'green' : 'yellow'" icon @click="liberar(item)">
                                        <v-icon>mdi-key-chain</v-icon>
                                    </v-btn>
                                    <v-btn color="secondary" icon @click="edit(item)">
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-btn>
                                    <v-btn color="primary" icon :to="{name: 'comercio.taquilla.view', params: {id: item.id}}">
                                        <v-icon>mdi-eye</v-icon>
                                    </v-btn>
                                </div>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card> 
            </v-col>
        </v-row>
        <TaquillaModal :open.sync="isModalOpen" @saved="postSaved" :taquilla="item"/>
    </v-container>
</template>

<script>
import TaquillaService from '@services/comercio/TaquillaService';

import TaquillaSearch from '@components/entities/comercio/taquilla/search';
import TaquillaModal from '@components/entities/comercio/taquilla/modal';

export default {
    components: {
        TaquillaSearch,
        TaquillaModal
    },
    data: () => ({
        options: {},
        loading: false,
        list: [],
        count: 0,

        isModalOpen: false,
        item: null,

        params: {}
    }),
    created: function () {
        this.$store.dispatch('ui/layout/setTitle', 'Taquillas');
    },
    computed: {
        user: function () {
            return this.$store.getters['persist/auth/user'];
        },
        headersMod: function () {
            const headersA = [
                { text: 'Nombre', value: 'name' },
                { text: 'Usuario', value: 'username' },
            ];
            const headersB = [
                { text: 'Estado', value: 'is_active', align: 'center', width: 150 },
                { text: 'Acciones', value: 'actions', align: 'right', width: 200 }
            ];
            return [
                ...headersA, 
                ['operadora'].includes(this.user.domain.slug) ? { text: 'Master', value: 'master.name' } : {},
                ['operadora', 'master'].includes(this.user.domain.slug) ? { text: 'Comercializadora', value: 'comercializadora.name' } : {},
                ['operadora', 'master', 'comercializadora', 'grupo'].includes(this.user.domain.slug) ? { text: 'Grupo', value: 'grupo.name' } : {},
                ...headersB
            ];
        },
    },
    methods: {
        getList: function (params = {}) {
            this.loading = true;
            TaquillaService.getList({...this.params, ...params})
                .then(({taquillaList, taquillaCount}) => {
                    this.list = taquillaList;
                    this.count = taquillaCount;
                }).finally(() => {
                    this.loading = false;
                });
        },
        add: function () {
            this.item = null;
            this.isModalOpen = true;
        },
        edit: function (item) {
            this.item = item;
            this.isModalOpen = true;
        },
        postSaved: function () {
            this.count = this.count + 1;
            this.getList();
        },
        getOptions: function () { 
            const params = {};
            let itemsPerPage = null;
            if (this.options.itemsPerPage !== -1) itemsPerPage = this.options.itemsPerPage
            else if (this.count) itemsPerPage = this.count
            params['limit'] = itemsPerPage;
            params['offset'] = (this.options.page * itemsPerPage) - itemsPerPage;
            return params;
        },
        liberar: function (item) {
            this.$ui.confirm({
                message: `¿Deseas liberar el usuario ${item.username}?`,
                textConfirm: 'Liberar',
                textCancel: 'Cancelar',
                confirm: () => {
                    this.liberarUsuario(item.id);
                },
            });
        },
        liberarUsuario: function (taquillaId) {
            this.loading = true;
            TaquillaService.liberar(taquillaId)
                .then(({taquilla}) => {
                    this.$emit('liberado', taquilla);
                    this.$ui.notificate({message: 'Usuario Liberado'});
                }).catch(() => {
                    this.$ui.notificate({message: 'Error liberando', color: 'error'});
                }).finally(() => {
                    this.loading = false;
                });
        },
    },
}
</script>
