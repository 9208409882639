import OperadoraService from '@services/comercio/OperadoraService'
import store from '@stores';

export default {
    namespaced: true,
    
    state: () => ({
        isLoading: false,
        default: null,
        timeLimit: null,
    }),

    getters: {
        isLoading: state => state.isLoading,
        default: state => state.default,
        timeLimit: state => state.timeLimit,
    },

    mutations: {
        set: (state, operadora) => {
            state.default = operadora;
        },
        setIsLoading: (state, isLoading) => {
            state.isLoading = isLoading;
        },
        setTimeLimit: (state, timeLimit) => {
            state.timeLimit = timeLimit;
        }
    },
    actions: {
        set: ({ commit }, operadora = null) => {
            return new Promise((resolve, reject) => {
                if (operadora) {
                    commit('setDefault', operadora);
                    commit('setTimeLimit', operadora.max_tiempo_anulacion);
                    resolve(operadora);
                } else {
                    commit('setIsLoading', true);
                    OperadoraService
                        .get()
                        .then(({ operadora }) => {
                            commit('set', operadora);
                            let user = store.getters['persist/auth/user'];
                            commit('setTimeLimit', {
                                'operadora': '12:00:00',
                                'master': operadora.max_tiempo_anulacion_master_comer,
                                'comercializadora': operadora.max_tiempo_anulacion_master_comer,
                            }[user.domain.slug]);
                            resolve(operadora);
                        })
                        .catch(error => reject(error))
                        .finally(() => {
                            commit('setIsLoading', false);
                        });
                }
            });
        },
    }
};