<template>
    <v-dialog v-model="dialog" width="600">
        <v-card shaped>
            <v-card-title class="primary white--text">
                <h3>Master</h3>
            </v-card-title>
            <v-card-text class="pa-3">
                <v-container fluid>
                    <MasterForm :loading="loading" v-model="masterData" :default="defaultMasterData" :valid.sync="isMasterDataValid" :context="context"/>
                    <v-checkbox v-model="hasUser" label="¿posee usuario?" :disabled="!!(master && master.user)"></v-checkbox>
                    <v-card outlined tile v-if="hasUser">
                        <v-card-title>Usuario</v-card-title>
                        <v-card-text>
                            <UserForm :loading="loading" v-model="userData"  :default="defaultUserData" :valid.sync="isUserDataValid" :context="context"/>
                        </v-card-text>
                    </v-card>
                </v-container>
            </v-card-text>
            <v-divider/>
            <v-card-actions>
                <v-spacer/>
                <v-btn color="primary" text :disabled="loading" @click="reset()">RESETEAR</v-btn>
                <v-btn color="primary" :disabled="!isValid || loading" :loading="loading" @click="submit()">
                    <v-icon>mdi-content-save</v-icon>
                    <span v-if="this.master">Editar master</span>
                    <span v-else>Añadir master</span>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import MasterForm from "./form"
import UserForm from '@components/entities/general/user/form';

import MasterService from '@services/comercio/MasterService';

export default {
    components: {
        MasterForm,
        UserForm
    },
    props: ['master', 'open'],
    data: () => ({
        loading: false,
        dialog: false,

        context: 'new',

        masterData: {},
        defaultMasterData: null,
        isMasterDataValid: false,

        userData: {},
        defaultUserData: null,
        isUserDataValid: false,

        hasUser: false,
    }),
    mounted: function () {
        this.reset();
    },
    computed: {
        isValid: function () {
            let isMasterDataValid = this.isMasterDataValid;
            let isUserDataValid = false;
            if (this.hasUser) {
                isUserDataValid = this.isUserDataValid;
            } else {
                isUserDataValid = isMasterDataValid;
            }
            return this.master ? isMasterDataValid || isUserDataValid : isMasterDataValid && isUserDataValid;
        }
    },
    methods: {
        close: function () {
            this.$emit('update:open', false);
        },
        submit: function () {
            this.loading = true;
            let action = null;
            const data = {};

            if (this.isMasterDataValid && Object.keys(this.masterData).length) data['master'] = this.masterData;
            if (this.hasUser && this.isUserDataValid && Object.keys(this.userData).length) data['user'] = this.userData;

            if (this.master) action = MasterService.update(this.master.id, data);
            else action = MasterService.create(data);

            action.then(({ master }) => {
                this.$ui.notificate({message: 'Master guardado'});
                this.emit(master);
                this.close();
            }).catch(() => {
                this.$ui.notificate({message: 'Error guardando el master', color: 'error'});
            }).finally(() => this.loading = false);
        },
        emit: function (data) {
            this.$emit('saved', data);
        },
        reset: function () {
            if (this.master) {
                this.context = 'edit';
                this.defaultMasterData = { ... this.master};
                if (this.master.user) {
                    this.hasUser = true;
                    this.defaultUserData = { ... this.master.user };
                } else {
                    this.hasUser = false;
                    this.defaultUserData = null;
                }
            } else {
                this.context = 'new';
                this.defaultMasterData = null;
                this.defaultUserData = null;
            }
            this.masterData = {};
            this.userData = {};
        }
    },
    watch: {
        open: function (open) {
            this.reset();
            this.dialog = open;
        },
        dialog: function (dialog) {
            if (!dialog) {
                this.$emit('update:open', false);
                this.reset();
            }
        },
    }
}
</script>
