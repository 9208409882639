<template>
	<div>
        <MenuHeader/>
        <MenuAside/>
        <v-main>
            <v-container fluid>
                <slot></slot>
            </v-container>
        </v-main>
    </div>
</template>
<script>
import MenuHeader from "./header"
import MenuAside from "./aside"

export default {
    components: {
        MenuHeader,
        MenuAside,
    }
}
</script>