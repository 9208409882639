export default {
    namespaced: true,
    state: () => ({
        drawer: false,
        title: null,
    }),
    getters: {
        getDrawer: state => state.drawer,
        getTitle: state => state.title
    },
    mutations: {
        setDrawer: (state, drawer) => {
            state.drawer = drawer;
        },
        setTitle: (state, title) => {
            state.title = title;
        }
    },
    actions: {
        setDrawer: ({ commit }, drawer) => {
            commit('setDrawer', drawer)
        },
        setTitle: ({ commit }, title) => {
            commit('setTitle', title)
        },
    }
}