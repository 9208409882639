<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="list"
            :items-per-page="-1"
            :loading="loading"
            hide-default-footer
            disable-pagination
            disable-filtering
            show-expand
            single-expand
            item-key="juego"
            >
            <!-- eslint-disable-next-line -->
            <template v-slot:item.horario="{ item }">
                {{ item.juego }}
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.venta="{ item }">
                {{ item.venta | currency }}
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.comision="{ item }">
                {{ item.comision | currency }}
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.premio="{ item }">
                {{ item.premio | currency }}
            </template>
            <!-- eslint-disable-next-line --> 
            <template v-slot:item.total="{ item }">
                <v-chip small label :color="item.percent > 0.80 ? 'error': 'primary'" class="font-weight-bold">
                    {{ item.total | currency(item.divisa_code) }} {{' / '}} {{ item.percent | percent }}
                </v-chip>
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.banqueo="{ item }">
                {{ item.banqueo[0] | currency }} / {{ item.banqueo[1] | currency }}
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                    <v-data-table
                        :headers="headers"
                        :items="item.sorteos"
                        :items-per-page="-1"
                        hide-default-footer
                        disable-pagination
                        class="elevation-1"
                    >
                    <!-- eslint-disable-next-line -->
                    <template v-slot:item.horario="{ item }">
                        {{ item.juego }} - {{ item.horario }} 
                    </template>
                    <!-- eslint-disable-next-line -->
                    <template v-slot:item.venta="{ item }">
                        {{ item.venta | currency }}
                    </template>
                    <!-- eslint-disable-next-line -->
                    <template v-slot:item.comision="{ item }">
                        {{ item.comision | currency }}
                    </template>
                    <!-- eslint-disable-next-line -->
                    <template v-slot:item.premio="{ item }">
                        {{ item.premio | currency }}
                    </template>
                    <!-- eslint-disable-next-line -->
                    <template v-slot:item.total="{ item }">
                        {{ item.total | currency }}
                    </template>
                    <!-- eslint-disable-next-line -->
                    <template v-slot:item.banqueo="{ item }">
                        {{ item.banqueo[0] | currency }} / {{ item.banqueo[1] | currency }}
                    </template>
                    </v-data-table>
                </td>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import VentaService from '@services/operadora-loteria/VentaService';

export default {
    components: {
    },
    props: {
        params: {
            defaults: function () {
                return {};
            }
        }
    },
    data: function() { 
        return {
            headers: [
            { text: 'Juego', align: 'start', value: 'horario' },
            { text: 'Ventas', value: 'venta' },
            { text: 'Comisión', value: 'comision' },
            { text: 'Premios', value: 'premio' },
            { text: 'Total', value: 'total'},
            { text: 'Banqueo',align: 'center', value: 'banqueo' },
            ],
            list: [],
            loading :false,
            count: 0,
        }
    },
    computed: {
        divisa: function () {
            return this.$store.getters['persist/divisa/selected'];
        },
        user: function () {
            return this.$store.getters['persist/auth/user'];
        },
    },
    mounted: function () {
        this.getList();
    },
    methods: {
        getList: function () {
            this.loading = true;
            this.list = [];
            VentaService.reportePorSorteoConsolidado({ ... this.params, divisa_id: this.divisa.id })
                .then(({reportList}) => {
                    let general = [];
                    let index = null;
                    reportList.forEach(reporte => {
                        index = general.findIndex(obj => obj.juego === reporte.juego);
                        if (index < 0)
                        {
                            general.push({
                                juego: reporte.juego,
                                venta: 0,
                                comision: 0,
                                premio: 0,
                                total: 0,
                                percent: 0,
                                banqueo: [0,0],
                                sorteos: [],
                            });
                            index = general.findIndex(obj => obj.juego === reporte.juego);
                        }
                        general[index].venta += reporte.venta;
                        general[index].comision += parseFloat(reporte.comision);
                        general[index].premio += reporte.premio;
                        general[index].total += reporte.total;
                        general[index].banqueo[0] += reporte.banqueo[0];
                        general[index].banqueo[1] += reporte.banqueo[1];
                        general[index].sorteos.push(reporte);
                    });
                    general.forEach(reporte => {
                        reporte.percent = reporte.premio !== 0 ? (reporte.premio / reporte.venta) : 0;
                    });
                    this.list = general;
                }).catch(() => {

                }).finally(() => {
                    this.loading = false;
                })
        },
        open: function (reporte, context = 'tab') {
            const comercio = [];
            if (this.user.comercio_type !== 'operadora') comercio[this.getComType(reporte.comercio_type)] = reporte.comercio_id;
            const route = this.$router.resolve({
                name: 'reporte.general',
                query: {
                    ... this.params,
                    juego_id: reporte.juego_id,
                    juego_sorteo_id: reporte.juego_sorteo_id,
                    ... context == 'window' ? { 'minimal': 1, 'no-layout': 1 }: {},
                }
            });
            if (context == 'tab') return window.open(new URL(route.href, window.location.origin), '_blank').focus();
            if (context == 'window') return window.open(new URL(route.href, window.location.origin), '_blank', 'titlebar=no, status=no, menubar=no, height=600 width=1000');
        },
        getComType: function (comercioType) {
            return {'master': 'master_id', 'comercializadora': 'comercializadora_id', 'grupo': 'grupo_id', 'taquilla': 'taquilla_id'}[comercioType];
        },
    },
    watch: { 
        'params': function () {
            this.getList();
        },
        'divisa': function () {
            this.getList();
        }
    }
}
</script>
<style>

</style>