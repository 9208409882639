<template>
    <div class="d-inline">
        <v-btn v-if="loteria" text small @click="dialog = !dialog"><v-icon small>mdi-pencil</v-icon> EDITAR</v-btn>
        <v-btn v-else color="white" text small @click="dialog = !dialog">AÑADIR LOTERÍA</v-btn>
        <v-dialog v-model="dialog" width="600">
            <v-card shaped>
                <v-card-title class="primary white--text">
                    <h3>Loteria</h3>
                </v-card-title>
                <v-card-text class="pa-3">
                    <v-container fluid>
                        <LoteriaForm :loading="loading" v-model="loteriaData" :default="defaultLoteriaData" :valid.sync="isLoteriaDataValid" :context="context"/>
                        <v-checkbox v-model="hasUser" label="¿posee usuario?" :disabled="!!(loteria && loteria.user)"></v-checkbox>
                        <v-card outlined tile v-if="hasUser">
                            <v-card-title>Usuario</v-card-title>
                            <v-card-text>
                                <UserForm :loading="loading" v-model="userData"  :default="defaultUserData" :valid.sync="isUserDataValid" :context="context"/>
                            </v-card-text>
                        </v-card>
                    </v-container>
                </v-card-text>
                <v-divider/>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn color="primary" text :disabled="loading" @click="reset()">RESETEAR</v-btn>
                    <v-btn color="primary" :disabled="!isValid || loading" :loading="loading" @click="submit()">
                        <v-icon>mdi-content-save</v-icon>
                        <span v-if="this.loteria">Editar loteria</span>
                        <span v-else>Añadir loteria</span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import LoteriaForm from "./form"
import UserForm from '@components/entities/general/user/form';

import LoteriaService from '@services/operadora/loteria/LoteriaService';

export default {
    components: {
        LoteriaForm,
        UserForm
    },
    props: ['loteria', 'open'],
    data: () => ({
        loading: false,
        dialog: false,

        context: 'new',

        loteriaData: {},
        defaultLoteriaData: null,
        isLoteriaDataValid: false,

        userData: {},
        defaultUserData: null,
        isUserDataValid: false,

        hasUser: false,
    }),
    mounted: function () {
        this.reset();
    },
    computed: {
        isValid: function () {
            let isLoteriaDataValid = this.isLoteriaDataValid;
            let isUserDataValid = false;
            if (this.hasUser) {
                isUserDataValid = this.isUserDataValid;
            } else {
                isUserDataValid = isLoteriaDataValid;
            }
            return this.loteria ? isLoteriaDataValid || isUserDataValid : isLoteriaDataValid && isUserDataValid;
        }
    },
    methods: {
        close: function () {
            this.dialog = false;
        },
        submit: function () {
            this.loading = true;
            let action = null;
            const data = {};

            if (this.isLoteriaDataValid && Object.keys(this.loteriaData).length) data['loteria'] = this.loteriaData;
            if (this.hasUser && this.isUserDataValid && Object.keys(this.userData).length) data['user'] = this.userData;

            if (this.loteria) action = LoteriaService.update(this.loteria.id, data);
            else action = LoteriaService.create(data);

            action.then(({ loteria }) => {
                this.$ui.notificate({message: 'Loteria guardado'});
                this.emit(loteria);
                this.close();
            }).catch(() => {
                this.$ui.notificate({message: 'Error guardando el loteria', color: 'error'});
            }).finally(() => this.loading = false);
        },
        emit: function (data) {
            this.$emit('saved', data);
        },
        reset: function () {
            if (this.loteria) {
                this.context = 'edit';
                this.defaultLoteriaData = { ... this.loteria};
                if (this.loteria.user) {
                    this.hasUser = true;
                    this.defaultUserData = { ... this.loteria.user };
                } else {
                    this.hasUser = false;
                    this.defaultUserData = {};
                }
            } else {
                this.context = 'new';
                this.defaultLoteriaData = {};
                this.defaultUserData = {};
                this.hasUser = false;
            }
            this.loteriaData = {};
            this.userData = {};
        }
    },
    watch: {
        dialog: function () {
            this.reset();
        },
    }
}
</script>
