<template>
    <div>
        <div class="mb-2">
            <v-card>
                <v-card-title>
                    Ventas Por Figura
                </v-card-title>
                <v-card-text class="d-flex justify-space-between align-center">
                    <div>
                        <div class="d-flex align-center mb-2" v-if="params.juego_sorteo_id">
                            <span class="mr-4">SORTEO</span>
                            <Totalizador :loading="loadingSorteoSummary" :summary="sorteoSummary"/>
                        </div>
                        <div class="d-flex align-center mb-2">
                            <span class="mr-4">DÍA</span>
                            <Totalizador :loading="loadingDaySummary" :summary="daySummary"/>
                        </div>
                        <div class="d-flex align-center" v-if="params.juego_sorteo_id && weekSummary">
                            <span class="mr-4">SEMANA</span>
                            <Totalizador :loading="loadingWeekSummary" :summary="weekSummary"/>
                        </div>
                    </div>
                    <div>
                        <h1>{{ totalTickets }} Tickets</h1>
                    </div>
                </v-card-text>
            </v-card>
        </div>
        <v-data-table
            :headers="headers"
            :items="listComputed"
            :items-per-page="-1"
            :loading="loading"
            >
            <!-- eslint-disable-next-line -->
            <template v-slot:item.comercio="{ item }">
                <div v-if="context == 'comercio'">
                    <small class="d-flex">{{ item.parentComercio }}</small>
                    <v-btn text small @click="open({
                        'comercio': 'reporte.figura',
                        'sistema': 'operadora.sistema.reporte.figura',
                    }[context], {
                        comercio: {
                            type: item.comercio_type,
                            id: item.comercio_id,
                        }
                    })">
                        <v-icon class="mr-2" small>mdi mdi-eye-outline</v-icon>
                        {{ item.comercio }} 
                    </v-btn>
                </div>
                <div v-if="context == 'sistema'">
                    {{ item.comercio }} 
                </div>
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.tickets="{ item }">
                <v-btn small class="mr-2" text @click="open({
                    'comercio': 'tickets',
                    'sistema': 'operadora.sistema.tickets',
                }[context], {
                    numero: item.figura.value
                })">
                    <v-icon class="mr-2" small>mdi mdi-receipt-text</v-icon>
                    {{ item.ticket_count }}
                </v-btn>
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.venta="{ item }">
                {{ item.venta | currency(item.divisa_code) }}
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.comision="{ item }">
                {{ item.comision | currency(item.divisa_code) }}
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.projected="{ item }">
                <v-chip small label :color="item.premioColor" class="font-weight-bold mr-2">
                    {{ item.projected | currency(item.divisa_code) }} {{' / '}} {{ item.premioPercent | percent }}
                </v-chip>
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.total_projected="{ item }">
                <v-chip small label :color="item.sorteoColor" class="font-weight-bold mr-2">
                    Sorteo: {{ item.sorteoTotal | currency(item.divisa_code) }}
                </v-chip>
                <v-chip v-if="weekSummary" small label :color="item.dayColor" class="font-weight-bold mr-2">
                    Día {{ item.dayTotal | currency(item.divisa_code) }}
                </v-chip>
                <v-chip v-if="weekSummary" small label :color="item.weekColor" class="font-weight-bold">
                    Semana {{ item.weekTotal | currency(item.divisa_code) }}
                </v-chip>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import VentaService from '@services/reporte/VentaService';

import Totalizador from '@components/entities/reporte/venta/figura/totalizador';

export default {
    components: {
        Totalizador
    },
    props: {
        params: {
            defaults: function() {
                return {

                };
            },
        },
        context: {
            default: 'comercio',
        },
    },
    data: function() { 
        return {
            list: [],
            count: 0,
            relatedByWeek: {},
            loading: false,
            loadingSorteoSummary: false,
            sorteoSummary: null,
            loadingDaySummary: true,
            daySummary: null,
            loadingWeekSummary: true,
            weekSummary: null,
            ultimo_resultado: null,
            ultimoResultadoLegible: "",
            totalTickets: 0,
        }
    },
    computed: {
        headers: function () {
            return [
                ... this.context == 'sistema' ? [{ text: 'Sistema', align: 'start', value: 'comercio' }] : [{ text: 'Comercio', align: 'start', value: 'comercio' }],
                { text: 'Tickets',align: 'start', value: 'tickets' },
                { text: 'Venta', value: 'venta' },
                { text: 'Comisión', value: 'comision' },
                { text: 'Premio', value: 'projected' },
                { text: 'Total', value: 'total_projected' },
            ];
        },
        divisa: function () {
            return this.$store.getters['persist/divisa/selected'];
        },
        listComputed: function () {
            return this.list.map(report => {

                let sorteoColor = 'secondary';
                let sorteoTotal = 0;
                let sorteoPercent = 0;

                let premioColor = 'secondary';
                let premioPercent = 0;

                if (this.sorteoSummary) {
                    sorteoTotal = this.sorteoSummary.total - report.projected;
                    sorteoPercent = this.sorteoSummary.total / sorteoTotal;

                    if (sorteoTotal > 0) sorteoColor = 'primary';
                    if (sorteoTotal == 0) sorteoColor = 'secondary';
                    if (sorteoTotal < 0) sorteoColor = 'error';

                    if (report.projected) {
                        premioPercent = report.projected / this.sorteoSummary.venta;
                        if (premioPercent <= 1) premioColor = 'primary';
                        else premioColor = 'error';
                    }
                }

                let dayColor = 'secondary';
                let dayTotal = 0;
                let dayPercent = 0;

                if (this.daySummary) {
                    dayTotal = this.daySummary.total - report.projected;
                    dayPercent = this.daySummary.total / dayTotal;

                    if (dayTotal > 0) dayColor = 'primary';
                    if (dayTotal == 0) dayColor = 'secondary';
                    if (dayTotal < 0) dayColor = 'error';
                }

                let weekColor = 'secondary';
                let weekTotal = 0;
                let weekPercent = 0;

                if (this.weekSummary) {
                    weekTotal = this.weekSummary.total - report.projected;
                    weekPercent = this.weekSummary.total / weekTotal;

                    if (weekTotal > 0) weekColor = 'primary';
                    if (weekTotal == 0) weekColor = 'secondary';
                    if (weekTotal < 0) weekColor = 'error';
                }

                const parentComercio = ['master', 'comercializadora', 'grupo', 'taquilla'].reduce((text, comercio, index) => {
                    if (!report[comercio +'_name']) return text;
                    return `${text}${index !== 0 ? ' - ' : ''}${report[comercio +'_name']}`; 
                }, '');

                return {
                    ...report,
                    sorteoColor,
                    sorteoTotal,
                    sorteoPercent,
                    premioColor,
                    premioPercent,
                    dayColor,
                    dayTotal,
                    dayPercent,
                    weekColor,
                    weekTotal,
                    weekPercent,
                    parentComercio
                }
            })
        },
        summarySorteo: function () {
            return {
                venta: this.ventas,
                comision: this.comision,
                premio: this.premio,
                total: this.total,
            }
        }
    },
    methods: {
        get: function () {
            this.getList();
            this.getSorteoSummary();
            this.getDaySummary();            
            this.getWeekSummary();
        },
        getLegibleDistanciaFecha: function (date) {
            const start = this.$luxon.fromSQL(date);
            const end = this.$luxon.now();

            const days = Math.floor(end.diff(start, 'days').toObject().days);

            return days;
        },
        getList: function () {
            this.list = [];
            this.loading = true;
            this.ventas = 0;
            this.comision = 0;
            this.premio = 0;
            this.total = 0;
            this.subtotal = 0;
            this.totalTickets = 0;

            const params = { ...this.params, divisa_id: this.divisa.id };

            if (this.context == 'sistema') {
                params['group_by'] = 'sistema';
                params['comercio_type'] = 'sistema';
            }

            VentaService.reportePorFiguraDetallado(params)
                .then(({reportList}) => {
                    this.totalTickets = 0;
                    reportList.forEach(reporte => {
                        this.totalTickets += reporte.ticket_count;
                        this.ultimo_resultado = reporte.ultimo_resultado;
                    });
                    this.ultimoResultadoLegible = this.getLegibleDistanciaFecha(this.ultimo_resultado);
                    this.list = reportList;
                }).finally(() => {
                    this.loading = false;
                });
        },
        getSorteoSummary: function (params = {}) {
            this.sorteoSummary = null;
            this.loadingSorteoSummary = true;

            let parameters = {...this.params, ...params, divisa_id: this.divisa.id};

            if (this.context == 'sistema') {
                parameters['group_by'] = 'sistema';
                parameters['comercio_type'] = 'sistema';
            }

            delete parameters.numero;

            VentaService.reporteConsolidado(parameters)
                .then(({reportList}) => {
                    this.sorteoSummary = reportList[0];
                }).finally(() => {
                    this.loadingSorteoSummary = false;
                });
        },
        getDaySummary: function (params = {}) {
            this.daySummary = null;
            this.loadingDaySummary = true;

            let parameters = {...this.params, ...params, divisa_id: this.divisa.id};

            delete parameters.juego_sorteo_id;

            if (this.context == 'sistema') {
                parameters['group_by'] = 'sistema';
                parameters['comercio_type'] = 'sistema';
            }

            delete parameters.numero;

            VentaService.reporteConsolidado(parameters)
                .then(({reportList}) => {
                    this.daySummary = reportList[0];
                }).finally(() => {
                    this.loadingDaySummary = false;
                });
        },
        getWeekSummary: function (params = {}) {
            this.weekSummary = null;
            this.loadingWeekSummary = true;

            let parameters = {...this.params, ...params, divisa_id: this.divisa.id};
            
            const dateParam = parameters.date;
            delete parameters.date;
            delete parameters.juego_sorteo_id;

            const DateTime = this.$luxon;
            // Obtén la fecha y hora actual
            let ahora = DateTime.fromFormat(dateParam, 'yyyy-LL-dd').setZone('America/Caracas');
            // Encuentra el lunes de la semana actual
            let lunes = ahora.startOf('week');
            // Asegúrate de que la hora esté seteada en 00:00:00
            lunes = lunes.set({ hour: 0, minute: 0, second: 0 });

            parameters.daterange = [lunes.toISODate(), dateParam];

            if (this.context == 'sistema') {
                parameters['group_by'] = 'sistema';
                parameters['comercio_type'] = 'sistema';
            }

            delete parameters.numero;

            VentaService.reporteConsolidado(parameters)
                .then(({reportList}) => {
                    this.weekSummary = reportList[0];
                }).finally(() => {
                    this.loadingWeekSummary = false;
                });
        },
        open: function (route, filters, context = 'tab') {
            const comercio = filters.comercio;
            delete filters.comercio;
            
            const query = {
                ... this.params,
                ... filters,
            };
            
            if (comercio) query[`${comercio.type}_id`] = comercio.id;
            
            const $route = this.$router.resolve({
                name: route,
                query: query,
            });

            if (context == 'tab') return window.open(new URL($route.href, window.location.origin), '_blank').focus();
            if (context == 'window') return window.open(new URL($route.href, window.location.origin), '_blank', 'titlebar=no, status=no, menubar=no, height=600 width=1000');
        }
    },
    watch: { 
        'params': function () {
            this.get();
        },
        'divisa': function () {
            this.get();
        }
    },
    mounted: function () {
        this.get();
    }
}
</script>
<style>

</style>