import CrudService from "@utils/CrudService";

class LoteriaService extends CrudService {}

export const BaseLoteriaService = LoteriaService;

export default new LoteriaService({
    domainName:'operadora/loteria',
    entityName:'loteria',
    fields: {
        'loteria': ['name', 'description', 'is_active'],
        'user': ['username', 'password', 'is_active']
    },
});