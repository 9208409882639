<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="list"
            :items-per-page="10"
            :loading="loading"
            :class="nopage ? 'primary' : ''"
            :hide-default-footer="nopage"
            :disable-pagination="nopage"
            >
            <!-- eslint-disable-next-line -->
            <template v-slot:item.juego="{ item }">
                {{ item.juego }}
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.divisa="{ item }">
                {{ item.divisa }}
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.venta="{ item }">
                {{ item.venta | currency(item.divisa_code) }}
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.comision="{ item }">
                {{ item.comision | currency(item.divisa_code) }}
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.premio="{ item }">
                {{ item.premio | currency(item.divisa_code) }}
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.total="{ item }">
                <v-chip small label :color="item.percent > 0.80 ? 'error': 'primary'" class="font-weight-bold mr-2">
                    {{ item.total | currency(item.divisa_code) }} {{' / '}} {{ item.percent | percent }}
                </v-chip>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import VentaService from '@services/operadora-loteria/VentaService';

export default {
    components: {

    },
    props: {
        params: {
            defaults: function() {
                return {

                };
            },
        },
        nopage: {
            type: Boolean,
            default: false,
        }
    },
    data: function() { 
        return {
            headers: [
                { text: 'Juego', align: 'start', value: 'juego' },
                { text: 'Divisa', value: 'divisa' },
                { text: 'Venta', value: 'venta' },
                { text: 'Comisión', value: 'comision' },
                { text: 'Premio', value: 'premio' },
                { text: 'Total', value: 'total' },
            ],
            list: [],
            count: 0,
            loading: false,       
        }
    },
    computed: {
        user: function () {
            return this.$store.getters['persist/auth/user'];
        },
    },
    mounted: function () {
        if (this.nopage) this.$delete(this.headers, 1);
        this.getList();
    },
    methods: {
        getList: function () {
            this.list = [];
            this.loading = true;
            const comercio = [];
            if (this.user.comercio_type !== 'operadora') comercio[this.getComType(this.user.comercio_type)] = this.user.comercio_id; 
            VentaService.reporteConsolidado({ ...this.params, ...comercio})
                .then(({reportList}) => {
                    this.list = reportList;
                }).finally(() => {
                    this.loading = false;
                });
        },
        getComType: function (comercioType) {
            return {'master': 'master_id', 'comercializadora': 'comercializadora_id', 'grupo': 'grupo_id', 'taquilla': 'taquilla_id'}[comercioType];
        },
    },
    watch: { 
        'params': function () {
            this.getList();
        },
    },
}
</script>
<style>

</style>