<template>
    <v-form ref="form" v-model="valid">
            <v-row dense>
                <v-col v-if="!!master" cols="12" sm="12">
                    Master
                    <v-text-field
                        readonly
                        :value="`${master.name}`"
                        hide-details
                        filled
                        >
                    </v-text-field>
                </v-col>
                <v-col v-else cols="12" sm="12" v-show="showMaster">
                    Master
                    <v-select
                        :items="masterList"
                        :loading="isLoadingMasterList"
                        :disabled="isLoadingMasterList"
                        item-text="name"
                        item-value="id"
                        label="Master"
                        @change="changed('master_id', $event)"
                        v-model="fields.master_id"
                        :rules="rules.master_id"
                        hide-details="auto"
                        solo
                        >
                    </v-select>
                </v-col>
                <v-col cols="12" sm="12" md="8">
                    Nombre
                    <v-text-field
                        v-model="fields.name"
                        :rules="rules.name"
                        :disabled="loading"
                        label="Nombre"
                        solo
                        hide-details="auto"
                        @change="changed('name', $event)"
                        required
                        />
                </v-col>
                <v-col cols="12" sm="4" md="4">
                    Estado
                    <v-switch
                        v-model="fields.is_active"
                        :rules="rules.is_active"
                        :disabled="loading"
                        :label="fields.is_active ? 'Activo' : 'Inactivo'"
                        inset
                        hide-details="auto"
                        color="primary"
                        @change="changed('is_active', $event)"
                        required
                        />
                </v-col>
                <v-col cols="12">
                    Descripción
                    <v-textarea
                        v-model="fields.description"
                        :rules="rules.description"
                        :disabled="loading"
                        label="Descripción"
                        rows="5"
                        clearable
                        clear-icon="mdi-close-circle"
                        solo
                        hide-details="auto"
                        auto-grow
                        @change="changed('description', $event)"
                        />
                </v-col>
            </v-row>
    </v-form>
</template>
<script>
import Form from '@mixins/form';

import MasterService from '@services/comercio/MasterService';

export default {
    mixins: [Form],
    props: ['master'],
    data: function () {
        return {
            masterList: [],
            isLoadingMasterList: false,
            showMaster: true,
        };
    },
    created: function () {
        this.getMasterList();
    },
    computed: {
        rules: function () {
            return {
                name: [v => !!v || 'ERROR'],
                master_id: this.master ? [] : [v => !!v || 'ERROR'],
                description: [],
                is_active: [],
            };
        },
        defaultFields: function () {
            return {
                name: null,
                description: null,
                master_id: this.master?.id || null,
                is_active: false,
            };
        }
    },
    methods: {
        getMasterList: function () {
            this.isLoadingMasterList = true;

            this.masterList = [];

            MasterService
                .getList({ is_active: true })
                .then(({ masterList }) => {
                    if (masterList.length == 1) {
                        this.showMaster = false;
                        this.fields.master_id = masterList[0].id;
                    }
                    this.masterList = masterList;
                })
                .finally(() => this.isLoadingMasterList = false);
        },
    }
}
</script>