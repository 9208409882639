import axios from '@plugins/axios';

class TicketService {
    get(id, options = {}) {
        return axios({
            url: `ticket/ticket/${id}`,
            method: 'GET',
            ...options
        });
    }
    getBy(filters = {}, options = {}) {
        return axios({
            url: `ticket/ticket/by`,
            method: 'GET',
            params: filters,
            ...options
        });
    }
    getList(context = 'comercio', filters = {}, options = {}) {
        return axios({
            url: `ticket/ticket/${context}`,
            method: 'GET',
            params: filters,
            ...options
        });
    }
    count(filters = {}, options = {}) {
        return axios({
            url: `ticket/ticket`,
            method: 'GET',
            params: filters,
            ...options
        });
    }
    anular(id, options = {}) {
        return axios({
            url: `ticket/ticket/${id}/anular`,
            method: 'POST',
            ...options
        });
    }
}

export const BaseTicketService = TicketService

export default new TicketService();