<template >
    <v-card v-if="ticket">
        <v-card-title>
            <div>
                Ticket #{{ ticket.control }}
                <v-icon :color="{
                    'true': 'success',
                    'false': 'error',
                }[ticket.is_printed]">{{ticket.is_printed ? 'mdi-printer-check' : 'mdi-printer-off'}}</v-icon>
                <p class="text-caption mr-2">{{ ticket.serial  }}</p>
            </div>
            <v-spacer/>
            <v-chip :color="{
                '-1': 'error',
                '0': 'primary',
                '1': 'accent',
                '2': 'success',
            }[ticket.state]">
                Estado: {{ state }}
            </v-chip>
        </v-card-title>
        <v-card-text>
            <v-simple-table v-if="ticket">
                <tbody>
                    <tr>
                        <td>{{ ticket.sistema.name }}</td>
                        <td>Fecha: {{ ticket.created_at | datetime }}</td>
                        <td>Nº Jugadas: {{ ticket.jugada_list.length }}</td>
                    </tr>
                    <tr>
                        <td>Cant. Premios: {{ ticket.winner_count }}</td>
                        <td>Total Premios: {{ ticket.jugada_list.reduce(
                            function($sum, $jugada)
                            { 
                                return $sum + $jugada["premio"] 
                            }, 0) | currency }}
                        </td>
                        <td>Monto Total: {{ ticket.total | currency }}</td>
                    </tr>
                    <tr v-if="ticket.pagado_at || ticket.anulado_at">
                        <td>
                            <span v-if="ticket.state == 2" class="text-left">
                                Pagado por: <strong>{{ ticket.updated_by? ticket.updated_by.username : ticket.taquilla.username }}</strong> - {{ ticket.pagado_at | bdatetime }}
                            </span>
                            <span v-if="ticket.state == -1" class="text-left">
                                Anulado por: <strong>{{ ticket.updated_by? ticket.updated_by.username : ticket.taquilla.username }}</strong> - {{ ticket.anulado_at | bdatetime }}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
        </v-card-text>
        <v-card-text>
            <v-simple-table>
                <thead>
                    <tr>
                        <th>Juego</th>
                        <th>Jugada</th>
                        <th>Monto</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in ticket.jugada_list" :key="item.loteria" :class="item.is_ganador ? 'green' : ''">
                        <td>{{ item.juego.name }} - {{ item.juego_sorteo.horario }}</td>
                        <td><v-chip color="primary">{{ item.numero }}</v-chip></td>
                        <td>{{ item.monto | currency }}</td>
                    </tr>
                </tbody>
            </v-simple-table>
        </v-card-text>
        <v-card-actions>
            <Anular :ticket="ticket"/>
            <v-spacer></v-spacer>
            <span text-right>Monto Total: {{ ticket.total | currency }}</span>
        </v-card-actions>
    </v-card>
</template>

<script>
import TicketService from '@services/ticket/TicketService';
import Anular from '@components/entities/ticket/components/actions/anular';

export default {
    components: {
        Anular,
        
    },
    data: function () {
        return {
            isLoading: false,
            ticket: null,
        }
    },
    created: function () {
        this.getTicket(this.$route.params.id);
        this.$store.dispatch('persist/operadora/set');
    },
    computed: {
        user: function () {
            return this.$store.getters['persist/auth/user'];
        },
        state: function () {
            switch (this.ticket?.state) {
                case -1: return 'Anulado';
                case 0: return 'Vendido';
                case 1: return 'Ganador';
                case 2: return 'Pagado';
                default: return null;
            }
        },
    },
    methods: {
        getTicket: function (ticket_id) {
            this.isLoading = true;
            TicketService
                .get(ticket_id)
                .then(({ ticket }) => {
                    this.ticket = ticket;
                })
                .finally(() => this.isLoading = false)
        }
    }
}
</script>  