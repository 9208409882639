import BaseService from "@utils/BaseService";

import axios from '@plugins/axios';

class UserService extends BaseService {
    get(comercio, comercioId, id, options = {}) {
        return axios({
            url: `comercio/${comercio}/${comercioId}/user/${id}`,
            method: 'GET',
            ...options
        });
    }
    getList(comercio, comercioId, filters = {}, options = {}) {
        return axios({
            url: `comercio/${comercio}/${comercioId}/user`,
            method: 'GET',
            params: this.getParamsProcessed(filters),
            ...options
        });
    }
    count(comercio, comercioId, filters = {}, options = {}) {
        return axios({
            url: `comercio/${comercio}/${comercioId}/user`,
            method: 'GET',
            params: this.getParamsProcessed(filters),
            ...options
        });
    }
    create(comercio, comercioId, data, options = {}) {
        return axios({
            url: `comercio/${comercio}/${comercioId}/user`,
            data: this.getDataEncoded(data),
            method: 'POST',
            ...options
        });
    }
    update(comercio, comercioId, id, data, options = {}) {
        return axios({
            url: `comercio/${comercio}/${comercioId}/user/${id}`,
            data: this.getDataEncoded(data),
            method: 'PUT',
            ...options
        });
    }
    delete(comercio, comercioId, id, options = {}) {
        return axios({
            url: `comercio/${comercio}/${comercioId}/user/${id}`,
            method: 'DELETE',
            ...options
        });
    }
    getDomainList(options = {}) {
        return axios({
            url: `comercio/domain`,
            method: 'GET',
            ...options
        });
    }
}

export const BaseUserService = UserService;

export default new UserService({
    domainName: 'comercio',
    entityName: 'user',
    fields: ['username', 'password', 'is_active', 'domain_id'],
    query:['search', 'slug', 'is_active', 'offset', 'limit'],
});