class BaseConfig {
    get(identifier) {
        return process.env[`VUE_APP_${identifier}`];
    }
}

export default new BaseConfig();

export {
    BaseConfig,
}