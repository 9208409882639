<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <v-card class="mb-3">
                    <v-card-title>Filtros</v-card-title>
                    <v-card-text><ConsolidadoJuegoDivisaSearch :default="params" @search="search($event)"></ConsolidadoJuegoDivisaSearch></v-card-text>
                </v-card>
                <v-card>
                    <v-card-text>
                        <ConsolidadoDivisa :params="params"/>
                        <br/>
                        <ConsolidadoJuegoDivisa :params="params"></ConsolidadoJuegoDivisa>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
    </template>
    <script>
        import ConsolidadoDivisa from '@components/entities/operadora-loteria/reporte/venta/divisa/box';
        import ConsolidadoJuegoDivisa from '@components/entities/operadora-loteria/reporte/venta/juego-divisa';
        import ConsolidadoJuegoDivisaSearch from '@components/entities/operadora-loteria/reporte/venta/juego-divisa/search';
    
        export default {
            components: {
                ConsolidadoDivisa,
                ConsolidadoJuegoDivisa,
                ConsolidadoJuegoDivisaSearch
            },
            props: {
            
            },
            data: function() {
                const query = {... this.$route.query};
    
                for (const key in query) {
                    if (Object.hasOwnProperty.call(query, key)) {
                        const element = query[key];
                        console.log(key, query[key], this.$lodash.isNumber(element));
                        query[key] = /^-?[\d.]+(?:e-?\d+)?$/.test(element) ? Number.parseFloat(element): element;
                    }
                }
    
                return {
                    params: {
                        daterange: [this.$luxon.now().toISODate(),this.$luxon.now().toISODate()],
                        ... query,
                    }
                }
            },
            created: function () {
                this.$store.dispatch('ui/layout/setTitle', 'Consolidada por Divisa');
            },
            computed:{
                
            },
            methods:{
                search: function (params) {
                    this.params = params;
                }
            },
        }
    </script>
    <tyle>
    
    </tyle>