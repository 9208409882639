import CrudService from "@utils/CrudService";

class ComercializadoraService extends CrudService {}

export const BaseComercializadoraService = ComercializadoraService

export default new ComercializadoraService({
    domainName:'comercio',
    entityName:'comercializadora',
    fields: {
        'comercializadora': ['name', 'description', 'phone', 'email', 'master_id', 'is_active'],
        'user': ['username', 'password', 'is_active']
    },
    query:['search', 'slug', 'is_active', 'offset', 'limit', 'master_id'],
});