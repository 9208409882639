<template>
    <v-card flat>
        <v-form>
            <div class="d-flex">
                <div class="mr-1" style="width: 60px">
                    <v-text-field
                        v-model="hour" 
                        outlined dense hide-details
                        type="number"
                        min="0"
                        max="23"
                        />
                    <small>Hora</small>
                </div>
                <div class="mr-1">
                    <h1 class="ma-0" style="position: relative; top: 7px">:</h1>
                </div>
                <div style="width: 60px">
                    <v-text-field
                        v-model="minute"
                        outlined dense hide-details 
                        type="number"
                        min="0"
                        max="59"
                        />
                    <small>Minuto</small>
                </div>
            </div>
        </v-form>
    </v-card>
</template>
<script>
    export default {
        props: ['value'],
        data: function () {
            return {
                hour: 0,
                minute: 0,
            }
        },
        methods: {
            emit: function () {
                this.$emit('input', this.$luxon.fromFormat(`${this.hour}:${this.minute}:0`, 'H:m:s').toFormat('HH:mm:ss'));
            }
        },
        watch: {
            value: function (value, old) {
                if (value == old) return;
                if (value == null) value = "00:00:00";
                this.hour = this.$luxon.fromFormat(value, 'HH:mm:ss').toFormat('H');
                this.minute = this.$luxon.fromFormat(value, 'HH:mm:ss').toFormat('m');
            },
            hour: function (hour, old) {
                if (hour == old) return;
                if (hour > 23) return this.hour = 23;
                if (hour < 0) return this.hour = 0;
                this.emit();
            },
            minute: function (minute, old) {
                if (minute == old) return;
                if (minute > 59) return this.minute = 59;
                if (minute < 0) return this.minute = 0;
                this.emit();
            }
        },
    }
</script>