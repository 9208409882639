<template>
    <div>
        <div class="d-flex">
            <ConsolidarComercio :params="params" :summary="sumary" :loading="loading"/>
            <div class="ml-auto"><v-btn @click="exportToExcel">Exportar a Excel</v-btn></div>
        </div>
        <br/>
        <v-data-table
            :headers="headers"
            :items="list"
            :items-per-page="10"
            :loading="loading"
            show-expand
            item-key="comercio_id"
            single-expand
            @item-expanded="abierto"
            >
            <template v-slot:expanded-item="{ headers }">
                <td :colspan="headers.length" class="pa-2">
                    <v-card outlined color="grey darken-2">
                        <v-card-text class="pa-0">
                            <ConsolidadoJuegoDivisa :context="context" :params="parametros" :nopage="true"></ConsolidadoJuegoDivisa>
                        </v-card-text>
                    </v-card>
                </td>
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.comercio="{ item }">
                <v-btn v-if="context == 'comercio'" class="mr-2" text small @click="open(item, 'window')">
                    <v-icon class="mr-2" small>mdi mdi-eye-outline</v-icon>
                    {{ item.comercio }} 
                </v-btn>
                <template v-else>
                    {{ item.comercio }} 
                </template>
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.venta="{ item }">
                <span font-color="success">{{ item.venta | currency }}</span>
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.comision="{ item }">
                {{ item.comision | currency }}
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.premio="{ item }">
                {{ item.premio | currency }}
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.total="{ item }">
                <v-chip small label :color="item.percent > 0.80 ? 'error': 'primary'" class="font-weight-bold mr-2">
                    {{ item.total | currency(item.divisa_code) }} {{' / '}} {{ item.percent | percent }}
                </v-chip>
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.banqueo="{ item }">
                {{ item.banqueo[0] | currency(item.divisa_code) }} / {{ item.banqueo[1] | currency(item.divisa_code) }}
            </template>
        </v-data-table>
    </div>
</template>

<script>
import VentaService from '@services/reporte/VentaService';
import ConsolidadoJuegoDivisa from '@components/entities/reporte/venta/juego-divisa';
import ConsolidarComercio from '@components/entities/reporte/venta/comercio/box';
import * as excelJS from 'exceljs';
import { saveAs } from "file-saver";

export default {
    components: {
        ConsolidarComercio,
        ConsolidadoJuegoDivisa
    },
    props: {
        params: {
            defaults: function() {
                return {

                };
            },
        },
        context: {
            default: 'comercio',
        },
    },
    data: function() { 
        return {
            list: [],
            sumary: [],
            parametros: {},
            count: 0,
            loading: false,       
        }
    },
    computed: {
        headers: function () {
            return [
                ... this.context == 'sistema' ? [{ text: 'Sistema', align: 'start', value: 'comercio' }] : [{ text: 'Comercio', align: 'start', value: 'comercio' }],
                { text: 'Venta', value: 'venta' },
                { text: 'Comisión', value: 'comision' },
                { text: 'Premio', value: 'premio' },
                { text: 'Total', value: 'total' },
                { text: 'Banqueo', value: 'banqueo' },
            ];
        },
        divisa: function () {
            return this.$store.getters['persist/divisa/selected'];
        },
        user: function () {
            return this.$store.getters['persist/auth/user'];
        },
    },
    mounted: function () {
        this.getList();
    },
    methods: {
        getList: function () { // MEJORAR
            this.list = [];
            this.loading = true;
            const comercio = [];
            if (this.user.comercio_type !== 'operadora') comercio[this.getComType(this.user.comercio_type)] = this.user.comercio_id; 

            const params = { ...this.params, divisa_id: this.divisa.id,  ...comercio};

            if (this.context == 'sistema') {
                params['group_by'] = 'sistema';
                params['comercio_type'] = 'sistema';
            }

            VentaService.reporteConsolidadoPorComercio(params)
                .then(({reportList}) => {
                    this.sumary = reportList.reduce((sumary, item) => { 
                        sumary.venta += item.venta; 
                        sumary.comision += item.comision; 
                        sumary.premio += item.premio; 
                        sumary.total += item.total; 
                        return sumary;
                    }, {venta: 0, comision: 0, premio: 0, porcentaje: 0, total: 0});
                    this.list = reportList; 
                }).finally(() => {
                    this.loading = false;
                });
        },
        /*getListDivisa: function (comercio_id) {
            VentaService.reporteConsolidado({ ...this.params, divisa_id: this.divisa.id, master_id:comercio_id})
                .then(({reportList}) => {
                    console.log('REPORTE LIST', reportList);
                }).finally(() => {
                    this.loading = false;
                });
        },*/
        abierto: function (row) { // MEJORAR
            const comercio = {};
            if (row.value) comercio[this.getComType(row.item.comercio_type)] = row.item.comercio_id;
            const params = {};
            if (this.context == 'sistema') {
                params['group_by'] = 'sistema';
                params['comercio_type'] = 'sistema';
            }
            this.parametros = { ...this.params, divisa_id: this.divisa.id, ...comercio, ...params};
        },
        open: function (reporte, context = 'tab') { // MEJORAR
            const comercio = {};
            if (this.user.comercio_type !== 'operadora') comercio[this.getComType(reporte.comercio_type)] = reporte.comercio_id;
            const params = {
                ... this.params,
                ...comercio,
                ... context == 'window' ? { 'minimal': 1, 'no-layout': 1 }: {},
            };

            if (this.context == 'sistema') {
                params['group_by'] = 'sistema';
                params['comercio_type'] = 'sistema';
            }

            const route = this.$router.resolve({
                name: ({
                    'comercio': 'reporte.consolidado.comercio',
                    'sistema': 'operadora.sistema.reporte.sistema',
                })[this.context],
                query: params,
            });

            if (context == 'tab') return window.open(new URL(route.href, window.location.origin), '_blank').focus();
            if (context == 'window') return window.open(new URL(route.href, window.location.origin), '_blank', 'titlebar=no, status=no, menubar=no, height=600 width=1000');
        },
        getComType: function (comercioType) {
            return {'master': 'master_id', 'comercializadora': 'comercializadora_id', 'grupo': 'grupo_id', 'taquilla': 'taquilla_id', 'sistema': 'sistema_id'}[comercioType];
        },
        exportToExcel () {
            if (this.list.length == 0) return;
            const workbook = new excelJS.Workbook();
            workbook.creator = 'Source 2';
            workbook.lastModifiedBy = 'System';
            workbook.created = new Date();
            let sheet = workbook.addWorksheet("Reporte");

            sheet.getRow(1).values = [
                'Comercio', 
                'Ventas '+ this.divisa.code, 
                'Comision '+ this.divisa.code, 
                'Premios '+ this.divisa.code, 
                'Total '+ this.divisa.code, 
                'BanqueoA',
                'BanqueoB',
            ];
            
            const results = this.list.reduce((sumary, item) => { 
                sumary.ventas += parseFloat(item.venta.toFixed(2)); 
                sumary.comisiones += parseFloat(item.comision.toFixed(2)); 
                sumary.premios += parseFloat(item.premio.toFixed(2)); 
                sumary.totales += parseFloat(item.total.toFixed(2)); 
                sumary.banqueoa += parseFloat(item.banqueo[0].toFixed(2)); 
                sumary.banqueob += parseFloat(item.banqueo[1].toFixed(2)); 
                return sumary;
            }, {ventas: 0, comisiones: 0, premios: 0, totales: 0, banqueoa: 0, banqueob: 0});

            let records = [];
            this.list.forEach(reporte => {
                records.push([
                    reporte.comercio,
                    parseFloat(reporte.venta.toFixed(2)),
                    parseFloat(reporte.comision.toFixed(2)),
                    parseFloat(reporte.premio.toFixed(2)), 
                    parseFloat(reporte.total.toFixed(2)), 
                    parseFloat(reporte.banqueo[0].toFixed(2)),
                    parseFloat(reporte.banqueo[1].toFixed(2))  
                ]);
            });
            sheet.addRows(records);
            const rmax = this.list.length + 2;
            sheet.getCell('B' + rmax).value = { formula: `SUM(B3:B${rmax})`, result: results.ventas };
            sheet.getCell('C' + rmax).value = { formula: `SUM(C3:C${rmax})`, result: results.comisiones };
            sheet.getCell('D' + rmax).value = { formula: `SUM(D3:D${rmax})`, result: results.premios };
            sheet.getCell('E' + rmax).value = { formula: `SUM(E3:E${rmax})`, result: results.totales };
            sheet.getCell('F' + rmax).value = { formula: `SUM(F3:F${rmax})`, result: results.banqueoa };
            sheet.getCell('G' + rmax).value = { formula: `SUM(G3:G${rmax})`, result: results.banqueob };
            
            sheet.getColumn(1).alignment = {
                vertical: "middle",
                horizontal: "center",
            };
            sheet.getRow(1).font  = {
                bold: true
            };
            /*Object.keys(sheet.getColumn(2).values).filter(x => x % 2 === 0).map(key => {
                sheet.getRow(key).fill = {
                    type: 'pattern',
                    pattern:'solid',
                    fgColor:{argb:'cccccc'}
                };
            });*/

            sheet.columns.forEach(column => {
                const lengths = column.values.map(v => v.toString().length);
                const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
                column.width = maxLength + 2;
            });

            const tittle = 'Source 2 Reporte de Ventas por Comercio desde ' 
                + this.params.daterange[0].split("-").reverse().join("-")
                + ' hasta '
                + this.params.daterange[1].split("-").reverse().join("-");

            sheet.insertRow(1, [tittle]);
            sheet.mergeCells("A1:G1");

            sheet.getCell('A1').fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb:'c9daf8'}
            };

            sheet.addConditionalFormatting({
                ref: 'A2:G' + rmax,
                rules: [
                    {
                    type: 'expression',
                    formulae: ['MOD(ROW(),2)=0'],
                    style: {fill: {type: 'pattern', pattern: 'solid', bgColor: {argb: 'cccccc'}}},
                    }
                ]
            });

            const filename = 'Reporte Comercio ' + this.$luxon.now().toFormat('MM-dd HHmmss') + '.xlsx';
            workbook.xlsx.writeBuffer().then(function(buffer) {
                const blob = new Blob([buffer], { type: "applicationi/xlsx" });
                saveAs(blob, filename);
            });
        }
    },
    watch: { 
        'params': function () {
            this.getList();
        },
        'divisa': function () {
            this.getList();
        }
    },
}
</script>
<style>

</style>