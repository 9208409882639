export default {
    props: ['value', 'loading', 'default', 'context'],
    data: function () {
        return {
            valid: false,
            fields: this.getFields(),
        };
    },
    created: function () {
        this.fields = this.getFields();
    },
    mounted: function () {
        this.emit();
    },
    methods: {
        getData: function () {
            const defaults = { ...this.getDefaults() };
            let data = {};
            for (const property in this.fields) {
                if (Object.hasOwnProperty.call(this.fields, property)) {
                    if (defaults[property] != this.fields[property]) data[property] = this.fields[property];
                    if (this.context && this.context === 'new' && !Object.hasOwnProperty.call(data, property)) data[property] = this.fields[property]; 
                }
            }
            if (this.customData) data = this.customData(data);
            return data;
        },
        emit: function () {
            const data = this.getData();
            this.$emit('input', data);
            this.$emit('update:valid', this.valid && !!Object.keys(data).length);
        },
        changed: function (field,$evt) {
            if (this.onChange) this.onChange(field,$evt);
            this.emit();
        },
        getDefaults: function () {
            return { ...(this.defaultFields), ...(this.default || {}) };
        },
        getFields: function () {
            const fields = this.getDefaults();
            if (this.$refs['form']) this.$refs['form'].resetValidation();
            return fields;
        }
    },
    watch: {
        default: {
            handler: function () {
                this.fields = this.getFields();
            },
            deep: true,
        },
        valid: function () {
            this.emit();
        }
    }
}