<template>
    <div>
        <form>
          <h3>Perfil de Usuario</h3>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="username"
              label="Nombre de Usuario"
              :loading="loading"
              :disabled="loading"
              outlined
              required
            ></v-text-field>
          </v-col>
          <v-col v-if="comercioType" cols="12" sm="6">
            <v-text-field
              v-model="comercioType"
              label="Tipo de Comercio"
              :loading="loading"
              disabled
              outlined
            ></v-text-field>
          </v-col>
          <v-checkbox v-model="setPass" label="¿Cambiar contreña?"></v-checkbox>
          <v-col v-if="setPass" cols="12" sm="6">
            <v-text-field
              v-model="password"
              type="password"
              label="Enter Password"
              :loading="loading"
              :disabled="loading"
              outlined
            ></v-text-field>
            <v-text-field
              v-model="rePassword"
              type="password"
              label="Re-enter Password"
              :rules="[passConfirmation]"
              :loading="loading"
              :disabled="loading"
              outlined
            ></v-text-field>
          </v-col>
          <v-btn
            class="ma-4"
            :loading="loading"
            :disabled="loading"
            color="secondary"
            @click="updateProfile"
          >Guardar cambios</v-btn>
        </form>
    </div>
  </template>
  <script>
    import UserService from '@services/comercio/UserService';
  
    export default {
      data: function () {
          return {
            defaultFields: {
              //password: null,
            },
            loading: false,
            password: '',
            rePassword: '',
            setPass: false,
            username: null,
            domain: null
          }
      },
      created: function(){
        this.username = this.user.username;
        this.comercioType = this.user.domain.slug;
        this.$store.dispatch('ui/layout/setTitle', 'Configuración de Perfil');
      },
      computed: {
        user: function () {
          return this.$store.getters['persist/auth/user'];
        },
        passConfirmation() {
          return () => (this.password === this.rePassword) || 'Contraseñas deben ser iguales'
        }
      },
      methods: {
        updateProfile() {
          this.loading = true;
          const data = {};
          if (this.setPass) data['password'] = this.password;
          if (this.username !== this.user.username) data['usarname'] = this.username;
          console.log(data);
          UserService.update(this.user.domain.slug, this.user.comercio_id, this.user.id, data).then(({ user }) => {
              console.log('Profile Updated', user);
              this.$ui.notificate({message: 'Perfil actualizado con éxito', color:'success'});
          }).catch(() => {
              this.$ui.notificate({message: 'Error al actualizar', color: 'error'});
          }).finally(() => this.loading = false);
        } 
      },
    }
  </script>
  