<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" md="8">
                <v-card>
                    <v-card-title>
                        <v-icon>mdi-account</v-icon>
                        {{ user.owner.name }}
                    </v-card-title>
                    <v-card-subtitle>
                        Lotería
                    </v-card-subtitle>
                    <v-card-text>
                        <div>
                            <div v-if="isJuegoListLoading">
                                Cargando...
                            </div>
                            <div v-else>
                                <div class="d-flex flex-wrap">
                                    <JuegoCardLimited 
                                        v-for="(juego, index) of juegoList" 
                                        :key="`operadora-loteria-juego-item-${index}-${juego.id}`" 
                                        :juego="juego"
                                        class="mr-2 mb-2 flex-grow-1"
                                        />
                                </div>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="4">
                <v-card>
                    <v-card-title>
                        <v-icon>mdi-account</v-icon>
                        {{ user.username }}
                        <v-spacer/>
                        <UserModalLimited :user="user"/>
                    </v-card-title>
                    <v-card-subtitle>
                        Usuario
                    </v-card-subtitle>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import JuegoService from '@/services/operadora-loteria/JuegoService';

import UserModalLimited from '@/components/entities/general/user/modal-limited';
import JuegoCardLimited from '@/components/entities/loteria/juego/card-limited';

export default {
    components: {
        UserModalLimited,
        JuegoCardLimited
    },
    props: {
        user: {},
    },
    data: function () {
        return {
            juegoList: [],
            isJuegoListLoading: false,
        }
    },
    methods: {
        getJuegoList: function () {
            this.isJuegoListLoading = true
            JuegoService.getList()
                .then(({ juegoList }) => {
                    this.juegoList = juegoList;
                })
                .finally(() => {
                    this.isJuegoListLoading = false;
                })
        }
    },
    created: function () {
        this.getJuegoList();
    }
}
</script>