<template>
<div>
    <v-list dense nav v-for="(menu, index) in menuList" :key="`${owner}-menu-group-${index}`">
        <v-subheader>{{ menu.title }}</v-subheader>
        <MenuItem :items="menu.items" :owner="`${owner}-menu-group-${index}`"/>
    </v-list>
</div>
</template>
<script>
import MenuItem from './item.vue';

export default {
    components: {
        MenuItem
    },
    props: {
        'menuList': Array, 
        'owner': {
            type: String,
            default: 'default',
        }
    },
    data: function () {
        return {
            
        };
    },
    computed: {
    },
}
</script>