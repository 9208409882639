import BaseService from "@utils/BaseService";
import axios from '@plugins/axios';

class DivisaService extends BaseService {
    get(id, options = {}) {
        return axios({
            url: this.getRoute(id),
            method: 'GET',
            ...options
        });
    }
    getBy(filters = {}, options = {}) {
        return axios({
            url: this.getRoute('by'),
            method: 'GET',
            params: this.getParamsProcessed({is_active: true, ...filters}),
            ...options
        });
    }
    getList(filters = {}, options = {}) {
        return axios({
            url: this.getRoute(),
            method: 'GET',
            params: this.getParamsProcessed({is_active: true, ...filters}),
            ...options
        });
    }
    count(filters = {}, options = {}) {
        return axios({
            url: this.getRoute(),
            method: 'GET',
            params: this.getParamsProcessed({is_active: true, ...filters}),
            ...options
        });
    }
    getDefault(options = {}) {
        return axios({
            url: this.getRoute('default'),
            method: 'GET',
            params: this.getParamsProcessed(),
            ...options
        });
    }
    getForCupoMaster(masterId, params = {}, options = {}) {
        return axios({
            url: this.getRoute(`for-cupo-master/master/${masterId}`),
            method: 'GET',
            params: this.getParamsProcessed({...params}),
            ...options
        });
    }
    getForCupoComercializadora(comercializadoraId, params = {}, options = {}) {
        return axios({
            url: this.getRoute(`for-cupo-comercializadora/comercializadora/${comercializadoraId}`),
            method: 'GET',
            params: this.getParamsProcessed({...params}),
            ...options
        });
    }
    getForCupoGrupo(grupoId, params = {}, options = {}) {
        return axios({
            url: this.getRoute(`for-cupo-grupo/grupo/${grupoId}`),
            method: 'GET',
            params: this.getParamsProcessed({...params}),
            ...options
        });
    }
    getForCupoTaquilla(taquillaId, params = {}, options = {}) {
        return axios({
            url: this.getRoute(`for-cupo-taquilla/taquilla/${taquillaId}`),
            method: 'GET',
            params: this.getParamsProcessed({...params}),
            ...options
        });
    }
}

export const BaseDivisaService = DivisaService;

export default new DivisaService({
    domainName:'general',
    entityName:'divisa',
    query:['search', 'is_active', 'offset', 'limit', 'juego_id', 'loteria_id', 'is_default'],
});