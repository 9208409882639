import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import GeneralRoutes from './routes/general';
import OperadoraRoutes from './routes/operadora';
import ComercioRoutes from './routes/comercio';
import TicketsRoutes from './routes/tickets';
import ReporteRoutes from './routes/reporte';
import UsuariosRoutes from './routes/usuarios';
import ProfileRoutes from './routes/profile';
import BloqueoRoutes from './routes/bloqueo';
import loteriaOperadoraRoutes from './routes/operadora-loteria';

const routes = [].concat(
  GeneralRoutes,
  OperadoraRoutes,
  ComercioRoutes,
  TicketsRoutes,
  ReporteRoutes,
  UsuariosRoutes,
  ProfileRoutes,
  BloqueoRoutes,
  loteriaOperadoraRoutes
);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

import store from '@stores';

router.beforeEach((to, from, next) => {
  const user = store.getters['persist/auth/user'];
  
  const isPublic = to.meta?.public !== undefined ? to.meta?.public : false;
  const isStrict = to.meta?.strict !== undefined ? to.meta?.strict : true;

  const domain = to.meta?.domain;

  const home = 'dashboard';
  const login = 'login';
  const notFound = 'not-found';

  if (isPublic) {
    if (isStrict) {
      if (user) {
        return next({name: home});
      }
    }
  } else {
    if (!user) {
      return next({name: login});
    }
  }

  if (user && domain) {
    if (user.domain.slug !== 'operadora') {
      if (Array.isArray(domain)) {
        if (!domain.find((dom) => dom == user.domain.slug)) {
          return next({name: notFound});
        }
      } else {
        if (domain !== user.domain.slug) {
          return next({name: notFound});
        }
      }
    }
  }

  return next();
});

const DEFAULT_TITLE = 'Source';
router.beforeEach((to, from, next) => {
  document.title = DEFAULT_TITLE;
  next();
});

export default router
