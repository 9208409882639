import Master from '@pages/comercio/master';
import MasterView from '@pages/comercio/master/view';

import Comercializadora from '@pages/comercio/comercializadora';
import ComercializadoraView from '@pages/comercio/comercializadora/view';

import Grupo from '@pages/comercio/grupo';
import GrupoView from '@pages/comercio/grupo/view';

import Taquilla from '@pages/comercio/taquilla';
import TaquillaView from '@pages/comercio/taquilla/view';

export default [
    {
        path: '/comercio/master',
        name: 'comercio.master',
        component: Master,
    },
    {
        path: '/comercio/master/:id',
        name: 'comercio.master.view',
        component: MasterView,
    },
    {
        path: '/comercio/comercializadora',
        name: 'comercio.comercializadora',
        component: Comercializadora,
    },
    {
        path: '/comercio/comercializadora/:id',
        name: 'comercio.comercializadora.view',
        component: ComercializadoraView,
    },
    {
        path: '/comercio/grupo',
        name: 'comercio.grupo',
        component: Grupo,
    },
    {
        path: '/comercio/grupo/:id',
        name: 'comercio.grupo.view',
        component: GrupoView,
    },
    {
        path: '/comercio/taquilla',
        name: 'comercio.taquilla',
        component: Taquilla,
    },
    {
        path: '/comercio/taquilla/:id',
        name: 'comercio.taquilla.view',
        component: TaquillaView,
    },
];
