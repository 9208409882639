<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <v-btn color="primary" @click="add()"><v-icon>mdi-account-box</v-icon>Añadir</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card elevation="2">
                    <v-card-title>
                        <UserSearch v-model="params" @search="getList()"/>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table
                            :headers="headers"
                            :items="list"
                            :options.sync="options"
                            :server-items-length="count"
                            :loading="loading"
                            >
                            <!-- eslint-disable-next-line -->
                            <template v-slot:item.is_active="{ item }">
                                <div>
                                    <v-chip v-if="item.is_active" color="success" small>Activo</v-chip>
                                    <v-chip v-else color="error" small>Inactivo</v-chip>
                                </div>
                            </template>
                            <!-- eslint-disable-next-line -->
                            <template v-slot:item.actions="{ item }">
                                <div>
                                    <v-btn color="secondary" icon @click="edit(item)">
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-btn>
                                </div>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card> 
            </v-col>
        </v-row>
        <UserModal :comercio-type="comercioType" :comercio-id="comercioId" :open.sync="isModalOpen" @saved="getList()" :user="item"/>
    </v-container>
</template>

<script>
import UserService from '@services/comercio/UserService';

import UserSearch from './search';
import UserModal from './modal';

export default {
    components: {
        UserSearch,
        UserModal
    },
    props: ['comercioType','comercioId'],
    data: () => ({
        headers: [
            {
                text: 'Nombre de Usuario',
                value: 'username',
            },
            {
                text: 'Estado',
                value: 'is_active',
                align: 'center',
                width: 150
            },
            {
                text: 'Acciones',
                value: 'actions',
                align: 'right',
                width: 200
            },
        ],
        options: {},
        loading: false,
        list: [],
        count: 0,

        isModalOpen: false,
        item: null,

        params: {}
    }),
    created: function () {
    },
    methods: {
        getList: function (params = {}) {
            this.loading = true;
            UserService.getList(this.comercioType, this.comercioId,{...this.params, ...params})
                .then(({userList, userCount}) => {
                    this.list = userList;
                    this.count = userCount;
                }).finally(() => {
                    this.loading = false;
                });
        },
        add: function () {
            this.item = null;
            this.isModalOpen = true;
        },
        edit: function (item) {
            this.item = item;
            this.isModalOpen = true;
        }
    },
    watch: {
        options: {
            handler: function (options) {
                const itemsPerPage = options.itemsPerPage !== -1 ? options.itemsPerPage : null;
                const offset =  itemsPerPage !== null ? (options.page * itemsPerPage) - itemsPerPage : null;

                const params = {};
                if (itemsPerPage !== null) params['limit'] = itemsPerPage;
                if (offset !== null) params['offset'] = offset;
                
                this.getList(params);
            },
            deep: true,
        },
    },
}
</script>
    
    <style>
    
    </style>