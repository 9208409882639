import { v4 as uuid } from 'uuid';

export default {
    namespaced: true,
    state: () => ({
        notificationList: [],
    }),
    getters: {
        get: state => state.notificationList,
    },
    mutations: {
        add: (state, notification) => {
            state.notificationList.push(notification);
        },
        remove: (state, uid) => {
            const notificationIndex = state.notificationList.findIndex(notification => notification.uid === uid);
            if (notificationIndex === -1) return;
            state.notificationList.splice(notificationIndex, 1);
        }
    },
    actions: {
        add: ({ commit }, notification) => {
            commit('add', {
                message: "",
                uid: uuid(),
                color: "primary",
                closed: () => null,
                ...notification
            });
        },
        remove: ({ commit }, uid) => {
            commit('remove', uid);
        },
    }
}