import { DateTime } from "luxon";
import { Duration } from "luxon";
import { Interval } from "luxon";

export const $luxon = DateTime;
export const $luxonduration = Duration;
export const $luxonInterval = Interval;

export default {
    install : function (Vue) {
        Vue.prototype['$luxon'] = DateTime;
        Vue.prototype['$luxonduration'] = Duration;
        Vue.prototype['$luxonInterval'] = Interval;
    }
}