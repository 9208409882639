import axios from '@plugins/axios';
import CrudService from "@utils/CrudService";

class CupoJuegoService extends CrudService {
    activate(id, options = {}) {
        return axios({
            url: this.getRoute(`${id}/activate`),
            method: 'POST',
            ...options
        });
    }
    deactivate(id, options = {}) {
        return axios({
            url: this.getRoute(`${id}/deactivate`),
            method: 'POST',
            ...options
        });
    }
}

export const BaseCupoJuegoService = CupoJuegoService

export default new CupoJuegoService({
    domainName:'operadora/loteria',
    entityName:'cupo-juego',
    fields: ['cupo_jugada', 'min_apuesta', 'max_apuesta', 'comision', 'banqueo', 'juego_id', 'divisa_id'],
    query:['juego_id', 'divisa_id'],
});