<template>
    <v-form ref="form" v-model="valid">
        <v-row dense>
            <v-col cols="12">
                Nombre de Usuario
                <v-text-field
                    v-model="fields.username"
                    :rules="rules.username"
                    :disabled="loading"
                    label="Nombre de Usuario"
                    hide-details="auto"
                    @change="changed('username')"
                    required
                    filled
                    />
            </v-col>
            <v-col cols="12">
                Contraseña
                <PasswordInput
                    v-model="fields.password"
                    :rules="rules.password"
                    :disabled="loading"
                    label="Contraseña"
                    @change="changed('password')"
                    />
            </v-col>
            <v-col cols="12" v-if="fields.password">
                Repetir Contraseña
                <PasswordInput
                    v-model="fields.repeat_password"
                    :rules="rules.repeat_password"
                    :disabled="loading"
                    label="Contraseña"
                    @change="changed('repeat_password')"
                    />
            </v-col>
        </v-row>
    </v-form>
</template>
<script>
import Form from '@mixins/form';

export default {
    mixins: [Form],
    props: ['value', 'loading', 'default'],
    data: function () {
        return {
            defaultFields: {
                username: null,
                password: null,
                repeat_password: null,
            }
        };
    },
    computed: {
        rules: function () {
            return {
                username: [
                    v => !!v || 'Username no puede estar vacío',
                    v => (v && v.length > 6) || 'Username muy corto, mínimo 6 carácteres'
                ],
                password: [
                    v => (!v || v.length > 6) || 'Contraseña muy corta, mínimo 6 carácteres'
                ],
                repeat_password: [
                    ... this.fields.password ? [
                        v => this.$lodash.isEqual(v, this.fields.password) || 'Las contraseñas no coinciden'
                    ]: [],
                ]
            };
        }
    }
}
</script>