<template>
    <div>
        <div class="mb-2">
            <v-card>
                <v-card-title>
                    Ventas Por Figura
                </v-card-title>
                <!-- <v-card-text>
                    <div class="d-flex align-center mb-2" v-if="params.juego_sorteo_id">
                        <span class="mr-4">SORTEO</span>
                        <Totalizador :loading="loading" :summary="summarySorteo"/>
                    </div>
                </v-card-text> -->
            </v-card>
        </div>
        <v-simple-table>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th>Numero</th>
                        <th>Tickets</th>
                        <th>1 Acierto</th>
                        <th>2 Aciertos</th>
                        <th>Ganadores</th>
                        <th>Comision</th>
                        <th>Proyección</th>
                        <th>Total</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(report, index) of list" :key="`reporte-figura-tripleta-${index}`">
                        <td>
                            <v-avatar size="25" class="mr-2">
                                <img
                                    :src="report.image"
                                    :alt="report.numero"
                                    />
                            </v-avatar>
                            {{ report.numero }} - {{ report.label }}
                        </td>
                        <td>
                            <v-chip label color="primary">
                                <v-icon class="mr-2" small>mdi mdi-receipt-text</v-icon>
                                <span>{{ report.count }}</span>
                                <span class="ml-2">{{ report.venta_total | currency(report.code) }}</span>
                            </v-chip>
                        </td>
                        <td>
                            <v-chip label>
                                <v-icon class="mr-2" small>mdi mdi-receipt-text</v-icon>
                                <span>{{ report.one_hit_count }}</span>
                                <span class="ml-2">{{ report.one_hit_venta | currency(report.code) }}</span>
                            </v-chip>
                        </td>
                        <td>
                            <v-chip label>
                                <v-icon class="mr-2" small>mdi mdi-receipt-text</v-icon>
                                <span>{{ report.two_hit_count }}</span>
                                <span class="ml-2">{{ report.two_hit_venta | currency(report.code) }}</span>
                            </v-chip>
                        </td>
                        <td>
                            <v-chip label color="amber darken-4">
                                <v-icon class="mr-2" small>mdi mdi-receipt-text</v-icon>
                                <span>{{ report.winner_count }}</span>
                                <span class="ml-2">{{ report.venta | currency(report.code) }}</span>
                            </v-chip>
                        </td>
                        <td>{{ report.comision | currency(report.code) }}</td>
                        <td>{{ report.projected | currency(report.code) }}</td>
                        <td>{{ report.total_projected | currency(report.code) }}</td>
                        <td></td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </div>
</template>

<script>
import TripletaService from '@services/reporte/game/TripletaService';

export default {
    components: {
    },
    props: {
        params: {
            defaults: function() {
                return {

                };
            },
        },
    },
    data: function() { 
        return {
            list: [],
            count: 0,
            loading: true,  
            loadingDaySummary: true,
            daySummary: null,
            loadingWeekSummary: true,
            weekSummary: null,
            ventas: 0,
            comision: 0,
            subtotal: 0,
            premio: 0,
            total: 0,
            total_projected: 0,
            projected: 0,
        }
    },
    computed: {
        divisa: function () {
            return this.$store.getters['persist/divisa/selected'];
        },
        listComputed: function () {
            return this.list.map(report => {
                // let color = 'secondary';
                // if (report.percent_projected > 0.1) color = 'primary';
                // if (report.percent_projected < 0.1 && report.percent_projected > -0.1) color = 'secondary';
                // if (report.percent_projected < -0.1) color = 'error';

                // const sorteoTotal = this.subtotal - (report.projected);
                // const sorteoPercent = sorteoTotal / this.subtotal;

                // let sorteoColor = 'secondary';
                // if (sorteoPercent > 0.1) sorteoColor = 'primary';
                // if (sorteoPercent < 0.1 && sorteoPercent > -0.1) sorteoColor = 'secondary';
                // if (sorteoPercent < -0.1) sorteoColor = 'error';

                // let premioColor = 'secondary';
                // let premioPercent = 0;
                // if (report.projected) {
                //     premioPercent = report.projected / this.ventas;
                //     if (premioPercent <= 0) premioColor = 'primary';
                //     else premioColor = 'error';
                // }

                // let dayColor = 'secondary';
                // let dayTotal = 0;
                // let dayPercent = 0;

                // if (this.daySummary) {
                //     dayTotal = this.daySummary.total - (report.total_projected);
                //     dayPercent = this.daySummary.total / dayTotal;

                //     if (dayPercent > 0.1) dayColor = 'primary';
                //     if (dayPercent < 0.1 && dayPercent > -0.1) dayColor = 'secondary';
                //     if (dayPercent < -0.1) dayColor = 'error';
                // }

                // let weekColor = 'secondary';
                // let weekTotal = 0;
                // let weekPercent = 0;

                // if (this.weekSummary) {
                //     weekTotal = this.weekSummary.total - (report.total_projected);
                //     weekPercent = this.weekSummary.total / weekTotal;

                //     if (weekPercent > 0.1) weekColor = 'primary';
                //     if (weekPercent < 0.1 && weekPercent > -0.1) weekColor = 'secondary';
                //     if (weekPercent < -0.1) weekColor = 'error';
                // }

                return {
                    ...report,
                    // color,
                    // sorteoColor,
                    // sorteoTotal,
                    // sorteoPercent,
                    // premioColor,
                    // premioPercent,
                    // dayColor,
                    // dayTotal,
                    // dayPercent,
                    // weekColor,
                    // weekTotal,
                    // weekPercent,
                }
            })
        },
        summarySorteo: function () {
            return {
                venta: this.ventas,
                comision: this.comision,
                premio: this.premio,
                total: this.total,
            }
        }
    },
    methods: {
        getLegibleDistanciaFecha: function (date) {
            if (!date) return 0;

            const start = this.$luxon.fromSQL(date);
            const end = this.$luxon.now();

            const days = Math.floor(end.diff(start, 'days').toObject().days);

            return days;
        },
        getList: function (params = {}) {
            this.list = [];
            this.loading = true;
            this.ventas = 0;
            this.comision = 0;
            this.premio = 0;
            this.total = 0;
            this.subtotal = 0;
            TripletaService.reportePorFiguraConsolidado({...this.params, ...params, divisa_id: this.divisa.id})
                .then(({reportList}) => {
                    reportList.forEach(reporte => {
                        this.ventas += reporte.venta;
                        this.comision += reporte.comision;
                        this.premio += reporte.premio;
                        this.projected += reporte.projected;
                        this.total += reporte.total;
                        this.total_projected += reporte.total_projected;
                    });
                    this.subtotal = this.ventas - this.comision;
                    this.list = reportList;
                }).finally(() => {
                    this.loading = false;
                });
        },
    },
    watch: { 
        'params': function () {
            this.getList();
            
        },
        'divisa': function () {
            this.getList();
            
        }
    },
    mounted: function () {
        this.getList();
       
    }
}
</script>
<style>

</style>