<template>
    <v-form ref="form" v-model="valid">
        <v-row dense>
            <v-col cols="12" sm="12" md="6">
                Nombre
                <v-text-field
                    v-model="fields.name"
                    :rules="rules.name"
                    @change="changed('name', $event)"
                    :disabled="loading"
                    label="Nombre"
                    filled
                    hide-details="auto"
                    required
                    dense
                    />
            </v-col>
            <v-col cols="12" sm="12" md="4">
                Nombre Corto
                <v-text-field
                    v-model="fields.shortname"
                    :rules="rules.shortname"
                    @change="changed('shortname', $event)"
                    :loading="loading"
                    :disabled="loading"
                    label="Nombre Corto"
                    filled
                    hide-details="auto"
                    required
                    dense
                    />
            </v-col>
            <v-col cols="12" sm="4" md="2">
                Estado
                <v-switch
                    v-model="fields.is_active"
                    :rules="rules.is_active"
                    @change="changed('is_active', $event)"
                    :loading="loading"
                    :disabled="loading"
                    inset
                    hide-details="auto"
                    color="primary"
                    dense
                    />
            </v-col>
            <v-col cols="12">
                Descripción
                <v-textarea
                    v-model="fields.description"
                    :rules="rules.description"
                    @change="changed('description', $event)"
                    :loading="loading"
                    :disabled="loading"
                    label="Descripción"
                    rows="5"
                    clearable
                    clear-icon="mdi-close-circle"
                    filled
                    hide-details="auto"
                    auto-grow
                    dense
                    />
            </v-col>
        </v-row>
    </v-form>
</template>
<script>
import Form from '@mixins/form';

export default {
    mixins: [Form],
    data: function () {
        return {
            rules: {
                name: [v => !!v || 'Debes colocar un nombre de loteria',],
                shortname: [v => !!v || 'Debes colocar un nombre corto de loteria',],
                is_active: [],
                description: [],
            },
            defaultFields: {
                name: '',
                shortname: '',
                is_active: true,
                description: '',
            }
        };
    },
}
</script>
