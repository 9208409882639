<template>
	<v-card outlined>
		<v-card-title>Selector Comercios</v-card-title>
		<v-divider/>
		<v-card-text>
			<v-treeview
				v-model="model"
				:load-children="fetch"
				:items="items"
				selected-color="indigo"
				selectable
				return-object
				expand-icon="mdi-chevron-down"
				/>
		</v-card-text>
		<v-divider/>
		<v-card-actions>
			<v-btn text @click="model = []">Reset</v-btn>
		</v-card-actions>
	</v-card>
</template>
<script>
import JuegoService from '@/services/loteria/JuegoService';
import MasterService from '@/services/comercio/MasterService';
import ComercializadoraService from '@/services/comercio/ComercializadoraService';
import GrupoService from '@/services/comercio/GrupoService';
import TaquillaService from '@/services/comercio/TaquillaService';

const levels = ['juego', 'master', 'comercializadora', 'grupo', 'taquilla'];

const levelServices = {
	'juego': JuegoService,
	'master': MasterService,
	'comercializadora': ComercializadoraService,
	'grupo': GrupoService,
	'taquilla': TaquillaService
}

const getLevelService = function (level) {
	return levelServices[level];
}

const makeChildren =  (id, level, foreing, name, withchild = true) => ({
	id,
	level,
	foreing,
	name,
	... (withchild) ? {children: []}:{},
})

export default {
	props: {
		value: {},
	},
	data: () => ({
		items: [makeChildren(null, 'juego', null, 'Todos')],
		isLoading: false,
		model: [],
		types: [],
	}),
	methods: {
		async fetch (item) {
			const indexLevel = levels.indexOf(item.level);
			const nextIndex = indexLevel + 1;
			const nextLevel = levels[nextIndex];

			const Service = getLevelService(nextLevel);

			const params = {};

			if (item.level != 'juego') params[`${item.level}_id`] = item.id.split(':')[1];

			const response = await Service.getList(params);
			
			const list = response[`${nextLevel}List`];

			list.forEach((comercio) => {
				item.children.push(
					makeChildren(`${nextLevel}:${comercio.id}`, nextLevel, comercio.id, comercio.name, indexLevel != levels.length - 2)
				);
			});
		},
	},
	watch: {
		value: {
			deep: true,
			handler: function (value) {
				if (this.$lodash.isEqual(this.model, value)) return;
				this.model = value;
			}
		},
		model: {
			deep: true,
			handler: function (value) {
				if (this.$lodash.isEqual(this.value, value)) return;
				this.$emit('input', value);
			}
		}
	}
}
</script>