<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <v-card elevation="2">
                    <v-card-title>
                        <div class="d-flex align-center justify-space-between" style="width: 100%">
                            <div>
                                {{ count }} Figuras Bloqueadas
                            </div>
                            <div>
                                <v-btn color="primary" @click="add()"><v-icon>mdi-account-box</v-icon> AÑADIR BLOQUEO</v-btn>
                            </div>
                        </div>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table
                            :items="list"
                            :headers="headers"
                            :loading="loading"
                        >
                            <!-- eslint-disable-next-line -->
                            <template v-slot:item.note="{ item }">
                                <div>
                                    <v-chip v-if="item.created_by" small label><v-icon>mdi-account</v-icon>{{ item.created_by.username }}</v-chip>
                                </div>
                            </template>
                            <!-- eslint-disable-next-line -->
                            <template v-slot:item.actions="{ item }">
                                <div>
                                    <v-btn v-if="item.is_active" icon @click="desbloquear(item)">
                                        <v-icon>mdi-shield-alert-outline</v-icon>
                                    </v-btn>
                                </div>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card> 
            </v-col>
        </v-row>
        <BloqueoModal :open.sync="isModalOpen" :saved.sync="isSaved"/>
    </v-container>
    </template>
<script>
    //import UserList from '@components/entities/usuario';
    import BloqueoService from '@services/loteria/BloqueoFiguraService';
    import BloqueoModal from '@components/entities/loteria/bloqueo-figura/modal';

    export default {
        components: {
            //UserList,
            BloqueoModal
        },
        props: {
        
        },
        data: function() {
            return {
                headers: [
                    { text: 'Juego', align: 'start', value: 'juego.name' },
                    { text: 'Numero', value: 'numero' },
                    { text: 'Sorteo', value: 'juego_sorteo.horario' },
                    { text: 'Divisa', value: 'divisa.name' },
                    { text: 'Observación', value: 'note', width: 100},
                    { text: 'Acciones', value: 'actions', align: 'right', width: 150 },
                ],
                loading: false,
                list: [],
                count: 0,
                isModalOpen: false,
                isSaved: false,
                item: null,
            }
        },
        created: function () {
            this.$store.dispatch('ui/layout/setTitle', 'Bloqueo de Figuras');
            this.getBloqueos();
        },
        methods:{
            add: function () {
                this.item = null;
                this.isModalOpen = true;
            },
            desbloquear: function (item) {
                this.$ui.confirm({
                    message: `Anular el bloqueo del ${item?.numero} Sorteo ${item?.juego_sorteo.horario}`,
                    textConfirm: 'Desbloquear',
                    textCancel: 'Cancelar',
                    confirm: () => {
                        BloqueoService.update(item.id, {is_active: false}).then(() => {
                            this.$ui.notificate({message: 'BLOQUEO ANULADO'});
                        })
                        .catch(() => this.$ui.notificate({message: 'Error guardando grupo', color: 'error'}))
                        .finally(() => this.getBloqueos());
                    },
                });
            },
            getBloqueos: function(){
                this.loading = true;
                this.list = [];
                BloqueoService
                    .getList({is_active: true, fecha: this.$luxon.local().toISODate()})
                    .then(({ bloqueoFiguraList }) => {
                        this.list = bloqueoFiguraList;
                        this.count = this.list.length;
                    })
                    .finally(() => this.loading = false);
            }
        },
        watch: {
            isSaved: function (saved) {
                if (saved) this.getBloqueos();
            }
        }
    }
</script>
<tyle>

</tyle>