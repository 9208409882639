import axios from '@plugins/axios';

class VentaService {
    reporteDetallado(filters = {}, options = {}) {
        if (Object.hasOwnProperty.call(filters, 'consolidado')) {
            filters['consolidado'] = filters['consolidado'] ? 1 : 0;         
        }

        return axios({
            url: `reporte/venta/detallado`,
            method: 'GET',
            params: filters,
            ...options
        });
    }
    reporteConsolidado(filters = {}, options = {}) {
        if (Object.hasOwnProperty.call(filters, 'consolidado')) {
            filters['consolidado'] = filters['consolidado'] ? 1 : 0;         
        }

        return axios({
            url: `reporte/venta/consolidado`,
            method: 'GET',
            params: filters,
            ...options
        });
    }
    reporteConsolidadoPorComercio(filters = {}, options = {}) {
        return axios({
            url: `reporte/venta/comercio/consolidado`,
            method: 'GET',
            params: filters,
            ...options
        });
    }
    
    reporteConsolidadoPorDivisa(filters = {}, options = {}) {
        return axios({
            url: `reporte/venta/divisa/consolidado`,
            method: 'GET',
            params: filters,
            ...options
        });
    }
    reportePorSorteoDetallado(filters = {}, options = {}) {
        return axios({
            url: `reporte/venta/sorteo/detallado`,
            method: 'GET',
            params: filters,
            ...options
        });
    }
    reportePorSorteoConsolidado(filters = {}, options = {}) {
        return axios({
            url: `reporte/venta/sorteo/consolidado`,
            method: 'GET',
            params: filters,
            ...options
        });
    }
    reportePorFiguraDetallado(filters = {}, options = {}) {
        if (Object.hasOwnProperty.call(filters, 'consolidado')) {
            filters['consolidado'] = filters['consolidado'] ? 1 : 0;         
        }

        return axios({
            url: `reporte/venta/figura/detallado`,
            method: 'GET',
            params: filters,
            ...options
        });
    }
    reportePorFiguraConsolidado(filters = {}, options = {}) {
        if (Object.hasOwnProperty.call(filters, 'consolidado')) {
            filters['consolidado'] = filters['consolidado'] ? 1 : 0;         
        }

        return axios({
            url: `reporte/venta/figura/consolidado`,
            method: 'GET',
            params: filters,
            ...options
        });
    }
}

export const BaseVentaService = VentaService

export default new VentaService();