<template>
    <v-dialog
      v-model="dialog"
      width="600"
    >
        <v-card
        >
            <v-card-title class="primary white--text" dark>New User</v-card-title>
            <v-card-text class="pa-3">
                <user-form :user="user" ref="user-form" v-model="valid"/>
            </v-card-text>
            <v-divider/>
            <v-card-actions>
                <v-spacer/>
                <v-btn 
                    color="primary" 
                    :disabled="!valid || loading"
                    :loading="loading"
                    @click="submit()"
                    >
                    <v-icon>mdi-content-save</v-icon>
                    SAVE
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import UserForm from "@components/entities/general/user/form"

import UserService from '@services/operadora/UserService';

export default {
    components: {
        'user-form': UserForm,
    },
    data: () => ({
        dialog: false,
        loading: false,
        user: null,
        valid: false,
        cb: null
    }),
    methods: {
        newUser: function (cb) {
            this.cb = cb;
            this.user = null;
            this.openDialog()
        },
        editUser: function (user, cb) {
            this.cb = cb;
            this.user = user;
            this.openDialog();
        },
        openDialog: function () {
            this.dialog = true;
        },
        closeDialog: function () {
            this.dialog = false;
        },
        submit: function () {
            this.loading = true;
            const data = this.$refs['user-form'].getData();
            let action = null;
            if (this.user?.id) action = UserService.update(this.user.id,data);
            else action = UserService.create(data);
            action.then(({user}) => {
                if (this.cb) this.cb(user);
                this.closeDialog();
            })
            .catch(() => {
            })
            .finally(() => this.loading = false);
        },
    }
}
</script>

<style>

</style>