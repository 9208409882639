import Home from '@pages/home';
import Login from '@pages/login';
import FourZeroFour from '@pages/404';

import Dashboard from '@pages/dashboard';

export default [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: { public: true, strict: true }
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: { public: true, strict: true }
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
    },
    {
        path: '/not-found',
        name: 'not-found',
        component: FourZeroFour
    },
    {
        path: '*',
        name: '404',
        component: FourZeroFour
    }
];