<template>
<div class="flex-grow-1 d-flex">
    
    <v-card outlined tile class="flex-grow-1">
        <v-card-title>
            <h3 v-if="juego && !isJuegoLoading">{{juego.name}}</h3>
            <h3 v-if="isJuegoLoading">Cargando...</h3>
            <v-spacer/>
            <v-tooltip left :disabled="hasActiveCupoJuego">
                <template v-slot:activator="{ on, attrs }">
                    <span 
                        v-bind="attrs"
                        v-on="on">
                        <v-switch 
                            class="ma-0"
                            v-if="juego"
                            v-model="juego.is_active" 
                            :loading="isActiveLoading || isJuegoLoading"
                            :disabled="isActiveLoading || !hasActiveCupoJuego || isJuegoLoading"
                            :label="`${juego.is_active?'Activo' : 'Inactivo'}`"
                            inset
                            hide-details="auto"
                            color="primary"
                            @change="setJuegoIsActive($event, juego)"
                        />
                    </span>
                </template>
                <span>Necesitas Divisas Activas</span>
            </v-tooltip>
            <slot name="upper-actions" v-bind:juego="juego" v-bind:isJuegoLoading="isJuegoLoading"></slot>
        </v-card-title>
        <v-card-text v-if="juego">
            <p v-if="juego.description">{{juego.description}}</p>
            <Sorteo :juego="juego"/>
            <CupoCrud context="juego" :divisaList="divisaList" :owner="{
                loteria: loteria.id,
                juego_id: juego_id
            }" :hasLimit="true"/>
        </v-card-text>
    </v-card>
</div>
</template>
<script>
import CupoJuegoService from '@services/operadora/loteria/CupoJuegoService';
import JuegoService from '@services/operadora/loteria/JuegoService';
import DivisaService from '@services/operadora/general/DivisaService';

import Sorteo from './sorteo';

import CupoCrud from '@components/entities/comercio/cupo/crud';

export default {
    components: {
        Sorteo,
        CupoCrud
    },
    props: ['loteria', 'juego_id'],
    data: () => ({
        isActiveLoading: false,
        
        cupoJuegoList: [],
        divisaList: [],
        
        isCupoJuegoLoading: false,
        cupoJuego: null,
        
        juego: null,
        isJuegoLoading: false,
    }),
    mounted: function () {
        this.getJuego();
        this.getDivisaList();
    },
    computed: {
        filteredDivisaList: function () {
            if (this.cupoJuego){
                return this.divisaList.filter(divisa => this.cupoJuegoList.find(cupoJuego => cupoJuego.divisa_id == divisa.id));
            }
            return this.divisaList.filter(divisa => !this.cupoJuegoList.find(cupoJuego => cupoJuego.divisa_id == divisa.id));
        },
        hasActiveCupoJuego: function () {
            return true;
        }
    },
    methods: {
        getJuego: function () {
            this.juego = null;
            this.isJuegoLoading = true;
            JuegoService.get(this.juego_id).then(({ juego }) => {
                this.juego = juego;
                this.getCupoJuegoList();
            }).finally(() => {
                this.isJuegoLoading = false;
            });
        },
        getCupoJuegoList: function () {
            this.cupoJuegoList = [];
            if (!this.juego) return;

            this.isCupoJuegoLoading = true;
            
            CupoJuegoService.getList({juego_id: this.juego.id}).then(({ cupoJuegoList = [] }) => {
                this.cupoJuegoList = cupoJuegoList;
            }).finally(() => this.isCupoJuegoLoading = false);
        },
        getDivisaList: function () {
            this.divisaList = [];
            DivisaService.getList().then(({ divisaList }) => {
                this.divisaList = divisaList;
            });
        },
        setJuegoIsActive: function (value, juego){
            if (value) {
                this.isActiveLoading = true;
                JuegoService
                    .activate(juego.id)
                    .then(({juego}) =>  {
                        if (juego)
                            this.$ui.notificate({message: 'Activado', color:'success'});
                        else{
                            this.juego.is_active = !value;
                            this.$ui.notificate({message: 'No puede ser activado', color:'error'});
                        }
                    })
                    .finally(() => this.isActiveLoading = false);
            } else {
                JuegoService
                    .deactivate(juego.id)
                    .then(() =>  this.$ui.notificate({message: 'Desactivado', color:'success'}))
                    .finally(() => this.isActiveLoading = false);
            }
            this.getCupoJuegoList();
        },
        cupoJuegoSaved: function (cupoJuego) {
            this.juego = cupoJuego.juego;
            const cupoJuegoIndex = this.cupoJuegoList.findIndex(_cupoJuego => _cupoJuego.id == cupoJuego.id);
            if (cupoJuegoIndex !== -1) this.cupoJuegoList[cupoJuegoIndex].is_active = cupoJuego.is_active
        }
    },
    watch: {
        juego_id: function (juego_id) {
            this.getJuego(juego_id);
        },
    }
}
</script>