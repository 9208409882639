import axios from '@plugins/axios';

class BloqueoFiguraService {
    getList(params = {}, options = {}) {
        return axios({
            url: `ventana/admin/bloqueo`,
            method: 'GET',
            params,
            ...options
        });
    }
    create(data, options = {}) {
        return axios({
            url: `ventana/admin/bloqueo`,
            data,
            method: 'POST',
            ...options
        });
    }
    delete(id, options = {}) {
        return axios({
            url: `ventana/admin/bloqueo/${id}`,
            method: 'DELETE',
            ...options
        });
    }
}

export const BaseBloqueoFiguraService = BloqueoFiguraService

export default new BloqueoFiguraService();