<template>
    <v-form ref="form" v-model="valid">
        <v-container fluid>
            <v-row>
                <v-col cols="12" sm="12" md="8">
                    Nombre
                    <v-text-field
                        v-model="fields.name"
                        :rules="rules.name"
                        @change="changed('name')"
                        :disabled="loading"
                        label="Nombre"
                        solo
                        hide-details="auto"
                        />
                </v-col>
                <v-col cols="12" sm="12" md="4">
                    Tipo Juego
                    <v-select
                        v-model="fields.tipo_juego_id"
                        :rules="rules.tipo_juego_id"
                        @change="changed('tipo_juego_id')"
                        :items="tipoJuegoList"
                        item-text="name"
                        item-value="id"
                        label="Tipo Juego"
                        hide-details="auto"
                        solo
                        :loading="isTipoJuegoLoading"
                        :disabled="isTipoJuegoLoading || loading"
                        >
                    </v-select>
                </v-col>
                <v-col cols="12" sm="12" v-if="context == 'new' && newG">
                    Loteria
                    <v-select
                        v-model="fields.loteria_id"
                        :rules="rules.loteria_id"
                        @change="changed('loteria_id')"
                        :items="loteriaList"
                        item-text="name"
                        item-value="id"
                        label="Loteria"
                        hide-details="auto"
                        solo
                        :loading="isLoteriaLoading"
                        :disabled="isLoteriaLoading || loading"
                        >
                    </v-select>
                </v-col>
                <v-col cols="12">
                    Max. Tiempo de Juego
                    <v-text-field
                        v-model="fields.max_tiempo_jugada"
                        :rules="rules.max_tiempo_jugada"
                        @change="changed('max_tiempo_jugada')"
                        :disabled="loading"
                        label="Minutos"
                        solo
                        type="number"
                        min="0"
                        max="10"
                        hide-details="auto"
                        />
                </v-col>
                <v-col cols="12">
                    Descripción
                    <v-textarea
                        v-model="fields.description"
                        :rules="rules.description"
                        @change="changed('description')"
                        :disabled="loading"
                        label="Descripción"
                        rows="5"
                        clearable
                        clear-icon="mdi-close-circle"
                        solo
                        hide-details="auto"
                        auto-grow
                        />
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
import TipoJuegoService from '@services/operadora/loteria/TipoJuegoService';
import LoteriaService from '@services/operadora/loteria/LoteriaService';

import Form from '@mixins/form';

export default {
    mixins: [Form],
    props: ['juego','value', 'loading', 'context', 'newG'],    
    data: () => ({
        rules: {
            name: [v => !!v || 'Debes colocar un nombre para el juego'],
            tipo_juego_id: [v => !!v || 'Debes seleccionar un tipo de juego'],
            loteria_id: [v => !!v || 'Debes seleccionar una loteria'],
            max_tiempo_jugada: [v => v > 0 && v < 10 || 'Fuera de Rango'],
            description: [],
        },

        defaultFields: {
            name: null,
            loteria_id: null,
            tipo_juego_id: null,
            description: null,
            max_tiempo_jugada: null,
        },

        tipoJuegoList: [],
        isTipoJuegoLoading: false,

        loteriaList: [],
        isLoteriaLoading: false,
    }),
    created: function () {
        // REFACTOR
        this.getTipoJuego();
        this.getLoteria();
    },
    methods: {
        getTipoJuego() {
            this.isTipoJuegoLoading = true;
            TipoJuegoService.getList({ is_active: true })
                .then(({ tipoJuegoList }) => {
                    this.tipoJuegoList = tipoJuegoList;
                }).finally(() => {
                    this.isTipoJuegoLoading = false;
                });
        },
        getLoteria() {
            this.isLoteriaLoading = true;
            LoteriaService.getList({ is_active: true })
                .then(({ loteriaList }) => {
                    this.loteriaList = loteriaList;
                }).finally(() => {
                    this.isLoteriaLoading = false;
                });
        },
    },
}
</script>

<style>

</style>