import store from '@stores';

export class BaseUI {
    constructor($store) {
        this.$store = $store;
    }
    confirm(confirm) {
        this.$store.dispatch('ui/confirm/set', confirm);
    }
    removeConfirm(confirm) {
        this.$store.dispatch('ui/confirm/remove', confirm);
    }
    notificate(notificate) {
        this.$store.dispatch('ui/notification/add', notificate);
    }
    removeNotification(uid) {
        this.$store.dispatch('ui/notification/remove', uid);
    }
}

export default {
    install: function (Vue) {
        Vue.prototype['$ui'] = new BaseUI(store);
    }
}