import BaseService from './BaseService';

import axios from '@plugins/axios';

export default class CrudService extends BaseService {
    get(id, options = {}) {
        return axios({
            url: this.getRoute(id),
            method: 'GET',
            ...options
        });
    }
    getBy(params = {}, options = {}) {
        return axios({
            url: this.getRoute('by'),
            method: 'GET',
            params,
            ...options
        });
    }
    getList(params = {}, options = {}) {
        return axios({
            url: this.getRoute(),
            method: 'GET',
            params,
            ...options
        });
    }
    count(params = {}, options = {}) {
        return axios({
            url: this.getRoute('count'),
            method: 'GET',
            params,
            ...options
        });
    }
    create(data, options = {}) {
        return axios({
            url: this.getRoute(),
            data: this.getDataEncoded(data),
            method: 'POST',
            ...options
        });
    }
    update(id, data, options = {}) {
        return axios({
            url: this.getRoute(id),
            data: this.getDataEncoded(data),
            method: 'PUT',
            ...options
        });
    }
    delete(id, options = {}) {
        return axios({
            method: 'DELETE',
            url: this.getRoute(id),
            ...options
        });
    }
}