<template>
<v-app >
    <component :is="layout">
        <slot></slot>
    </component>
</v-app>
</template>
<script>
import PublicLayout from '@components/layout/public';
import MainLayout from '@components/layout/main';
import MinimalLayout from '@components/layout/minimal';

export default {
    components: {
        PublicLayout,
        MainLayout,
        MinimalLayout,
    },
    data: () => ({

    }),
    computed: {
        layout() {
            if (!this.$store.getters['persist/auth/isLogged']) return 'public-layout';
            if (this.$route.query['no-layout']) return 'public-layout';
            if (this.$route.query['minimal-layout']) return 'minimal-layout';
            return `main-layout`;
        },
    },
};
</script>
<style>

</style>