<template>
    <v-form v-model="valid" ref="form" style="padding: 1rem">
        Usuario
        <v-text-field
            v-model="username"
            :loading="loading"
            :disabled="loading"
            :rules="usernameRules"
            @keyup.enter="submit()"
            label="Ingrese nombre de usuario"
            solo
            required
            autocomplete="username"
        >
        <v-icon
            slot="append"
            color="primary"
            >
            mdi-account
            </v-icon>
        </v-text-field>
        Contraseña
        <v-text-field
            label="Ingrese Contraseña"
            :loading="loading"
            :disabled="loading"
            v-model="password"
            :rules="passwordRules"
            @keyup.enter="submit()"
            :append-icon="pass ? 'mdi-eye-off' : 'mdi-eye'"
            required
            solo
            @click:append="pass = !pass"
            :type="pass ? 'password' : 'text'"
        >
        </v-text-field>
    </v-form>
</template>

<script>
export default {
    props: ['value', 'loading', 'errors'],
    data: function () {
        return {
        valid: false,
        pass: true,
        username: '',
        usernameRules: [
            v => !!v || "Usuario Requerido",
        ],
        password: '',
        passwordRules: [
            v => !!v || "Contraseña Requerida",
        ],
    }},
    methods: {
        getData: function () {
            return {
                username: this.username,
                password: this.password
            }
        },
        submit: function () {
            if (this.$refs['form'].validate()) this.$emit('submit');
        }
    },
    watch: {
        valid: function (value) {
            this.$emit('input', value);
        },
    }
}
</script>

<style>

</style>