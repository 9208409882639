<template>
    <div class="d-flex">
        <v-card v-for="reporte in list" :key="reporte.divisa" elevation="2" class="mr-2">
            <v-card-title>{{ reporte.divisa }}</v-card-title>
            <v-card-text>
                <v-chip label class="mr-2" :color="color(reporte.venta)">V.: {{ reporte.venta | currency(reporte.divisa_code) }}</v-chip>
                <v-chip label class="mr-2" :color="color(reporte.comision)">C.: {{ reporte.comision | currency(reporte.divisa_code) }}</v-chip>
                <v-chip label class="mr-2" :color="color(reporte.premio)">P.: {{ reporte.premio | currency(reporte.divisa_code) }}</v-chip>
                <v-chip label :color="color(reporte.total)">T.: {{ reporte.total | currency(reporte.divisa_code) }}</v-chip>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import VentaService from '@services/reporte/VentaService';

export default {
    components: {

    },
    props: {
        params: {
            defaults: function() {
                return {};
            },
        }
    },
    data: function() { 
        return {
            list: [],
            loading: false,
        }
    },
    computed: {
        user: function () {
            return this.$store.getters['persist/auth/user'];
        },
    },
    mounted: function () {
        this.getList();
    },
    methods: {
        getList: function () {
            this.list = [];
            this.loading = true;
            const comercio = [];
            if (this.user.comercio_type !== 'operadora') comercio[this.getComType(this.user.comercio_type)] = this.user.comercio_id; 
            VentaService.reporteConsolidadoPorDivisa({ ...this.params, ...comercio })
                .then(({reportList}) => {
                    this.list = reportList;
                }).finally(() => {
                    this.loading = false;
                });
        },
        color: function (value) {
            if (value < 0)
                return 'error';
            else if (value > 0)
                return 'primary';
            else
                return '';
        },
        getComType: function (comercioType) {
            return {'master': 'master_id', 'comercializadora': 'comercializadora_id', 'grupo': 'grupo_id', 'taquilla': 'taquilla_id'}[comercioType];
        },
    },
    watch: { 
        'params': function () {
            this.getList();
        },
    },
}
</script>
<style>

</style>