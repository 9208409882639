<template>
    <v-btn v-if="canAnular" small color="primary" :loading="loading" :disabled="loading" @click="anular(ticket)"><v-icon small>mdi-text-box-remove</v-icon>Anular</v-btn>
</template>
<script>
import TicketService from '@services/ticket/TicketService';

export default {
    components: {
    },
    props: ['ticket'],
    data: function () {
        return {
            loading: false,
        }
    },
    computed: {
        domain: function () {
            return this.$store.getters['persist/auth/domain'];
        },
        timeLimit: function () {
            return this.$store.getters['persist/operadora/timeLimit'];
        },
        currentDateTime: function () {
            return this.$store.getters['ui/currentDateTime'];
        },
        canAnular: function () {
            if (this.ticket.is_anulado) return false;
            if (this.ticket.is_pagado) return false;

            if (this.domain != 'operadora') return false;

            return true;
            
            // if (!this.timeLimit) return false;
            // const duration = this.$luxonduration.fromISOTime(this.timeLimit);
            // const created_at = this.$luxon.fromISO(this.ticket.created_at).plus(duration);
            // if (created_at.diff(this.currentDateTime) < 0) return false;
        },
    },
    methods: {
        anular: function () {
            this.$ui.confirm({
                message: `Anular el Ticket ${this.ticket.control}`,
                textConfirm: 'Anular Ticket',
                textCancel: 'Cancelar',
                confirm: () => {
                    this.anularTicket(this.ticket);
                },
            });
        },
        anularTicket: function (ticket) {
            this.loading = true;
            TicketService.anular(ticket.id)
                .then(({ticket}) => {
                    this.$emit('anulado', ticket);
                    this.$ui.notificate({message: 'Ticket anulado'});
                }).catch(() => {
                    this.$ui.notificate({message: 'Error anulando', color: 'error'});
                }).finally(() => {
                    this.loading = false;
                });
        },
    },
}
</script>