import ReporteDivisa from '@pages/operadora-loteria/reporte/divisa';
import ReporteFigura from '@pages/operadora-loteria/reporte/figura';
import ReporteGeneral from '@pages/operadora-loteria/reporte/general';

export default [
    {
        path: '/loteria/reporte/general',
        name: 'operadora-loteria.reporte.general',
        component: ReporteGeneral,
    },
    {
        path: '/loteria/reporte/figura',
        name: 'operadora-loteria.reporte.figura',
        component: ReporteFigura,
    },
    {
        path: '/loteria/reporte/divisa',
        name: 'operadora-loteria.reporte.consolidado.divisa',
        component: ReporteDivisa,
    },
];
