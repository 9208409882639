<template>
    <v-form ref="form" v-model="valid">
        <v-row dense>
            <v-col cols="12" sm="8">
                Nombre de Usuario
                <v-text-field
                    v-model="fields.username"
                    :rules="rules.username"
                    :disabled="loading"
                    label="Nombre de Usuario"
                    solo
                    hide-details="auto"
                    @change="changed('username')"
                    required
                    />
            </v-col>
            <v-col cols="12" sm="4">
                <v-switch
                    v-model="fields.is_active"
                    :rules="rules.is_active"
                    :disabled="loading"
                    :label="fields.is_active ? 'Usuario Activo' : 'Usuario Inactivo'"
                    inset
                    hide-details="auto"
                    color="primary"
                    @change="changed('is_active')"
                    required
                    />
            </v-col>
            <v-col cols="12">
                Contraseña
                <v-text-field
                    v-model="fields.password"
                    type="password"
                    :rules="rules.password"
                    :disabled="loading"
                    autocomplete="new-password"
                    label="Contraseña"
                    clearable
                    solo
                    hide-details="auto"
                    @change="changed('password')"
                    />
            </v-col>
        </v-row>
    </v-form>
</template>
<script>
import Form from '@mixins/form';

export default {
    mixins: [Form],
    props: ['value', 'loading', 'default'],
    data: function () {
        return {
            defaultFields: {
                username: null,
                password: null,
                is_active: true,
            }
        };
    },
    computed: {
        rules: function () {
            return {
                username: [v => !!v || 'ERROR'],
                password: this.default?.username ? []: [v => !!v || 'ERROR'],
                is_active: [],
            };
        }
    }
}
</script>