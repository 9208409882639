import axios from '@plugins/axios';

import BaseService from "@utils/BaseService";

export default new (class AuthService extends BaseService {
    get() {
        return axios({
            url: this.getRoute(),
            method: 'GET',
        });
    }
    login(credentials) {
        return axios({
            url: this.getRoute('login'),
            method: 'POST',
            data: this.getDataEncoded(credentials),
        });
    }
    logout() {
        return axios({
            url: this.getRoute('logout'),
            method: 'POST',
        });
    }
    update(data) {
        return axios({
            url: this.getRoute('update'),
            method: 'PUT',
            data,
        });
    }
})({
    domainName:'auth',
    fields:['username', 'password'],
});