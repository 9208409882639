<template>
    <div>
        <div v-if="sistemaLoading">
            Cargando...
        </div>
        <div v-else>
            <SistemaCard v-for="(sistema, index) of sistemaList" :key="`sistema-list-${index}-${sistema.id}`" :sistema="sistema"/>
        </div>
    </div>
</template>
<script>
import SistemaService from '@/services/operadora/comercio/sistema/SistemaService';

import SistemaCard from './card';

export default {
    components: {
        SistemaCard,
    },
    props: {
        params: {},
    },
    data: () => ({
        sistemaList: [],
        sistemaLoading: false,
    }),
    methods: {
        getList: function (params) {
            this.sistemaLoading = true;
            SistemaService
                .getList(params)
                .then(({ sistemaList }) => {
                    this.sistemaList = sistemaList;
                }).finally(() => {
                    this.sistemaLoading = false;
                });
        },
    },
    created: function () {
        this.getList(this.params);
    },
    watch: {
        params: {
            handler: function (params) {
                this.getList(params);
            },
            deep: true,
        }
    }
}
</script>