<template>
    <v-form ref="form" style="width: 100%" v-model="valid">
        <div class="d-flex align-center mb-3">
            <div class="mr-3">
                <DateRangeInput v-model="fields.daterange" @change="changed('daterange', $event)" label="Rango de Fechas"/>
            </div>
            <div class="mr-3">
                <SelectorJuego v-model="fields.juego_id" @change="changed('juego_id', $event)" filled/>
            </div>
            <div class="mr-3">
                <v-select
                    :items="juegoSorteoList"
                    :loading="isLoadingJuegoSorteoList"
                    :disabled="isLoadingJuegoSorteoList || !this.fields.juego_id"
                    item-text="horario"
                    item-value="id"
                    label="Sorteo"
                    @change="changed('juego_sorteo_id', $event)"
                    v-model="fields.juego_sorteo_id"
                    hide-details
                    filled
                    />
            </div>
        </div>
        <v-divider class="my-4" />
        <div class="d-flex">
            <v-spacer/>
            <v-btn text class="mr-3" :disabled="loading" @click="reset()">Reiniciar Filtros</v-btn>
            <v-btn color="primary" :disabled="loading" :loading="loading" @click="search()"><v-icon>mdi-magnify</v-icon>Buscar</v-btn>
        </div>
    </v-form>
    
</template>

<script>
import Search from '@mixins/search';

import JuegoSorteoService from '@services/loteria/JuegoSorteoService';

export default {
    props: ['value', 'loading'],
    mixins: [Search],
    data: function ()  {
        return {
            juegoSorteoList: [],
            isLoadingJuegoSorteoList: false,

            defaultFields: {
                daterange: [this.$luxon.local().toISODate(), this.$luxon.local().toISODate()],

                juego_id: null,
                juego_sorteo_id: null,
            },
        }
    },
    computed: {
    },
    created: function () {
    },
    mounted: function () {
        this.preloadLists();
    },
    methods: {
        getJuegoSorteoList: function (parent_id = null) {
            const juego_id = this.fields.juego_id || parent_id
            this.juegoSorteoList = [];
            if (!juego_id) return;
            this.isLoadingJuegoSorteoList = true;
            JuegoSorteoService.getList({ juego_id })
                .then(({ juegoSorteoList }) => {
                    this.juegoSorteoList = [
                        {
                            horario: 'Todos',
                            id: null,
                        },
                        ...juegoSorteoList
                    ];
                })
                .finally(() => this.isLoadingJuegoSorteoList = false);
        },
        preloadLists: function () {
            const defaults = this.default;
            if (defaults.juego_id) {
                this.getJuegoSorteoList();
            }
        },
        onChange: function (field) {
            switch(field) {
                case 'juego_id':
                    this.fields.juego_sorteo_id = null;
                break;
            }
        }
    },
    watch: {
        'fields.juego_id': function (juego_id) {
            if (juego_id !== null) this.getJuegoSorteoList();
        },
    }
}
</script>

<style>

</style>