import BaseService from "@utils/BaseService";
import axios from '@plugins/axios';

class JuegoSorteoService extends BaseService {
    get(id, options = {}) {
        return axios({
            url: this.getRoute(id),
            method: 'GET',
            ...options
        });
    }
    getList(filters = {}, options = {}) {
        return axios({
            url: this.getRoute(),
            method: 'GET',
            params: this.getParamsProcessed({is_active: true, ...filters}),
            ...options
        });
    }
    count(filters = {}, options = {}) {
        return axios({
            url: this.getRoute(), 
            method: 'GET',
            params: this.getParamsProcessed({is_active: true, ...filters}),
            ...options
        });
    }
}

export const BaseJuegoSorteoService = JuegoSorteoService;

export default new JuegoSorteoService({
    domainName:'loteria',
    entityName:'juego-sorteo',
    query:['juego_id', 'is_active'],
});