<template>
    <v-form ref="form" v-model="valid">
        <v-container fluid>
            <v-row dense>
                <v-col cols="12">
                    Juego
                    <SelectorJuego 
                        v-model="fields.juego_id" 
                        :rules="rules.juego_id" 
                        @change="changed('juego_id', $event)" 
                        filled
                        />
                </v-col>  
                <v-col cols="12">
                    Sorteo
                    <SelectorJuegoSorteo
                        v-model="fields.juego_sorteo_id" 
                        :params="{ juego_id: fields.juego_id }"
                        :rules="rules.juego_sorteo_id" 
                        @change="changed('juego_sorteo_id', $event)"
                        :disabled="!fields.juego_id"
                        filled
                        />
                </v-col>   
                <v-col cols="12">
                    Divisa
                    <v-select
                        :items="divisaList"
                        :loading="isLoadingDivisaList"
                        :disabled="isLoadingDivisaList"
                        :rules="rules.divisa_id"
                        item-text="name"
                        item-value="id"
                        @change="changed('divisa_id', $event)"
                        v-model="fields.divisa_id"
                        hide-details="auto"
                        filled
                        >
                    </v-select>
                </v-col>
                <v-col cols="12">
                    Número
                    <v-text-field
                        v-model="fields.numero"
                        :rules="rules.numero"
                        :disabled="loading"
                        label="Número/Bloqueo"
                        filled
                        hide-details="auto"
                        @change="changed('numero')"
                        required
                        />
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>
<script>
import Form from '@mixins/form';
import DivisaService from '@services/general/DivisaService';

const defaultFields = {
    juego_id: null,
    juego_sorteo_id: null,
    numero: null,
    divisa_id: null,
}

export default {
    mixins: [Form],
    props: ['value', 'loading', 'default'],
    data: function () {
        return {
            divisaList: [],
            isLoadingDivisaList: false,

            fields: defaultFields,
        };
    },
    created: function () {
        this.getDivisaList();
    },
    computed: {
        rules: function () {
            return {
                juego_id: [v => !!v || 'Debe selecionar un Juego'],
                divisa_id: [v => !!v || 'Debe selecionar una Divisa'],
                juego_sorteo_id: [v => !!v || 'Debe selecionar un Sorteo'],
                numero: [v => !!v || 'Debe ingresar el número a bloquear'],
            };
        },
        currentDateTime: function () {
            return this.$store.getters['ui/currentDateTime'];
        },
    },
    methods: {
        getDivisaList: function () {
            this.divisaList = [];
            DivisaService.getList().then(({ divisaList }) => {
                this.divisaList = divisaList;
            });
        },
        getDefaultFields: function () {
            if (this.$refs['form']) this.$refs['form'].resetValidation();
            return defaultFields; 
        },
        emit: function () {
            const data = { ...this.value };
            for (const property in this.fields) {
                if (Object.hasOwnProperty.call(this.fields, property)) {
                    if (!Object.hasOwnProperty.call(data, property) || data[property] != this.fields[property]) data[property] = this.fields[property];
                }
            }
            this.$emit('input', data);
        },
    },
    watch: {
        value: function (value) {
            const newValue = (value && {...this.getDefaultFields(), ...value}) || this.getDefaultFields();
            this.fields = newValue;
            this.$emit('update:valid', this.valid);
        },
        valid: function (valid) {
            this.$emit('update:valid', valid);
        }
    }
}
</script>