<template>
    <v-card 
        :loading="loading"
        elevation="0"
        :color="system.is_active ? 'grey darken-4': ''"
        tile
        class="mb-4"
        >
        <v-card-title>
            {{ system.name }}
            <v-spacer/>
            <v-switch v-model="is_active" :loading="loading" :disabled="loading" hide-details="" class="ma-0"/>
            <Modal :sistema="system" @saved="system = $event"/>
        </v-card-title>
        <v-card-subtitle v-if="system.description">{{ system.description }}</v-card-subtitle>
        <v-card-text>
            <v-card outlined class="mb-2">
                <v-card-text class="pa-2">
                    <div class="d-flex align-center">
                        <small class="caption mr-2">TOKEN</small>
                        <code>
                            <template v-if="system.token">{{ system.token }} <v-btn x-small icon @click="copy(system.token)"><v-icon x-small>mdi-content-copy</v-icon></v-btn></template>
                            <template v-else>SIN TOKEN</template>
                        </code>
                        <v-spacer/>
                        <v-btn small @click="renewToken()" :loading="loading" :disabled="loading">RENOVAR TOKEN</v-btn>
                    </div>
                </v-card-text>
            </v-card>
            <v-card outlined>
                <v-card-title>
                    <small>Juegos</small>
                    <v-spacer/>
                    <CupoModal :sistema="sistema"/>
                </v-card-title>
                <v-card-text>
                    <Cupo :sistema="sistema"/>
                </v-card-text>
            </v-card>
        </v-card-text>
    </v-card>
</template>
<script>
import SistemaService from '@/services/operadora/comercio/sistema/SistemaService';

import Modal from './modal';
import CupoModal from './cupo/modal';
import Cupo from './cupo';

export default {
    components: {
        Modal,
        CupoModal,
        Cupo
    },
    props: {
        sistema: {
            required: true
        }
    },
    data: function () {
        return {
            system: this.sistema,
            is_active: this.sistema.is_active,
            loading: false,
        }
    },
    methods: {
        setIsActive(is_active) {
            this.loading = true;
            SistemaService
                .update(this.system.id, { is_active })
                .then(({ sistema }) => {
                    this.system = sistema;
                }).finally(() => {
                    this.loading = false;
                });
        },
        renewToken() {
            this.$ui.confirm({
                message: `Confirmar renofación de token: ${this.sistema.name}`,
                textConfirm: 'RENOVAR',
                textCancel: 'CANCELAR',
                confirm: () => {
                    this.loading = true;
                    SistemaService
                        .renewToken(this.system.id)
                        .then(({ sistema }) => {
                            this.system = sistema;
                        }).finally(() => {
                            this.loading = false;
                        });
                },
            });
        },
        async copy(text) {
            try {
                await navigator.clipboard.writeText(text);
            } catch (e) {
                console.error(e);
            }
        }
    },
    watch: {
        is_active: function (value) {
            this.setIsActive(value);
        }
    }
}
</script>