<template>
  <v-container fluid>
    <h1>Source</h1>
  </v-container>
</template>
<script>

export default {
  components: {
      
  },
  created: function () {
    this.$store.dispatch('ui/layout/setTitle', '');
  },
}
</script>
