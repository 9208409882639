<template>
<v-container fluid>
    <v-row>
        <v-col>
            <v-card class="mb-3" v-if="!minimal">
                <v-card-title>Filtros</v-card-title>
                <v-card-text><GeneralSearch :default="params" @search="search($event)"></GeneralSearch></v-card-text>
            </v-card>
            <v-card>
                <v-card-text>
                    <component :is="reporte" :params="params"></component>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</v-container>
</template>
<script>
    import GeneralSearch from '@components/entities/reporte/venta/general/search';
    
    import Detallado from '@components/entities/reporte/venta/general/detallado';
    import Consolidado from '@components/entities/reporte/venta/general/consolidado';
    import TicketList from '@components/entities/ticket';

    export default {
        components: {
            GeneralSearch,
            Detallado,
            Consolidado,
            TicketList
        },
        props: {
        
        },
        data: function() {
            const query = {... this.$route.query};

            for (const key in query) {
                if (Object.hasOwnProperty.call(query, key)) {
                    const element = query[key];
                    query[key] = /^-?[\d.]+(?:e-?\d+)?$/.test(element) ? Number.parseFloat(element): element;
                }
            }

            return {
                params: {
                    daterange: [this.$luxon.now().toISODate(),this.$luxon.now().toISODate()],
                    ... query,
                },
                minimal: query['minimal'],
            }
        },
        created: function () {
            this.$store.dispatch('ui/layout/setTitle', 'Ventas Generales');
        },
        computed:{
            reporte: function() {
                if (this.params?.taquilla_id) return 'TicketList';
                if (this.params?.juego_sorteo_id) return 'Detallado';
                return 'Consolidado';    
            },
        },
        methods:{
            search: function (params) {
                this.params = params;
            }
        },
    }
</script>
<tyle>

</tyle>