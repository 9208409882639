<template>
    <div v-if="notificationList">
        <Notification 
            v-for="(notification, notificationIndex) in notificationList"
            :key="`notification-${notificationIndex}-${notification.uid}`"
            :message="notification.message"
            :color="notification.color"
            @closed="closed(notification)"
            />
    </div>
</template>
<script>
import Notification from './index.vue'

export default {
    components: {
        Notification
    },
    computed: {
        notificationList: function () {
            return this.$store.getters['ui/notification/get'];
        },
    },
    methods: {
        closed: function (notification) {
            notification.closed();
            this.$nextTick(() => {
                this.$ui.removeNotification(notification.uid);
            });
        },
    }
}
</script>