export default {
    users: {
        title: "Administrador de usuarios",
        'user-card': {
            title: "{user-count} usuarios",
            subtitle: "Nostrud ut ullamco sunt",
            action: "añadir usuario",
        },
        'client-card': {
            title: "{client-count} clientes",
            subtitle: "Nostrud ut ullamco sunt",
            action: "Lista de clientes",
        },
        'user-list': {
            title: "Lista de usuarios",
            headers: {
                email: "Email",
                actions: "Acciones"
            },
            items: {
                actions: {
                    edit: "Editar",
                    delete: "Remover",
                }
            }
        }
    }
}