import config from '@plugins/config';

const defaultCountryCode = config.get('COUNTRY_CODE');
const defaultCode = config.get('CURRENCY_CODE');

const currencyFormatters = {};
const getCurrencyFormatter = (code) => {
  return Object.prototype.hasOwnProperty.call(currencyFormatters, code) ? currencyFormatters[code] : currencyFormatters[code] = new Intl.NumberFormat(defaultCountryCode, { style: 'currency', currency: code});
}

const percentFormatter = new Intl.NumberFormat(config.get('COUNTRY_CODE'), { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 });

export default {
    namespaced: true,

    getters: {
        getCurrencyFormatter: state => (code = null) => {
            if (code) return getCurrencyFormatter(code);
            return getCurrencyFormatter(state.default?.code || defaultCode);
        },
        currencyFormatter: state => (value, code = null) => {
            if (code) return getCurrencyFormatter(code).format(value);
            return getCurrencyFormatter(state.default?.code || defaultCode).format(value);
        },
        percentFormatter: () => (value) => {
            return percentFormatter.format(value);
        },
    },
}