<template>
  <v-card
    :loading="loading"
    elevation="5"
    >
    <v-fade-transition mode="out-in">
      <div v-if="loading" key="loading">
        <v-card-text style="min-height: 100px">
          <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
            />
        </v-card-text>
      </div>
      <div v-else key="loaded">
        <div>
          <v-card-title>
            <div class="d-flex justify-space-between align-center flex-grow-1">
              <div>
                <h3 class="mr-2">{{ loteria.name }}</h3>
                <div class="d-flex align-center">
                  <p class="text-caption ma-0 mr-2 font-weight-bold">{{ loteria.shortname }}</p>
                  <p class="text-caption ma-0 mr-2">{{ loteria.slug }}</p>
                </div>
              </div>
              <v-btn 
                  color="primary" 
                  @click="edit()"
                  :to="{name: 'operadora.loteria.loteria.editar', params: {id: loteria.id}}"
              >
                  <v-icon>mdi-pencil</v-icon> EDITAR
              </v-btn>
            </div>
          </v-card-title>
          <v-card-text v-if="loteria.description">
            <p v-if="loteria.description" class="ma-0">{{ loteria.description }}</p>
          </v-card-text>
        </div>
        <v-card-actions class="pa-0">
          <slot/>
        </v-card-actions>
      </div>
    </v-fade-transition>
  </v-card>
</template>

<script>
export default {
  props: ['loteria', 'loading'],
}
</script>

<style>

</style>