import SistemaIndex from '@pages/operadora/sistema';

import Divisa from '@pages/operadora/sistema/reporte/divisa';
import Figura from '@pages/operadora/sistema/reporte/figura';
import General from '@pages/operadora/sistema/reporte/general';
import Sistema from '@pages/operadora/sistema/reporte/sistema';
import Tickets from '@pages/operadora/sistema/ticket';
import TicketsView from '@pages/operadora/sistema/ticket/view';

export default [
    {
        path: '/operadora/sistema',
        name: 'operadora.sistema',
        component: SistemaIndex,
    },
    {
        path: '/operadora/sistema/reporte/divisa',
        name: 'operadora.sistema.reporte.divisa',
        component: Divisa,
    },
    {
        path: '/operadora/sistema/reporte/figura',
        name: 'operadora.sistema.reporte.figura',
        component: Figura,
    },
    {
        path: '/operadora/sistema/reporte/general',
        name: 'operadora.sistema.reporte.general',
        component: General,
    },
    {
        path: '/operadora/sistema/reporte/sistema',
        name: 'operadora.sistema.reporte.sistema',
        component: Sistema,
    },
    {
        path: '/operadora/sistema/tickets',
        name: 'operadora.sistema.tickets',
        component: Tickets,
    },
    {
        path: '/operadora/sistema/tickets/:id',
        name: 'operadora.sistema.tickets.view',
        component: TicketsView,
    },
];
