<template>
  <v-navigation-drawer permanent app> 
    <template v-slot:prepend>
      <div class="d-flex align-center pa-3" >
        <v-switch v-model="$vuetify.theme.dark"></v-switch>
        <div class="d-flex align-center justify-center" style="min-height: 40px; width: 100%">
          <h3 class="text-h6 font-weight-black ma-0">SOURCE 2</h3>
        </div>
      </div>
    </template>
    <div style="width: 100%; height: 100%; display: flex; flex-direction: column;">
      <Menu :menu-list="menu"/>
      <Account class="mt-auto"/>
    </div>
  </v-navigation-drawer>
</template>
<script>
import Menu from '@components/layout/components/menu';
import Account from '@components/layout/components/account';

export default {
    components: {
      Menu,
      Account
    },
    computed: {
      user: function () {
        return this.$store.getters['persist/auth/user'];
      },
      menu: function () {
        return this.$store.getters['ui/menu/get'](this.user.domain.slug);
      }
    },
}
</script>