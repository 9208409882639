import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

import general from './es/general';
import dashboard from './es/dashboard';
import business from './es/business';
import account from './es/account';

export default new VueI18n({
    locale: 'es',
    fallbackLocale: 'es',
    messages: {
        es: {
            general,
            dashboard,
            business,
            account
        },
    }
});