/*
    domain: domainName
    parent: parentName

    entity id: entityId
    entity: entityName
    service: EntityService
*/
export default class BaseService {
    constructor({
        domainName = '',
        entityName = '',
        query = [],
        fields = [],
        updateFields = [],
        dataType = 'json',
    }) {
        this.domainName = domainName;
        this.entityName = entityName;
        this.query = query;
        this.fields = fields;
        this.updateFields = updateFields;
        this.dataType = dataType;
    }

    get baseRoute() {
        const routeList = [];
        if (this.domainName.length) routeList.push(this.domainName);
        if (this.entityName.length) routeList.push(this.entityName);
        return routeList.join('/');
    }

    get camelEntityName() {
        return this.entityName.replace(new RegExp('[-][a-z]', 'ig'), (s) => s.substr(1, 1).toUpperCase());
    }

    getParamsProcessed(filters = {}) {
        return filters;
    }

    getRoute(route = "") {
        return `${this.baseRoute}/${route}`
    }

    // PROCESA LA DATA DE ACUERDO A LOS FIELDS, ASI SOLO SUBMITEAR LOS CAMPOS DE LA ENTIDAD
    getDataEncoded(data) {
        switch (this.dataType) {
            case 'URLSearchParams': return this.getURLSearchParams(data);
            default: return this.getJsonData(data);
        }
    }

    getJsonData(data) {
        var dataEncoded = {};

        if (Array.isArray(this.fields)) {
            if (this.camelEntityName.length) {
                dataEncoded[this.camelEntityName] = {};
                let entityData = dataEncoded[this.camelEntityName];
                for (const field of this.fields) {
                    if (data[field] !== undefined) {
                        entityData[field] = data[field];
                    }
                }
            } else {
                for (const field of this.fields) {
                    if (data[field] !== undefined) dataEncoded[field] = data[field];
                }
            }
        } else {
            for (const entity in this.fields) {
                if (Object.hasOwnProperty.call(data, entity)) {
                    dataEncoded[entity] = {};
                    let entityData = dataEncoded[entity];
                    const fields = this.fields[entity];
                    for (const field of fields) {
                        if (data[entity][field] !== undefined) {
                            entityData[field] = data[entity][field];
                        }
                    }
                }
            }
        }

        return dataEncoded;
    }

    getURLSearchParams(data) {
        var dataEncoded = new URLSearchParams();

        if (Array.isArray(this.fields)) {
            if (this.camelEntityName.length) {
                for (const field of this.fields) {
                    if (data[field] !== undefined) dataEncoded.append(`${this.camelEntityName}[${field}]`, data[field]);
                }
            } else {
                for (const field of this.fields) {
                    if (data[field] !== undefined) dataEncoded.append(field, data[field]);
                }
            }
        } else {
            for (const entity in this.fields) {
                if (Object.hasOwnProperty.call(data, entity)) {
                    const fields = this.fields[entity];
                    for (const field of fields) {
                        if (data[entity][field] !== undefined) {
                            dataEncoded.append(`${entity}[${field}]`, data[entity][field])
                        }
                    }
                }
            }
        }

        return dataEncoded;
    }
}