<template>
<v-container fluid>
    <v-row>
        <v-col cols="12"  md="3">
            <v-card elevation="2" :loading="loading">
                <v-card-title>{{ count }} Masters</v-card-title>
                <v-card-actions>
                    <v-btn color="primary" @click="add()"><v-icon>mdi-account-box</v-icon> AÑADIR MASTER</v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
    <v-row>
        <v-col>
            <v-card elevation="2">
                <v-card-title>
                    <MasterSearch v-model="params" @search="getList()"/>
                </v-card-title>
                <v-card-text>
                    <v-data-table
                        :headers="headers"
                        :items="list"
                        :options.sync="options"
                        :server-items-length="count"
                        :loading="loading"
                        >
                        <!-- eslint-disable-next-line -->
                        <template v-slot:item.name="{ item }">
                            <div class="d-flex">
                                <div>
                                    <div>{{ `${item.name}` }}</div>
                                    <small>{{ `${item.slug}` }}</small>
                                </div>
                            </div>
                        </template>
                        <!-- eslint-disable-next-line -->
                        <template v-slot:item.user="{ item }">
                            <div v-if="item.user">
                                <v-chip :color="item.user.is_active ? 'primary' : 'secondary'" label>
                                    <v-icon left>mdi-account-circle-outline</v-icon>
                                    {{ item.user.username }}
                                </v-chip>
                            </div>
                        </template>
                        <!-- eslint-disable-next-line -->
                        <template v-slot:item.is_active="{ item }">
                            <div>
                                <v-chip v-if="item.is_active" color="success" small>Activo</v-chip>
                                <v-chip v-else color="error" small>Inactivo</v-chip>
                            </div>
                        </template>
                        <!-- eslint-disable-next-line -->
                        <template v-slot:item.actions="{ item }">
                            <div>
                                <v-btn color="secondary" icon @click="edit(item)">
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                                <v-btn color="primary" icon :to="{name: 'comercio.master.view', params: {id: item.id}}">
                                    <v-icon>mdi-eye</v-icon>
                                </v-btn>
                            </div>
                        </template>
                        <!-- eslint-disable-next-line -->
                        <template v-slot:item.created_at="{ item }">
                            <div>
                                {{  item.created_at | date }}
                            </div>
                        </template>
                        
                    </v-data-table>
                </v-card-text>
            </v-card> 
        </v-col>
    </v-row>
    <MasterModal :open.sync="isModalOpen" @saved="postSaved" :master="item"/>
</v-container>
</template>

<script>
import MasterService from '@services/comercio/MasterService';

import MasterSearch from '@components/entities/comercio/master/search';
import MasterModal from '@components/entities/comercio/master/modal';

export default {
    components: {
        MasterSearch,
        MasterModal
    },
    data: () => ({

        headers: [
            {
                text: 'Nombre',
                value: 'name',
                align: 'start',
            },
            
            {
                text: 'Usuario',
                value: 'user',
                align: 'start',
            },
            {
                text: 'Estado',
                value: 'is_active',
                align: 'center',
                width: 150
            },
            {
                text: 'Creado En',
                value: 'created_at',
                align: 'center',
                width: 150
            },
            {
                text: 'Acciones',
                value: 'actions',
                align: 'right',
                width: 200
            },
        ],
        options: {},
        loading: false,
        list: [],
        count: 0,

        isModalOpen: false,
        item: null,

        params: {}
    }),
    created: function () {
        this.$store.dispatch('ui/layout/setTitle', 'Masters');
    },
    methods: {
        getList: function (params = {}) {
            this.loading = true;
            MasterService.getList({...this.params, ...params})
                .then(({masterList, masterCount}) => {
                    this.list = masterList;
                    this.count = masterCount;
                }).finally(() => {
                    this.loading = false;
                });
        },
        add: function () {
            this.item = null;
            this.isModalOpen = true;
        },
        edit: function (item) {
            this.item = item;
            this.isModalOpen = true;
        },
        postSaved: function () {
            this.count = this.count + 1;
            this.getList();
        },
        getOptions: function () { 
            const params = {};
            let itemsPerPage = null;
            if (this.options.itemsPerPage !== -1) itemsPerPage = this.options.itemsPerPage
            else if (this.count) itemsPerPage = this.count
            params['limit'] = itemsPerPage;
            params['offset'] = (this.options.page * itemsPerPage) - itemsPerPage;
            return params;
        },
    },
    watch: {
        options: {
            handler: function () {
                this.getList(this.getOptions()); 
            },
            deep: true,
        },
    },
}
</script>

<style>

</style>