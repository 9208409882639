import axios from '@plugins/axios';

class OperadoraService {
    get() {
        return axios({
            url: 'comercio/operadora/',
            method: 'GET',
        });
    }
}

export const BaseOperadoraService = OperadoraService

export default new OperadoraService();