<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <v-card class="mb-3">
                    <v-card-title>Filtros</v-card-title>
                    <v-card-text>
                        <ConsolidadoComercioSearch context="sistema" :default="params" @search="search($event)"/>
                    </v-card-text>
                </v-card>
                <v-card>
                    <v-card-text>
                        <ConsolidadoComercio context="sistema" :params="params"></ConsolidadoComercio>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import ConsolidadoComercio from '@components/entities/reporte/venta/comercio';
    import ConsolidadoComercioSearch from '@components/entities/reporte/venta/comercio/search';

    export default {
        components: {
            ConsolidadoComercio,
            ConsolidadoComercioSearch
        },
        props: {
        
        },
        data: function() {
            const query = {... this.$route.query};

            for (const key in query) {
                if (Object.hasOwnProperty.call(query, key)) {
                    const element = query[key];
                    console.log(key, query[key], this.$lodash.isNumber(element));
                    query[key] = /^-?[\d.]+(?:e-?\d+)?$/.test(element) ? Number.parseFloat(element): element;
                }
            }

            return {
                params: {
                    daterange: [this.$luxon.now().toISODate(),this.$luxon.now().toISODate()],
                    ... query,
                }
            }
        },
        mounted: function () {
            this.$store.dispatch('ui/layout/setTitle', '');
        },
        computed:{
            
        },
        methods:{
            search: function (params) {
                this.params = params;
            }
        },
    }
</script>