<template>
	<div>
		<component :is="dashboard" :user="user"/>
	</div>
</template>
<script>
import DashboardBasic from './dashboard/basic';
import DashboardOperadoraLoteria from './dashboard/operadora-loteria';

export default {
	components: {
		DashboardBasic,
		DashboardOperadoraLoteria,
	},
	computed: {
		user: function () {
			return this.$store.getters['persist/auth/user'];
		},
		dashboard: function () {
			switch (this.user.owner_entity) {
				case 'operadora-loteria': return 'DashboardOperadoraLoteria';
				default: return 'DashboardBasic';
			}
		}
	}
}
</script>