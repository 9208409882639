import Form from './form';

export default {
    mixins: [Form],
    methods: {
        getData: function () {
            let data = {};
            for (const property in this.fields) {
                if (Object.hasOwnProperty.call(this.fields, property)) {
                    if (this.fields[property] !== null) data[property] = this.fields[property];
                }
            }
            if (this.customData) data = this.customData(data);
            return data;
        },
        reset: function () {
            this.fields = this.getFields();
            this.emit();
        },
        search: function (executeAfter = true) {
            if (this.valid) this.$emit('search', this.getData());
            if (executeAfter && this.afterSearch) this.afterSearch();
        },
        init: function () {
            if (this.valid) this.$emit('init', this.getData())
        }
    }
};