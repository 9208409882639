<template>
    <div>
        <div v-if="divisaRateLoading">
            Cargando...
        </div>
        <div v-else-if="divisaRate">
            Actualizado el: {{ divisaRate.updated_at | datetime }}
            <CurrencyInput v-model="rate" :code="divisaDefault.code" label="Rate" hide-details="" class="mb-2"/>
            <div class="d-flex">
                <v-spacer/><v-btn :loading="divisaRateSaving" :disabled="divisaRateSaving" @click="setRate()">Guardar</v-btn>
            </div>
        </div>
    </div>
</template>
<script>
import DivisaService from '@/services/operadora/general/DivisaService';

export default {
    props: {
        divisa: {},
        divisaDefault: {},
    },
    data: function () {
        return {
            rate: this.divisa.rate,
            divisaRateLoading: false,
            divisaRate: null,
            divisaRateSaving: false,
        }
    },
    methods: {
        getLastRate: function () {
            this.divisaRateLoading = true;
            this.divisaRate = null;

            DivisaService.getLastRate({ target_id: this.divisa.id })
                .then(({ divisaRate }) => {
                    this.divisaRate = divisaRate;
                })
                .finally(() => this.divisaRateLoading = false)
        },
        setRate: function () {
            this.divisaRateSaving = true;

            DivisaService
                .setRate({ 
                    target_id: this.divisa.id,
                    rate: this.rate, 
                })
                .then(({ divisaRate }) => {
                    this.divisaRate = divisaRate;
                    this.$emit('saved', divisaRate);
                })
                .finally(() => this.divisaRateSaving = false);
        }
    },
    mounted: function () {
        this.getLastRate();
    }
}
</script>