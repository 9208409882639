<template>
    <div fluid class="fill-height" style="
        background-image: url(/img/background.jpg)
        background-size: cover;
        background-position: center;
        ">
        <v-overlay
            absolute
            :dark="false"
            >
            <v-card width="400" shaped>
                <v-card-title class="d-flex justify-center">
                    <div class="text-h4">
                        <img src="/img/source/source-monedas-color-128.png"/>
                    </div>
                </v-card-title>
                <v-card-subtitle class="text-h6 text-bold text-center">{{ $t('general.login.header.title') }}</v-card-subtitle>
                <v-card-text>
                    <UserLoginForm ref="user-login-form" v-model="valid" :loading="loading" :errors="errors" @submit="submit"/>
                    <div v-if="messages.length" class="pa-2">
                        <v-alert v-for="message in messages" :key="message.content" dense :type="message.type" dismissible>{{ message.content }}</v-alert>
                    </div>
                </v-card-text>
                <v-card-actions style="padding: 1rem 2rem; padding-top: 0">
                    <v-spacer/>
                    <v-btn
                        color="primary"
                        v-on:click="submit"
                        :disabled="!valid || loading"
                        :loading="loading"
                        >
                        INGRESAR
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-overlay>
    </div>
</template>

<script>
import UserLoginForm from '@components/entities/general/user/login-form'

export default {
    data: () => ({
        overlay: true,
        valid: false,
        loading: false,
        messages: [],
        errors: {
            user: {
                email: [],
            }
        }
    }),
    components: {
        UserLoginForm
    },
    methods: {
        submit: function () {
            this.loading = true;
            this.messages = [];
            const credentials = this.$refs['user-login-form'].getData();
            this.$store.dispatch('persist/auth/login', {...credentials})
                .then(() => {
                    this.$router.push({name: 'profile'})
                })
                .catch((code) => {
                    switch (code) {
                        case 'USER_NOT_FOUND': 
                            this.messages.push({type: 'error', content: this.$t('login.errors.user_not_found')})
                        break;
                    }
                    // ERROR
                })
                .finally(() =>  this.loading = false);
        }
    }
}
</script>