<template>
    <div>
        <div align="right"><v-btn @click="exportToExcel">Exportar a Excel</v-btn></div>
        <v-data-table
            :headers="headers"
            :items="list"
            :items-per-page="10"
            :loading="loading"
            :hide-default-footer="nopage"
            :disable-pagination="nopage"
            >
            <!-- eslint-disable-next-line -->
            <template v-slot:item.juego="{ item }">
                {{ item.juego }}
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.divisa="{ item }">
                {{ item.divisa }}
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.venta="{ item }">
                {{ item.venta | currency(item.divisa_code) }}
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.comision="{ item }">
                {{ item.comision | currency(item.divisa_code) }}
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.premio="{ item }">
                {{ item.premio | currency(item.divisa_code) }}
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.total="{ item }">
                <v-chip small label :color="item.percent > 0.80 ? 'error': 'primary'" class="font-weight-bold mr-2">
                    {{ item.total | currency(item.divisa_code) }} {{' / '}} {{ item.percent | percent }}
                </v-chip>
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.banqueo="{ item }">
                {{ item.banqueo[0] | currency(item.divisa_code) }} / {{ item.banqueo[1] | currency(item.divisa_code) }}
            </template>
        </v-data-table>
    </div>
</template>

<script>
import VentaService from '@services/reporte/VentaService';
import * as excelJS from 'exceljs';
import { saveAs } from "file-saver";
export default {
    components: {

    },
    props: {
        params: {
            defaults: function() {
                return {

                };
            },
        },
        context: {
            default: 'comercio',
        },
        nopage: {
            type: Boolean,
            default: false,
        }
    },
    data: function() { 
        return {
            headers: [
                { text: 'Juego', align: 'start', value: 'juego' },
                { text: 'Divisa', value: 'divisa' },
                { text: 'Venta', value: 'venta' },
                { text: 'Comisión', value: 'comision' },
                { text: 'Premio', value: 'premio' },
                { text: 'Total', value: 'total' },
                { text: 'Banqueo', value: 'banqueo' },
            ],
            list: [],
            count: 0,
            loading: false,       
        }
    },
    computed: {
        user: function () {
            return this.$store.getters['persist/auth/user'];
        },
        divisa: function () {
            return this.$store.getters['persist/divisa/selected'];
        },
    },
    mounted: function () {
        if (this.nopage) this.$delete(this.headers, 1);
        this.getList();
    },
    methods: {
        getList: function () {
            this.list = [];
            this.loading = true;
            const comercio = [];
            if (this.user.comercio_type !== 'operadora') comercio[this.getComType(this.user.comercio_type)] = this.user.comercio_id; 
            const params = { ...this.params, ...comercio};

            if (this.context == 'sistema') {
                params['group_by'] = 'sistema';
                params['comercio_type'] = 'sistema';
            }

            VentaService.reporteConsolidado(params)
                .then(({reportList}) => {
                    this.list = reportList;
                }).finally(() => {
                    this.loading = false;
                });
        },
        getComType: function (comercioType) {
            return {
                'master': 'master_id', 
                'comercializadora': 'comercializadora_id', 
                'grupo': 'grupo_id', 
                'taquilla': 'taquilla_id', 
                'sistema': 'sistema_id'
            }[comercioType];
        },
        exportToExcel () {
            if (this.list.length == 0) return;
            const workbook = new excelJS.Workbook();
            workbook.creator = 'Source 2';
            workbook.lastModifiedBy = 'System';
            workbook.created = new Date();
            let sheet = workbook.addWorksheet("Reporte");

            sheet.getRow(1).values = [
                'Juego', 
                'Divisa',
                'Ventas '+ this.divisa.code, 
                'Comision '+ this.divisa.code, 
                'Premios '+ this.divisa.code, 
                'Total '+ this.divisa.code, 
                'BanqueoA',
                'BanqueoB',
            ];
            
            let records = [];
            this.list.forEach(reporte => {
                records.push([
                    reporte.juego,
                    reporte.divisa,
                    parseFloat(reporte.venta.toFixed(2)),
                    parseFloat(reporte.comision.toFixed(2)),
                    parseFloat(reporte.premio.toFixed(2)), 
                    parseFloat(reporte.total.toFixed(2)), 
                    parseFloat(reporte.banqueo[0].toFixed(2)),
                    parseFloat(reporte.banqueo[1].toFixed(2))  
                ]);
            });
            sheet.addRows(records);
            
            sheet.getColumn(1).alignment = {
                vertical: "middle",
                horizontal: "center",
            };
            sheet.getRow(1).font  = {
                bold: true
            };
            sheet.columns.forEach(column => {
                const lengths = column.values.map(v => v.toString().length);
                const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
                column.width = maxLength + 2;
            });

            const tittle = 'Source 2 Reporte de Ventas por Divisa desde ' 
                + this.params.daterange[0].split("-").reverse().join("-")
                + ' hasta '
                + this.params.daterange[1].split("-").reverse().join("-");

            sheet.insertRow(1, [tittle]);
            sheet.mergeCells("A1:H1");

            sheet.getCell('A1').fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb:'c9daf8'}
            };
            
            const rmax = this.list.length + 2;
            sheet.addConditionalFormatting({
                ref: 'A2:H' + rmax,
                rules: [
                    {
                    type: 'expression',
                    formulae: ['MOD(ROW(),2)=0'],
                    style: {fill: {type: 'pattern', pattern: 'solid', bgColor: {argb: 'cccccc'}}},
                    }
                ]
            });

            const filename = 'Reporte Divisa ' + this.$luxon.now().toFormat('MM-dd HHmmss') + '.xlsx';
            workbook.xlsx.writeBuffer().then(function(buffer) {
                const blob = new Blob([buffer], { type: "applicationi/xlsx" });
                saveAs(blob, filename);
            });
        }
    },
    watch: { 
        'params': function () {
            this.getList();
        },
    },
}
</script>
<style>

</style>