export default {
    namespaced: true,

    state: () => ({
        juego_slug: 'lotto-rey',
    }),

    getters: {
        defaultJuegoSlug: state => state.juego_slug,
    },
}