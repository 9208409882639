<template>
    <div class="d-flex">
        <v-card elevation="2" class="mr-2" :loading="loading">
            <v-card-title>Ventas</v-card-title>
            <v-card-text>{{ sumary.venta ? sumary.venta : 0 | currency }}</v-card-text>
        </v-card>
        <v-card elevation="2" class="mr-2" :loading="loading">
            <v-card-title>Comisión</v-card-title>
            <v-card-text>{{ sumary.comision ? sumary.comision : 0 | currency }}</v-card-text>
        </v-card>
        <v-card elevation="2" class="mr-2" :loading="loading">
            <v-card-title>
                Premios
                <v-chip small label :color="porcentaje > 0.80 ? 'error': 'primary'" class="font-weight-bold ml-1">
                    {{ porcentaje ? porcentaje : 0 | percent }}
                </v-chip>
            </v-card-title>
            <v-card-text class="d-flex">
                {{ sumary.premio ? sumary.premio : 0 | currency }}
            </v-card-text>
        </v-card>
        <v-card elevation="2" class="mr-2" :loading="loading">
            <v-card-title>Total</v-card-title>
            <v-card-text>{{ sumary.total ? sumary.total : 0 | currency }}</v-card-text>
        </v-card>
    </div>
</template>

<script>
//import VentaService from '@services/reporte/VentaService';

export default {
    components: {

    },
    props: {
        summary: {
            required: true,
            default: () => {},
        },
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
        params: {
            defaults: function() {
                return {};
            },
        }
    },
    data: function() { 
        return {
            list: [],
        }
    },
    computed: {
       /* divisa: function () {
            return this.$store.getters['persist/divisa/selected'];
        },
        user: function () {
            return this.$store.getters['persist/auth/user'];
        },*/
        porcentaje: function (){
            if (this.sumary.premio === 0) return 0;
            return this.sumary.premio / this.sumary.venta;
        },
        sumary: function (){
            return this.summary;
        }
    },
    mounted: function () {
        //this.getList();
    },
    methods: {
        /*getList: function () {
            this.list = [];
            this.loading = true;
            const comercio = [];
            if (this.user.comercio_type !== 'operadora') comercio[this.getComType(this.user.comercio_type)] = this.user.comercio_id; 
            VentaService.reporteConsolidadoPorComercio({ ...this.params , divisa_id: this.divisa.id, ...comercio})
                .then(({reportList}) => {
                    this.list = reportList;
                    this.sumary = reportList.reduce((sumary, item) => { 
                        sumary.venta += item.venta; 
                        sumary.comision += item.comision; 
                        sumary.premio += item.premio; 
                        sumary.total += item.total; 
                        return sumary;
                    }, {venta: 0, comision: 0, premio: 0, total: 0});
                }).finally(() => {
                    this.loading = false;
                });
        },
        getComType: function (comercioType) {
            return {'master': 'master_id', 'comercializadora': 'comercializadora_id', 'grupo': 'grupo_id', 'taquilla': 'taquilla_id'}[comercioType];
        },*/
    },
    watch: { 
        /*'params': function () {
            this.getList();
        },
        'divisa': function () {
            this.getList();
        }*/
    },
}
</script>
<style>

</style>