<template>
    <div v-if="confirm">
        <Confirm :message="confirm.message" :textConfirm="confirm.textConfirm" :textCancel="confirm.textCancel" @confirm="confirmed()" @cancel="cancelled()"/>
    </div>
</template>
<script>
import Confirm from './index.vue'

export default {
    components: {
        Confirm
    },
    computed: {
        confirm: function () {
            return this.$store.getters['ui/confirm/get'];
        },
    },
    methods: {
        confirmed: function () {
            if (!this.confirm) return;
            this.confirm.confirm();
            this.$nextTick(() => {
                this.$ui.removeConfirm();
            });
        },
        cancelled: function () {
            if (!this.confirm) return;
            this.confirm.cancel();
            this.$nextTick(() => {
                this.$ui.removeConfirm();
            });
        },
    }
}
</script>