import BaseService from "@utils/BaseService";
import axios from '@plugins/axios';

class JuegoService extends BaseService {
    get(id, options = {}) {
        return axios({
            url: this.getRoute(id),
            method: 'GET',
            ...options
        });
    }
    getList(filters = {}, options = {}) {
        return axios({
            url: this.getRoute(),
            method: 'GET',
            params: this.getParamsProcessed({is_active: true, ...filters}),
            ...options
        });
    }
    count(filters = {}, options = {}) {
        return axios({
            url: this.getRoute(),
            method: 'GET',
            params: this.getParamsProcessed({is_active: true, ...filters}),
            ...options
        });
    }
    getForCupoMaster(masterId, params = {}, options = {}) {
        return axios({
            url: this.getRoute(`for-cupo-master/master/${masterId}`),
            method: 'GET',
            params: this.getParamsProcessed({...params}),
            ...options
        });
    }
    getForCupoComercializadora(comercializadoraId, params = {}, options = {}) {
        return axios({
            url: this.getRoute(`for-cupo-comercializadora/comercializadora/${comercializadoraId}`),
            method: 'GET',
            params: this.getParamsProcessed({...params}),
            ...options
        });
    }
    getForCupoGrupo(grupoId, params = {}, options = {}) {
        return axios({
            url: this.getRoute(`for-cupo-grupo/grupo/${grupoId}`),
            method: 'GET',
            params: this.getParamsProcessed({...params}),
            ...options
        });
    }
    getForCupoTaquilla(taquillaId, params = {}, options = {}) {
        return axios({
            url: this.getRoute(`for-cupo-taquilla/taquilla/${taquillaId}`),
            method: 'GET',
            params: this.getParamsProcessed({...params}),
            ...options
        });
    }
}

export const BaseJuegoService = JuegoService;

export default new JuegoService({
    domainName:'loteria',
    entityName:'juego',
    query:['search', 'loteria_id', 'tipo_juego_id', 'is_active', 'offset', 'limit', 'divisa_id', 'loteria_id', 'owner_id'],
});