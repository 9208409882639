import axios from '@plugins/axios';

class UsuarioService {
    get(id, options = {}) {
        return axios({
            url: `usuario/${id}`,
            method: 'GET',
            ...options
        });
    }
    getList(filters = {}, options = {}) {
        return axios({
            url: `usuario`,
            method: 'GET',
            params: filters,
            ...options
        });
    }
}

export const BaseUsuarioService = UsuarioService

export default new UsuarioService();