<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="3">
        <v-card elevation="2" :loading="loading">
          <v-card-title>{{$t('dashboard.users.user-card.title', {'user-count': userListLength})}}</v-card-title>
          <v-card-subtitle>{{$t('dashboard.users.user-card.subtitle')}}</v-card-subtitle>
          <v-card-actions>
            <v-btn color="primary" block @click.stop="newUser()"><v-icon>mdi-account-box</v-icon>{{$t('dashboard.users.user-card.action')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card elevation="4">
          <v-card-title>{{$t('dashboard.users.user-list.title')}}</v-card-title>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="userList"
              :items-per-page="10"
              :loading="loading"
            >
              <!-- eslint-disable-next-line -->
              <template v-slot:item.roles="{ item }">
                <div>
                  {{ item.roles.reduce((roles, role) => roles += role.slug, '') }}
                </div>
              </template>

              <!-- eslint-disable-next-line -->
              <template v-slot:item.actions="{ item }">
                <div>
                  <v-btn color="primary" text @click="editUser(item)">
                    <v-icon>mdi-account-edit</v-icon>
                    {{$t('dashboard.users.user-list.items.actions.edit')}}
                  </v-btn>
                  <v-btn class="ml-2" color="red" icon><v-icon>mdi-account-lock</v-icon></v-btn>
                </div>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <ModalForm ref="modal-form-ref"/>
  </v-container>
</template>

<script>
import ModalForm from "./components/modal-form";

import UserService from '@services/operadora/UserService';

export default {
  components: { 
    ModalForm,
  },
  data: function () {
    return {
      loading: false,
      loadingClient: false,
      clientCount: null,
      params: {},
      headers: [
        {
          text: this.$t('dashboard.users.user-list.headers.username'),
          value: 'username',
          align: 'start',
        },
        {
          text: this.$t('dashboard.users.user-list.headers.roles'),
          value: 'roles',
          align: 'start',
        },
        {
          text: this.$t('dashboard.users.user-list.headers.actions'),
          value: 'actions',
          width: 200
        },
      ],
      userList: [],
    }
  },
  created: function () {
    this.$store.dispatch('ui/layout/setTitle', this.$t('dashboard.users.title'));
    this.getUserList();
    this.getClientCount();
  },
  computed: {
    userListLength: function () {
      return this.userList.length;
    }
  },
  methods: {
    newUser: function () {
      this.$refs['modal-form-ref'].newUser(() => {
        this.getUserList();
      });
    },
    editUser: function (user) {
      this.$refs['modal-form-ref'].editUser(user,() => {
        this.getUserList();
      });
    },
    getUserList: function () {
      this.loading = true;
      UserService.getList(this.params)
        .then(({userList}) => {
          this.userList = userList;
        })
        .catch(() => {

        })
        .finally(() => {
          this.loading = false;
        });
    },
    getClientCount: function () {
      this.loadingClient = true;
    }
  }
}
</script>

<style>

</style>