import Vue from 'vue'
import Vuex from 'vuex';

Vue.use(Vuex);

import VuexPersistence from 'vuex-persist'

var vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: state => ({
    persist: state.persist
  })
})

import ui from './ui'
import persist from './persist'

import comercio from './entities/comercio'
import general from './entities/general'

export default new Vuex.Store({
  namespaced: true,
  modules: {
    ui,
    persist,
    comercio,
    general
  },
  plugins: [vuexLocal.plugin]
});