<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>Filtros</v-card-title>
                    <v-card-text><UsuarioSearch :loading="loading" @search="params = $event"/></v-card-text>
                </v-card>
                <br/>
                <UserList :params="params" :loading="loading"/>
            </v-col>
        </v-row>
    </v-container>
    </template>
<script>
    import UserList from '@components/entities/usuario';
    import UsuarioSearch from '@components/entities/usuario/search';

    export default {
        components: {
            UserList,
            UsuarioSearch,
        },
        props: {
        
        },
        data: function() {
            return {
                options: {},
                loading: false,
                params: {}
            }
        },
        created: function () {
            this.$store.dispatch('ui/layout/setTitle', 'Reporte de Usuarios');
        },
        methods:{
            
        },
    }
</script>
<tyle>

</tyle>