<template>
    <div class="pa-4">
        <v-card outlined>
            <v-card-text class="pa-3">
                <v-card-actions class="pa-0 d-flex flex-column">
                    <span style="font-size: 80%;">{{user.domain.slug}}</span>
                    <v-btn block text :to="{name: 'profile'}">
                        <v-icon small>mdi-account-circle</v-icon>
                        <span>{{user.username}}</span>
                    </v-btn>
                    <v-btn block text :loading="loading" :disabled="loading" v-on:click="logout()">
                        <v-icon small>mdi-logout-variant</v-icon>
                        <span>CERRAR SESIÓN</span>
                    </v-btn>
                </v-card-actions>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
export default {
    data: () => ({
        menu: false,
        loading: false
    }),
    computed: {
        user: function () {
            return this.$store.getters['persist/auth/user'];
        },
        letters: function () {
            if (!this.user) return "-";
            return this.user.username.charAt(0);
        }
    },
    methods: {
        logout: function () {
            this.loading = true;
            this.$store.dispatch('persist/auth/logout').finally(() => this.loading = false);
        }
    }
}
</script>

<style>

</style>