<template>
    <v-form ref="form" v-model="valid">
            <v-row dense>
                <v-col v-if="!!master" cols="12" sm="12">
                    Master
                    <v-text-field
                        readonly
                        :value="`${master.name}`"
                        hide-details
                        filled
                        >
                    </v-text-field>
                </v-col>
                <v-col v-else cols="12" sm="12" v-show="showMaster">
                    Master
                    <v-select
                        :items="masterList"
                        :loading="isLoadingMasterList"
                        :disabled="isLoadingMasterList"
                        item-text="name"
                        item-value="id"
                        label="Master"
                        @change="changed('master_id', $event)"
                        v-model="fields.master_id"
                        :rules="rules.master_id"
                        hide-details="auto"
                        solo
                        >
                    </v-select>
                </v-col>
                <v-col v-if="!!comercializadora" cols="12" sm="12">
                    Comercializadora
                    <v-text-field
                        readonly
                        :value="`${comercializadora.name}`"
                        hide-details
                        filled
                        >
                    </v-text-field>
                </v-col>
                <v-col v-else cols="12" sm="12" v-show="showComercializadora">
                    Comercializadora
                    <v-select
                        :items="comercializadoraList"
                        :loading="isLoadingComercializadoraList"
                        :disabled="isLoadingComercializadoraList || !fields.master_id"
                        item-text="name"
                        item-value="id"
                        label="Comercializadora"
                        @change="changed('comercializadora_id', $event)"
                        v-model="fields.comercializadora_id"
                        :rules="rules.comercializadora_id"
                        hide-details="auto"
                        solo
                        >
                    </v-select>
                </v-col>
                <v-col cols="12" sm="8">
                    Nombre
                    <v-text-field
                        v-model="fields.name"
                        :rules="rules.name"
                        :disabled="loading"
                        label="Nombre"
                        solo
                        hide-details="auto"
                        @change="changed('name', $event)"
                        required
                        />
                </v-col>
                <v-col cols="12" sm="4" md="4">
                    Estado
                    <v-switch
                        v-model="fields.is_active"
                        :rules="rules.is_active"
                        :disabled="loading"
                        :label="fields.is_active ? 'Activo' : 'Inactivo'"
                        inset
                        hide-details="auto"
                        color="primary"
                        @change="changed('is_active', $event)"
                        required
                        />
                </v-col>
                <v-col cols="12">
                    Descripción
                    <v-textarea
                        v-model="fields.description"
                        :rules="rules.description"
                        :disabled="loading"
                        label="Descripción"
                        rows="5"
                        clearable
                        clear-icon="mdi-close-circle"
                        solo
                        hide-details="auto"
                        auto-grow
                        @change="changed('description', $event)"
                        />
                </v-col>
            </v-row>
    </v-form>
</template>
<script>
import Form from '@mixins/form';

import MasterService from '@services/comercio/MasterService';
import ComercializadoraService from '@services/comercio/ComercializadoraService';

export default {
    mixins: [Form],
    props: ['master', 'comercializadora'],
    data: function () {
        return {
            rules: {
                name: [v => !!v || 'ERROR'],
                comercializadora_id: [v => !!v || 'ERROR'],
                master_id: [v => !!v || 'ERROR'],
                description: [],
                is_active: [],
            },

            defaultFields: {
                name: null,
                description: null,
                master_id: null,
                comercializadora_id: null,
                is_active: false,
            },

            masterList: [],
            showMaster: true,
            isLoadingMasterList: false,

            comercializadoraList: [],
            showComercializadora: true,
            isLoadingComercializadoraList: false,
        };
    },
    created: function () {
        this.getMasterList();
    },
    mounted: function () {
        if (this.fields.master_id) {
            this.getComercializadoraList();
        }
    },
    methods: {
        getMasterList: function () {
            this.isLoadingMasterList = true;

            this.masterList = [];
            this.comercializadoraList = [];

            MasterService
                .getList({ is_active: true })
                .then(({ masterList }) => { 
                    if (masterList.length == 1) {
                        this.fields.master_id = masterList[0].id;
                        this.showMaster = false;
                    }
                    this.masterList = masterList; 
                })
                .finally(() => this.isLoadingMasterList = false);
        },
        getComercializadoraList: function () {
            this.comercializadoraList = [];
            if (!this.fields.master_id) return;

            this.isLoadingComercializadoraList = true;

            ComercializadoraService
                .getList({ is_active: true, master_id: this.fields.master_id })
                .then(({ comercializadoraList }) => { 
                    if (comercializadoraList.length == 1) 
                    {
                        this.fields.comercializadora_id = comercializadoraList[0].id;
                        this.showComercializadora = false;
                    }
                    this.comercializadoraList = comercializadoraList; 
                })
                .finally(() => this.isLoadingComercializadoraList = false);
        },
        onChange: function (field) {
            switch(field) {
                case 'master_id':
                    this.fields.comercializadora_id = null;   
                break;
            }
        }
    },
    
    watch: {
        'fields.master_id': function () {
            this.showComercializadora = true;
            this.getComercializadoraList();
        },
    }
}
</script>